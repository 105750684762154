import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BaseDialog } from "./BaseDialog";
import { Typography } from "@mui/material";

const styles = {
  primarySx: {
    color: "#1769aa"
  },
  secondySx: {
    color: "#ab003c"
  },
}
export const CreationAccountError = ({
  error,
  resetErrorBoundary,
  resetComponentViewState = undefined,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoHome = () => {
    resetErrorBoundary();
    navigate("/signin"); // Navega a la página de inicio
  };

  const handleRetry = () => {
    if (resetComponentViewState) {
      dispatch(resetComponentViewState());
    }
    resetErrorBoundary(); // Solo resetea el error
  };

  const userFriendlyErrorMessage = () => {
    switch (error.status) {
      case 400:
        return "The information submitted was not correct. Please check your input and try again.";
      case 401:
        return "You are not authorized to perform this action. Please log in and try again.";
      case 500:
        return "We're sorry, there was a problem creating your account. Please try again later.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  return (
    <div key="error">
      <BaseDialog
        openState={true}
        title="Account Creation Error"
        content={
          <Typography sx={{ color: "red" }}>{userFriendlyErrorMessage()}</Typography>
        }
        actions={[
          {
            label: "Return to Home",
            onClick: handleGoHome,
            style: styles.primarySx
          },
          {
            label: "Try Again",
            onClick: handleRetry,
            style: styles.secondySx
          }
        ]}
      >
      </BaseDialog>
    </div>
  );
};
