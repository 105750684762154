import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from "@mui/material";
import { useDispatch } from "react-redux";
export const CheckEmployeeRequirements = ({
  data,
  updateComponentState,
  checkedState,
  setChanges = () => {},
}) => {
  const [requirements, setRequirements] = useState(checkedState || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkedState) {
      setRequirements(checkedState);
    }
  }, [checkedState]);

  const handleCheckboxChange = (e, item) => {
    setChanges(true);
    if (e.target.checked) {
      setRequirements((prev) => [...prev, item]);
    } else {
      setRequirements((prev) => prev.filter((itemC) => itemC.id !== item.id));
    }
  };

  useEffect(() => {
    console.log(requirements);
    dispatch(updateComponentState({ requirements }));
  }, [requirements]);

  const isChecked = (item) => {
    return (
      Array.isArray(requirements) &&
      requirements.some((requirements) => requirements.id === item.id)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "white",
        width: "100%",
        borderRadius: "10px",
        height: "auto",
        padding: "10px",
      }}
    >
      <div>
        <FormControl
          component="fieldset"
          style={{ height: "400px", overflow: "auto", width: "100%" }}
        >
          <FormGroup aria-label="position">
            {data.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked(item)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                }
                label={item.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};
