import React, { useEffect, useState } from "react";
import { Box, Button, Grid, ButtonBase, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "../redux/features/formStateSlice";
import ButtonBaseDemo from "./buttonUserIdentification";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import ButtonBaseTransport from "./buttonUserTransport";
import BackPage from "./common/BackPage";
import { SignUpNextButton } from "./signupNextButton";
import { gateway } from "../gateway";

export const ConnectEmployeeNine = () => {
  const currPage = "/connect";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(false); // true indica que inicialmente está deshabilitado
  const signupEmployeeState = useSelector((state) => {return state.signupEmployeeState});

useEffect(() =>{
    if (signupEmployeeState.transport != null) {
      setSelected(true)
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {

  }, [selected]);
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          minHeight: "600px", // Establece una altura mínima
        }}
        sx={{
          marginTop: { xs: 2, md: 3 },
          width: { xs: "100%", md: 750 },
          marginLeft: { xs: "0", md: -20 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              How do you transport?
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ButtonBaseTransport setSelectedParent={setSelected} ></ButtonBaseTransport>
          </Grid>

          <Grid container margin="auto" sx={{ height: "100%" }}>
            <Grid
              item
              xs
              sx={{
                mr: 1,
                height: "20px",
                display: "flex",
                marginLeft: "10px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SignUpNextButton
                // type="submit"
                disabled={!selected} // Deshabilitar o habilitar el botón basado en el estado `selected`
                sx={{
                  marginTop: { xs: -2, md: "35%" },
                }}
                nextViewEntityState={nextViewSignupEmployeeState}
                previousViewEntityState={previousViewSignupEmployeeState}
                onSubmit={() => {}}
              ></SignUpNextButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
