import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../../components/CompanyInfo";
import { CompanyTeam } from "../../components/CompanyTeam";
import { CompanyMetrics } from "../../components/CompanyMetrics";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { AdminsListTeam } from "../../components/AdminsList";
import { UserQuickCreation } from "../../components/UserQuickCreation";
export default function AdminsPageLayout({ nameCompany }) {
  const company = useSelector((state) => state.userState.company);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <>
      <Box
        minHeight="90vh"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // backgroundColor: "blue",
          height: "90vh",
          width: "100%",
          padding: 1,
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 0.5s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", padding: "0", margin: "0" }}
        >
          <UserQuickCreation Scope={"admins"}></UserQuickCreation>
          <AdminsListTeam sx={{ padding: "0", margin: "0" }}></AdminsListTeam>
        </Box>
      </Box>
    </>
  );
}
