import React from "react";
import { Grid, Typography } from "@mui/material";

export const SmallHeaderMenu = ({ children }) => {
  return (
    <Grid
      container
      id="data-header"
      sx={{
        height: "3em",
        width: "100%",
        backgroundColor: "#d4d6da",
      }}
    >
      <Grid id="data-hader-text" item xs={8}>
        <Typography
          fontSize={"1.3em"}
          textAlign={"center"}
          sx={{
            display: "flex",
            justifyContent: "center", // Esto centra horizontalmente
            alignItems: "center", // Esto centra verticalmente
            height: "100%",
          }}
          variant="h5"
        >
          Request
        </Typography>
      </Grid>
      <Grid
        id="data-hader-text"
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
