import PageWrapper from "../components/layout/PageWrapper";
import { Route } from "react-router-dom";
import { appRoutes } from "../routes/appRoutes";
export const generateRoute = (routes) => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper>{route.element}</PageWrapper>}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.state ?? undefined}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    )
  );
};

export const routes = generateRoute(appRoutes);
