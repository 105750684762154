import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  companyName: " ",
  employeeIdNumber: "",
  phone: "",
  address: "",
  state: "",
  country: "",
  zipCode: "",
  residentialServices: [],
  commercialServices: [],
  availability: null,
  servicesYourOffer: undefined,
  imageUrl: null,
  imageFile: null,
  coverageAreas: [],
  currentView: 0,
  totalViews: 8,
  companyClassificatiion: "",
  messageToUser: "",
};

export const signupBusinessStateSlice = createSlice({
  name: "signupBusinesstateSlice",
  initialState,
  reducers: {
    updateSignupBusinessState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }, // state.totalViews - 1 is equal to 6 because we start counting in 0
    nextViewSignupBusinessState: (state, action) => {
      return {
        ...state,
        currentView:
          state.currentView >= state.totalViews - 1 ? 0 : state.currentView + 1,
      };
    },
    previousViewSignupBusinessState: (state, action) => {
      return {
        ...state,
        currentView: state.currentView <= 0 ? 0 : state.currentView - 1,
      };
    },
    resetSignupBusinessState: () => {
      return initialState;
    },
  },
});

export const {
  updateSignupBusinessState,
  resetSignupBusinessState,
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
} = signupBusinessStateSlice.actions;
export default signupBusinessStateSlice.reducer;
