import React, { useState } from "react";
import Box from "@mui/material/Box";
import { BaseDialog } from "./common/BaseDialog";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import QuestionMark from "@mui/icons-material/QuestionMark";
import WhatsApp from "@mui/icons-material/WhatsApp"

export const HelpFloatingButton = ({ bgColor = "#243447" }) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Box sx={{ position: "fixed", bottom: "1em", right: "3%" }}>
        <IconButton onClick={handleOpenDialog} ria-label="add" sx={{ backgroundColor: bgColor, width: "30px", height: "30px", "&:hover": { backgroundColor: bgColor, opacity: 0.6 } }} size="small">
          <QuestionMark sx={{ width: "18px", color: "white" }} />
        </IconButton>
      </Box>
      <BaseDialog openState={open} setOpenState={setOpen} title="Contact Info" titleSx={{ color: bgColor, fontWeight: "bold" }} content={<SupportContact></SupportContact>}></BaseDialog>
    </>
  );
};

const SupportContact = () => {
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const message = "Send us a message for any questions or problems with the application.";

  const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;


  return (
    <Box>
      <Typography>{message}</Typography>
      <Grid sx={{ display: "flex", mt: "10px" }}>
        <Button
          variant="contained"
          startIcon={<WhatsApp />} // Optional: Use an icon
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            backgroundColor: "rgba(74,196,89,255)"
          }}
        >
          Contact Us
        </Button>
      </Grid>
    </Box>
  );
};
