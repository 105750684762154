import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ConnectEmployeeSix } from "./connectEmployeeSix";
import { ConnectEmployeeOne } from "./connectEmployeeOne";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  resetSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { ConnectEmployeeSeven } from "./connectEmployeeSeven";
import { ConnectEmployeeEight } from "./connectEmployeeEight";
import { ConnectEmployeeNine } from "./connectEmployeeNine";
import { ConnectEmployeeWelcome } from "./connectEmployeeWelcome";
import { SignUpNextButton } from "./signupNextButton";
import { ConnectEmployeeRequirements } from "./connectEmployeeRequirements";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { CreationAccountError } from "./common/CreationAccountError";
import { gateway } from "../gateway";

export const SignUpEmployee = () => {
  const [isFormValid, setIsFormValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { showBoundary } = useErrorBoundary();

  const [isVisible, setIsVisible] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [email, setEmail] = useState(false);
  const [emailInUseMessage, setemailInUseMessage] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const dispatch = useDispatch();
  const signupEmployeeState = useSelector((state) => state.signupEmployeeState);
  const { residentialServices: checkedResidentialService } =
    signupEmployeeState;
  const { commercialServices: checkedCommercialServices } = signupEmployeeState;
  const { availability: checkedAvailability } = signupEmployeeState;
  const { requirements: checkedRequirements } = signupEmployeeState;

  const { currentView } = signupEmployeeState;

  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Si hay un timerId existente, limpiarlo
    if (timerId) {
      clearTimeout(timerId);
    }

    // Establecer un nuevo timer para la verificación de disponibilidad del email
    const newTimerId = setTimeout(() => {
      if (email) {
        checkEmailAvailability(email);
      }
    }, 200); // 500ms de retraso

    setTimerId(newTimerId);

    // Limpiar el timer al desmontar
    return () => clearTimeout(newTimerId);
  }, [email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    dispatch(updateSignupEmployeeState({ [name]: value }));
  };

  const handleSubmit = async (event) => {
    if (!passwordMatch) {
      return false;
    }
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    const formData = new FormData(event.currentTarget);
    const userData = {
      email: formData.get("email"),
      password: formData.get("password"),
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
    };
    dispatch(updateSignupEmployeeState(userData));
    setIsFormValid(true); // El formulario es válido
    setIsLoading(false);
    dispatch(nextViewSignupEmployeeState());
    setOpen(false);
    return true;
  };

  const checkEmailAvailability = async (email) => {
    try {
      const response = await gateway.get(`/users/isemailavailable/${email}`);
      const { data } = response;
      console.log("my Data: ", data);
      if (!data?.data?.isAvailable) {
        setEmailInUse(true);
        setemailInUseMessage(data.message);
      } else {
        setEmailInUse(false);
        setemailInUseMessage("");
      }
    } catch (error) {
      console.error("email ver error: ", error);
      switch (error?.name) {
        case "AxiosError":
          break;
        case "ERR_CONNECTION_REFUSED":
          showBoundary(error);
          break;
        default:
          break;
      }
    }
  };

  const handleEmailBlur = () => {
    const email = signupEmployeeState.email;
    checkEmailAvailability(email);
  };

  const handlePasswordChange = (event) => {
    handleInputChange(event);
    if (signupEmployeeState.password !== passwordRepeat && passwordRepeat != '') {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };
  
  return (
    <div>
      <Container component="main" maxWidth="xs" sx={{ width: "100%" }}>
        <CssBaseline />
        {currentView === 0 ? (
          <Box
            style={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end", // Alinea los elementos al final de la dirección principal (vertical)
              minHeight: "auto",
              opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
              transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
            }}
            sx={{
              marginTop: { xs: -1, md: 0 },
            }}
          >
            <Grid
              container
              sx={{
                bgcolor: "none",
                width: "100%",
              }}
            >
              <Grid
                item
                xs
                sx={{
                  bgcolor: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <BackPage currPage={currPage} /> */}
              </Grid>
              <Grid item sx={{ bgcolor: "none" }} xs>
                <Avatar
                  sx={{
                    m: 5,
                    bgcolor: "#2196f3",
                    width: 80,
                    height: 80,
                    backgroundColor: "#233044",
                  }}
                >
                  <PortraitOutlinedIcon />
                </Avatar>
              </Grid>

              <Grid item sx={{ bgcolor: "none" }} xs></Grid>
            </Grid>

            <Typography component="h1" variant="h3">
              Sign Up
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container margin="normal">
                <Grid item xs sx={{ mr: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="Name"
                    name="firstName"
                    autoFocus
                    onChange={handleInputChange}
                    value={signupEmployeeState.firstName}
                  />
                </Grid>
                <Grid item xs sx={{ ml: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={handleInputChange}
                    value={signupEmployeeState.lastName}
                  />
                </Grid>
              </Grid>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
                value={signupEmployeeState.email}
                error={emailInUse}
                helperText={emailInUse ? emailInUseMessage : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={signupEmployeeState.password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility></Visibility>
                      ) : (
                        <VisibilityOff></VisibilityOff>
                      )}
                    </IconButton>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordRepeat"
                label="Confirm Password"
                type="password"
                id="passwordRepeat"
                value={passwordRepeat}
                onChange={(e) => {
                  const repeatPassword = e.target.value;
                  setPasswordRepeat(repeatPassword);

                  // Verifica si las contraseñas coinciden
                  if (repeatPassword !== signupEmployeeState.password) {
                    setPasswordMatch(false); // Las contraseñas no coinciden
                  } else {
                    setPasswordMatch(true); // Las contraseñas coinciden
                  }
                }}
                error={!passwordMatch} // Marca el campo en error si las contraseñas no coinciden
                helperText={!passwordMatch ? "Passwords do not match" : ""}
              />
              <Grid
                item
                xs={12} // Ocupa todo el ancho en pantallas pequeñas
                style={{
                  display: "flex", // Usa flexbox para alinear el contenido
                  justifyContent: "center", // Centra el contenido horizontalmente
                  alignItems: "center", // Centra el contenido verticalmente
                  width: "100%", // Asegúrate de que ocupa todo el ancho
                }}
              >
                <SignUpNextButton
                  type="submit"
                  disabled={!passwordMatch || emailInUse} // Deshabilitar el botón si el formulario no es válido NOTA**** use this only for type submit
                  nextViewEntityState={nextViewSignupEmployeeState}
                  previousViewEntityState={previousViewSignupEmployeeState}
                  currentView={currentView}
                  initialView="/signup/"
                  // onSubmit={}
                ></SignUpNextButton>
                {/* <Button
                  type="submit"
                  style={{
                    marginTop: "5%",
                    borderRadius: "10px",
                    width: "50%", // Ancho del botón
                    backgroundColor: "#233044",
                    color: "white",
                  }}
                  variant="contained"
                  disableElevation
                >
                  Next
                </Button> */}
              </Grid>
            </Box>
          </Box>
        ) : currentView === 1 ? (
          <ConnectEmployeeOne
            componentState={signupEmployeeState}
            nextViewComponentState={nextViewSignupEmployeeState}
            previousViewEntityState={previousViewSignupEmployeeState}
            updateComponentState={updateSignupEmployeeState}
          ></ConnectEmployeeOne>
        ) : currentView === 2 ? (
          <ConnectEmployeeSeven
            checkedState={checkedResidentialService}
            nextViewComponentState={nextViewSignupEmployeeState}
            previousViewEntityState={previousViewSignupEmployeeState}
            updateComponentState={updateSignupEmployeeState}
          ></ConnectEmployeeSeven>
        ) : currentView === 3 ? (
          <ConnectEmployeeEight
            checkedState={checkedCommercialServices}
            nextViewComponentState={nextViewSignupEmployeeState}
            previousViewEntityState={previousViewSignupEmployeeState}
            updateComponentState={updateSignupEmployeeState}
          ></ConnectEmployeeEight>
        ) : currentView === 4 ? (
          <ConnectEmployeeRequirements
            checkedState={checkedRequirements}
            nextViewComponentState={nextViewSignupEmployeeState}
            previousViewEntityState={previousViewSignupEmployeeState}
            updateComponentState={updateSignupEmployeeState}
          ></ConnectEmployeeRequirements>
        ) : currentView === 5 ? (
          <ConnectEmployeeSix
            checkedState={checkedAvailability}
            nextViewComponentState={nextViewSignupEmployeeState}
            previousViewEntityState={previousViewSignupEmployeeState}
            updateComponentState={updateSignupEmployeeState}
          ></ConnectEmployeeSix>
        ) : currentView === 6 ? (
          <ConnectEmployeeNine></ConnectEmployeeNine>
        ) : currentView === 7 ? (
          <ErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => {
              return (
                <CreationAccountError
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  resetComponentViewState={resetSignupEmployeeState}
                />
              );
            }}
            onError={(error, info) => {
              console.log(error);
              console.log(info);
            }}
          >
            <ConnectEmployeeWelcome
              componentState={signupEmployeeState}
              resetEntityState={resetSignupEmployeeState}
            ></ConnectEmployeeWelcome>
          </ErrorBoundary>
        ) : undefined}
      </Container>
    </div>
  );
};
