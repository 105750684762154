import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography } from "@mui/material";
import { TodoHeaderQuickMenu } from "./todoHeaderQuickMenu";
import { useEffect } from "react";
import { useState } from "react";

const columns = [
  { field: "id", headerName: "ID", width: 80 },
  {
    field: "homeAddress",
    headerName: "Home Address",
    width: 200,
    editable: false,
  },
  { field: "visitDate", headerName: "Visit Date", width: 130, editable: false },
  { field: "visitTime", headerName: "Visit Time", width: 130, editable: false },
  {
    field: "estimatedDuration",
    headerName: "Duration",
    width: 130,
    editable: false,
  },
  {
    field: "employeeName",
    headerName: "Employee Name",
    width: 150,
    editable: false,
  },
  {
    field: "employeeContact",
    headerName: "Employee Contact",
    width: 150,
    editable: false,
  },
  {
    field: "employeeEmail",
    headerName: "Employee Email",
    width: 150,
    editable: false,
  },
  {
    field: "clientName",
    headerName: "Client Name",
    width: 150,
    editable: false,
  },
  {
    field: "clientContact",
    headerName: "Client Contact",
    width: 150,
    editable: false,
  },
  {
    field: "cleaningTasks",
    headerName: "Cleaning Tasks",
    width: 200,
    editable: false,
  },
  {
    field: "suppliesNeeded",
    headerName: "Supplies Needed",
    width: 160,
    editable: false,
  },
  {
    field: "specialInstructions",
    headerName: "Special Instructions",
    width: 200,
    editable: false,
  },
  { field: "priority", headerName: "Priority", width: 110, editable: false },
  {
    field: "taskStatus",
    headerName: "Task Status",
    width: 120,
    editable: false,
  },
  {
    field: "clientNotes",
    headerName: "Client Notes",
    width: 200,
    editable: false,
  },
  {
    field: "postCleaningComments",
    headerName: "Post-Cleaning Comments",
    width: 200,
    editable: false,
  },
];

const mockTasks = [
  {
    id: 1,
    homeAddress: "Centro 43c 912",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "15:00",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Pending",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 12,
    homeAddress: "46 E #913",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "10:00",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Pending",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 13,
    homeAddress: "Norte Mid",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "12:30",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Completed",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 153,
    homeAddress: "Norte Mid",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "12:30",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Completed",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 199,
    homeAddress: "Norte Mid",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "12:30",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Completed",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 18,
    homeAddress: "Norte Mid",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "12:30",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Completed",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
  },
  {
    id: 15,
    homeAddress: "Norte Mid",
    visitDate: new Date("2023-12-13T15:00:00Z").toISOString(),
    visitTime: "12:30",
    estimatedDuration: "3 hours",
    clientName: "Jane Smith",
    clientContact: "jane.smith@example.com",
    cleaningTasks: "Kitchen deep clean, Bathroom sanitation",
    suppliesNeeded: "Disinfectants, Gloves",
    specialInstructions: "Use eco-friendly products",
    priority: "Medium",
    taskStatus: "Completed",
    clientNotes: "The key is under the mat.",
    postCleaningComments: "",
    employeeId: "5572",
    employeeName: "Carlos Yola",
    employeeContact: "9992674470",
    employeeEmail: "carlos@gmail.com",
    latitude: 20.941556744903757,
    longitude: -89.59192035413724,
    isRepetitive: true,
    repetitionInterval: ["mon", "tue", "wen", "thu", "fri", "sat", "sun"], // ["mon", "tue", "wen", "thu", "fri"], ["mon", "tue", "wen", "thu", "fri", "sat"]
  },
];

export function DataTodo() {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  useEffect(() => {
    // Este efecto se ejecutará cada vez que `rowSelectionModel` cambie.
    console.log(rowSelectionModel);
  }, [rowSelectionModel]);
  return (
    <Box
      sx={{
        width: "90%",
        margin: 0,
        backgroundColor: "background.paper",
      }}
    >
      <Grid
        container
        id="data-header"
        sx={{
          height: "3em",
          width: "100%",
          backgroundColor: "#d4d6da",
        }}
      >
        <Grid id="data-hader-text" item xs={8}>
          <Typography
            fontSize={"1.3em"}
            textAlign={"center"}
            sx={{
              display: "flex",
              justifyContent: "center", // Esto centra horizontalmente
              alignItems: "center", // Esto centra verticalmente
              height: "100%",
            }}
            variant="h5"
          >
            Request
          </Typography>
        </Grid>
        <Grid
          id="data-hader-text"
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <TodoHeaderQuickMenu></TodoHeaderQuickMenu>
        </Grid>
      </Grid>
      <DataGrid
        sx={{
          height: "80%",
        }}
        rows={mockTasks}
        columns={[columns[0], columns[8], columns[1], columns[3], columns[14]]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
      />
    </Box>
  );
}
