import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { Loader } from "./common/Loader";
import { useDispatch, useSelector } from "react-redux";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { gateway } from "../gateway";
import { LoaderComponent } from "./Loader";

export const ForgotPassword = () => {
  const [isVisible, setIsVisible] = useState(false);
  const appState = useSelector((state) => state.appState);
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const [base, reset, resetToken] = pathname.split("/");

  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    // console.log(resetToken);
    return () => clearTimeout(timeout);
  }, []);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const pathSignup = "/signup";
  const currPath = "/signin";
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Mostrar el Loader
    setOpen(true); // Abrir el diálogo

    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email"),
    };

    try {
      const response = await gateway.post(
        `/users/send-reset-password`,
        userData
      );
      if (response.status === 200) {
        // const accessToken = response.data.message.token.token;
        // setToken(accessToken);
        // localStorage.setItem("token", accessToken);
        navigate("/signin");
        window.location.reload();
      } else if (response.status === 401) {
        setError("Bad Credentials. Please try again.");
      } else {
        console.error("Error en el inicio de sesión");
      }
    } catch (error) {
      console.error("Error en la solicitud", error);
    } finally {
      setIsLoading(false); // Ocultar el Loader
      setOpen(false); // Cerrar el diálogo
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            minHeight: "50vh",
            opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
            transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
          }}
        >
          <Avatar sx={{ m: 5, bgcolor: "#2196f3", width: 80, height: 80 }}>
            <LockResetOutlinedIcon sx={{ width: "50%", height: "50%" }} />
          </Avatar>

          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send reset link
            </Button>
          </Box>
        </Box>
      </Container>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
};
