import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avatar: null,
  email: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  country: "",
  company: {
    businessName: "",
    businessPhone: "",
    dba: "",
    country: "",
    state: "",
    address: "",
    zipCode: "",
    coverageCountry: "",
    coverageState: "",
    coverageNationWide: false,
  },
  residentialServices: [],
  commercialServices: [],
  role: {
    type: "",
    name: "",
  },
  coverageAreas: [],
};

const userStateSlice = createSlice({
  initialState,
  name: "userStateSlice",
  reducers: {
    updateUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetUser: (state, action) => {
      return {
        avatar: "",
        email: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        country: "",
        company: {
          businessName: "",
          businessPhone: "",
          dba: "",
          country: "",
          state: "",
          address: "",
          zipCode: "",
          coverageCountry: "",
          coverageState: "",
          coverageNationWide: false,
        },
        role: {
          type: "",
          name: "",
        },
        coverageArea: {
          location: null,
          state: null,
          city: null,
          address: null,
          milesOfCoverage: null,
          latitude: null,
          longitude: null,
        },
        coverageAreas: [],
      };
    },
  },
});

export const { updateUser, resetUser } = userStateSlice.actions;
export default userStateSlice.reducer;
