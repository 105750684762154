import React, { useEffect, useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { SuperAdminBaseSectionCard } from "../SuperAdminBaseSectionCard";
import { assets } from "../../assets";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppState } from "../../redux/features/appStateSlice";
import { useErrorBoundary } from "react-error-boundary";
import { getUsersQuickSummary } from "../../utils";
import { LoaderComponent } from "../Loader";

export const AdminDashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const token = localStorage.getItem("token");
  const { showBoundary } = useErrorBoundary();
  const [usersSummary, setUsersSummary] = useState({
    individual: {
      total: undefined,
      thisMonth: undefined,
      today: undefined,
    },
    business: {
      total: undefined,
      thisMonth: undefined,
      today: undefined,
    },
    total: {
      total: undefined,
      thisMonth: undefined,
      today: undefined,
    },
  });

  const handleGetUsersSummary = async () => {
    try {
      setLoading(true);
      const usersSummary = await getUsersQuickSummary(token);
      setUsersSummary(usersSummary);
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setAppState({ currPathState: "/" }));
    handleGetUsersSummary();
  }, []);

  return (
    <div>
      <CssBaseline></CssBaseline>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
          mt: "5em",
          flexWrap: "wrap",
        }}
      >
        <SuperAdminBaseSectionCard
          cbOnClick={() => {
            dispatch(setAppState({ prevPathState: "/" }));
            navigate("/total-registered-business");
          }}
          title={"Total Registered Business"}
          icon={assets.images.bussinesAdminIcon}
          totalCount={usersSummary.business.total}
          todayCount={usersSummary.business.today}
          monthCount={usersSummary.business.thisMonth}
        ></SuperAdminBaseSectionCard>
        <SuperAdminBaseSectionCard
          cbOnClick={() => {
            dispatch(setAppState({ prevPathState: "/" }));
            navigate("/total-registered-individuals");
          }}
          title={"Total Registered Individuals"}
          icon={assets.images.employee}
          totalCount={usersSummary.individual.total}
          todayCount={usersSummary.individual.today}
          monthCount={usersSummary.individual.thisMonth}
        ></SuperAdminBaseSectionCard>
        <SuperAdminBaseSectionCard
          cbOnClick={() => {
            dispatch(setAppState({ prevPathState: "/" }));
            navigate("/total-registered-users");
          }}
          title={"Total Registered Users"}
          icon={assets.images.personsAdminIcon}
          totalCount={usersSummary.individual.total}
          todayCount={usersSummary.individual.today}
          monthCount={usersSummary.individual.thisMonth}
        ></SuperAdminBaseSectionCard>
        {/* <SuperAdminBaseSectionCard
          cbOnClick={() => {
            dispatch(setAppState({ prevPathState: "/" }));
            navigate("/sales");
          }}
          title={"Sales "}
          icon={assets.images.salesAdminIcon}
          totalCount={`$${1604}`}
          todayCount={6}
          monthCount={17}
        ></SuperAdminBaseSectionCard> */}
      </Box>
      <LoaderComponent open={loading} type="card" hideBackdrop={false}></LoaderComponent>
    </div>
  );
};
