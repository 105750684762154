import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  markerCount: 0,
};

export const markerStateSlice = createSlice({
  name: "markerState",
  initialState,
  reducers: {
    addMarkerCount: (state) => {
      state.markerCount += 1;
    },
    deleteMarkerCount: (state) => {
      state.markerCount = Math.max(0, state.markerCount - 1);
    },
    resetMarkerCount: (state) => {
      state.markerCount = 0;
    },
  },
});

export const { addMarkerCount, deleteMarkerCount, resetMarkerCount } = markerStateSlice.actions;

export default markerStateSlice.reducer;
