import React, { useState } from "react";
import { Card, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import { PlanBanner } from "./PlanBanner";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector, useDispatch } from "react-redux";
import { setPlanSelected } from "../redux/features/planStateSlice";
import { BusinessContact } from "./businessContact";


export const Plan = ({ title, description, benefits, price, type, frequency, bannerHexColor, planId, isCustom, isPopular }) => {
  const [contactUs, setContactUs] = useState(false);
  const width = {
    md: "310px",
  };
  const height = {
    md: "100%",
  };
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        width,
        height,
        position: "relative",
        animation: "fadeIn 1.5s", // Aplicando animación
        "@keyframes fadeIn": {
          // Definiendo la animación
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        transition: "transform 0.3s ease-in-out", // Animación suave
        "&:hover": {
          // Efecto hover para la Card
          transform: "scale(1.025)", // Hace que la Card sea ligeramente más grande cuando se selecione
        },
      }}
    >
      <PlanBanner color={bannerHexColor} width="180px" height="18%">
        <Typography variant="h3">${price.amount}</Typography>
      </PlanBanner>
      {isPopular ? <p className="badge">Popular</p> : <></>}
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "2em",
          m: "10px",
        }}
      >
        <Typography variant="h3" color="initial" sx={{ fontWeight: "300px", textAlign: "center" }}>
          {title}
        </Typography>
        <Typography sx={{ position: "absolute", top: "calc(10% + 8em)", left: 195 }} variant="subtitle2" color="gray">
          {frequency}
        </Typography>
        <Typography variant="subtitle1" color="initial" sx={{ mt: "60%", mb: "10px" }}>
          {description}
        </Typography>
        <List>
          {benefits?.map((benefit, index) => (
            <ListItem key={`ListItem-${index}`} sx={{ m: 0, p: 0 }}>
              <ListItemIcon>
                <CircleIcon sx={{ color: bannerHexColor }}></CircleIcon>
              </ListItemIcon>
              <ListItemText
                primary={benefit}
                // primaryTypographyProps={{ fontWeight: "bold" }}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          sx={{
            backgroundColor: bannerHexColor,
            marginTop: "auto",
          }}
          //TODO: Plan Selected
          onClick={() => {
            isCustom ? setContactUs(true) :
              dispatch(setPlanSelected({ planSelected: { name: title, type, benefits, price, id: planId } }));
          }}
        >
          CHOOSE PLAN
        </Button>
      </Card>
      {
        contactUs ? <BusinessContact bannerHexColor={"#005eff"} setContactUs={setContactUs}></BusinessContact> : <></>
      }
    </Box>
  );
};
