import { createSlice } from "@reduxjs/toolkit";
import { appStates } from "./appStateSlice";

export const PlanType = {
  FREE_TRIAL: "free_trial",
  STANDARD: "standard",
  CORPORATION: "corporation",
};

export const PlanNameFromType = {
  free_trial: "Free Trial",
  standard: "Standard",
  corporation: "Corporation",
};

const initialState = {
  currState: appStates.PLANS,
  planSelected: undefined,
  ticket: undefined,
  upgradeFreeTrial: false,
};

export const planStateSlice = createSlice({
  name: "planState",
  initialState,
  reducers: {
    setPlanSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setPlanSelected } = planStateSlice.actions;

export default planStateSlice.reducer;
