import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { updateSignupEmployeeState } from "../redux/features/signupEmployeeStateSlice";

export const CheckExperienceCommercial = ({
  title,
  data,
  updateComponentState,
  checkedState,
  setChanges = () => {},
}) => {
  const dataList = data;
  const [commercialServices, setcommercialServices] = useState(
    checkedState || []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Esta lógica asegura que el estado se actualice solo cuando cambie checkedState
    setcommercialServices(checkedState);
  }, [checkedState]);

  const handleCheckboxChange = (event, service) => {
    setChanges(true);
    if (event.target.checked) {
      setcommercialServices((prev) => [...prev, service]);
    } else {
      setcommercialServices((prev) =>
        prev.filter((item) => item.id !== service.id)
      );
    }
  };

  useEffect(() => {
    console.log(commercialServices);
    dispatch(updateComponentState({ commercialServices }));
  }, [commercialServices]);

  // Función para verificar si un item está seleccionado
  const isChecked = (item) => {
    return (
      Array.isArray(commercialServices) &&
      commercialServices.some((service) => service.id === item.id)
    );
  };

  return (
    <div
      style={{
        display: "flex", // Utilizamos flexbox para posicionar los div en columnas
        flexDirection: "column", // Columnas en lugar de filas
        background: "white",
        width: "100%",
        borderRadius: "10px",
        height: "auto",
        padding: "10px",
      }}
    >
      <div>
        <p
          style={{
            fontFamily: "Tahoma, sans-serif",
            color: "#233044",
            fontWeight: "bold",
            fontSize: "1.25rem",
            textAlign: "center",
            marginTop: "-2px",
          }}
        >
          {title ?? "Commercial Cleaning Services"}
        </p>

        <FormControl
          component="fieldset"
          style={{ height: "400px", overflow: "auto", width: "100%" }}
        >
          {dataList.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked(item)}
                  onChange={(e) => handleCheckboxChange(e, item)}
                />
              }
              label={item.workServices.name}
            />
          ))}
        </FormControl>
      </div>
    </div>
  );
};
