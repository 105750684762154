import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";

import { IconButton, List, ListItem, Skeleton, Slide, Typography } from "@mui/material";
import { Check, Close, Download } from "@mui/icons-material";
import { BaseDialog } from "./common/BaseDialog";
import { useSelector } from "react-redux";
import { EConnectionStatus, getMyConnections, IRoles, updateMyConnections } from "../utils";
import { socket } from "../sockets";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const userRole = useSelector((state) => state.userState.role);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "date",
      label: "Date",
      disablePadding: true,
      numeric: false,
      style: {
        maxWidth: 130,
      },
    },
    {
      id: userRole.type === IRoles.Employee ? "cleaner" : "cleaner",
      label: userRole.type === IRoles.Employee ? "Company" : "Cleaner",
      disablePadding: false,
      numeric: false,
      style: {
        minWidth: 150,
        maxWidth: 200,
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    {
      id: "typeOfJob",
      label: "Type of Job",
      disablePadding: false,
      numeric: false,
      style: {
        maxWidth: 130,
      },
    },
    {
      id: "typeOfService",
      label: "Type of Services",
      disablePadding: false,
      numeric: false,
      style: {
        maxWidth: 140,
      },
    },
    {
      id: "requirements",
      label: "Requirements",
      disablePadding: false,
      numeric: false,
      style: {
        maxWidth: 140,
      },
    },
    {
      id: "status",
      label: "Status",
      disablePadding: false,
      numeric: false,
      style: {
        manWidth: 130,
      },
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" padding="normal" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              sx={{
                color: "#1c74d4 !important",
                "&:hover": {
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "#1c74d4 !important",
                },
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "ASC"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const ConnectionRequests = () => {
  const token = localStorage.getItem("token");
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRquest, setTotalRequests] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [index, setIndex] = useState(0);
  const [requests, setRequests] = useState([]);
  const [pendingConnections, setPendingConnections] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const userRole = useSelector((state) => state.userState.role);

  const headers = ["Date", "Company", "Type of Job", "Type of Service", "Requirements", "Status"];

  useEffect(() => {
    socket.on("connectionState", (data) => {
      setRefresh((prev) => !prev);
    });
  }, []);

  useEffect(() => {
    setOpenLoader(true);
    const requestApi = `?page=${page + 1}&pagesize=${pageSize}&order=${orderBy}&ordertype=${order}`;
    getMyConnections(requestApi, token)
      .then((res) => {
        setRows(res?.items);
        setTotalRequests(res?.totalItems);
        const pendingConnections = res?.items.map((item) => ({ status: item.status, isVisible: true, id: item.id }));
        setPendingConnections(pendingConnections);
        console.log("setPendingConnection: ", pendingConnections);
      })
      .finally(() => setOpenLoader(false));
  }, [order, orderBy, page, pageSize, refresh]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConnectionsSlide = () => {
    setPendingConnections((prev) => prev.map((item, i) => (i === index ? { ...item, isVisible: false } : { ...item })));
  };

  const exportDataToCsv = () => {
    const dataRows = requests?.map((row) => [
      row?.createdAt,
      row?.company?.bussinesName,
      row?.workTypesServices[0]?.workTypes?.name,
      `"${row?.workTypesServices?.map((service) => service?.workServices?.name).join(" , ")}"`,
      `"${row?.workRequirements?.map((requirement) => requirement.name).join(" , ")}"`,
      row.status,
    ]);
    const csvData = [headers, ...dataRows];
    const csvContent = csvData?.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "My Connections.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenConfirm = (index) => {
    setIndex(index);
    setOpen(true);
  };

  const handleCloseConfirm = () => {
    setOpen(false);
  };

  const handleAcceptRequest = async () => {
    setOpenLoader(true);
    const item = pendingConnections[index];
    console.log(item);
    const id = item.id;
    setOpen(false);

    updateMyConnections(id, "accepted", token).finally(() => {
      setOpenLoader(false);
      handleConnectionsSlide();
    });

    setPendingConnections((prev) => prev?.map((item, i) => (i === index ? { ...item, status: "accepted" } : item)));
  };

  const handleOpenDecline = (index) => {
    setIndex(index);
    setOpenDecline(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleDeclineRequest = async () => {
    setOpenLoader(true);
    const item = pendingConnections[index];
    const id = item.id;
    setOpenDecline(false);

    updateMyConnections(id, "declined", token).finally(() => {
      setOpenLoader(false);
      handleConnectionsSlide();
    });
    setPendingConnections((prev) => prev?.map((item, i) => (i === index ? { ...item, status: "declined" } : item)));
  };

  const styles = {
    accepted: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#009933",
      width: "80px",
    },
    declined: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#e63900",
      width: "80px",
    },
    pending: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "gray",
      width: "80px",
    },
    actionSx: {
      justifyContent: "center",
    },
    btnAcceptSx: {
      backgroundColor: "#009933",
      color: "white",
      "&:hover": { backgroundColor: "#005c1f" },
      margin: "0 8px",
    },
    btnDeclineSx: {
      backgroundColor: "#e63900",
      color: "white",
      "&:hover": { backgroundColor: "#a12800" },
      margin: "0 8px",
    },
    btnCloseSx: {
      backgroundColor: "grey",
      color: "white",
      "&:hover": { backgroundColor: "#233044" },
      margin: "0 8px",
    },
  };

  return (
    <Box sx={{ width: { lg: "75%", md: "90%", sm: "95%", xs: "95%" }, ml: "-2em", mt: "5em" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ textAlign: "center", backgroundColor: "#d9d9d9", p: "8px", position: "relative" }}>
          <Typography fontWeight="bold">My Connections</Typography>
          <IconButton sx={{ position: "absolute", right: 5, top: 1 }} onClick={exportDataToCsv}>
            <Download></Download>
            <Typography fontWeight="bold">Export</Typography>
          </IconButton>
        </Box>
        {openLoader ? (
          <React.Fragment>
            <TableContainer>
              <Table sx={{ minWidth: 750, minHeight: 650 }} aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {new Array(10).fill(null).map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index} sx={{ cursor: "pointer" }}>
                        <TableCell colSpan={6} component="th" scope="row" padding="normal">
                          <Skeleton variant="rectangular"></Skeleton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={totalRquest}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </React.Fragment>
        ) : rows?.length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 750, minHeight: 650 }} aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {rows?.map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: "pointer" }}>
                        <TableCell component="th" scope="row" padding="normal">
                          {row.createdAt}
                        </TableCell>
                        <TableCell align="left" sx={{ minWidth: 140 }}>
                          {userRole.type === IRoles.Employee ? row?.company?.bussinesName : row?.user?.firstName + row?.user?.lastName}
                        </TableCell>
                        <TableCell align="left">{row?.workTypesServices[0]?.workTypes?.name}</TableCell>
                        <TableCell align="left" sx={{ minWidth: 120 }}>
                          <List>
                            {row?.workTypesServices?.map((item, index) => {
                              return (
                                <ListItem key={index} disablePadding>
                                  {item?.workServices?.name}.
                                </ListItem>
                              );
                            })}
                          </List>
                        </TableCell>
                        <TableCell align="right" sx={{ maxWidth: 150 }}>
                          {row?.workRequirements?.map((item, index) => {
                            return (
                              <ListItem key={index} disablePadding>
                                {item.name}
                              </ListItem>
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {userRole.type === IRoles.Employee && row.status === EConnectionStatus.PENDING ? (
                            <>
                              <Box sx={{ display: "flex-box", alignContent: "center", width: "80px" }}>
                                {pendingConnections[index].isVisible ? (
                                  <Box>
                                    <Box>
                                      <IconButton
                                        onClick={() => handleOpenConfirm(index)}
                                        size="small"
                                        sx={{ fontSize: "13px", width: "80px", color: "#009933", border: "solid 1px #009933", borderRadius: "5px" }}
                                      >
                                        <Check sx={{ fontSize: "14px" }}></Check>Accept
                                      </IconButton>
                                    </Box>
                                    <Box mt={1.5}>
                                      <IconButton
                                        onClick={() => handleOpenDecline(index)}
                                        size="small"
                                        sx={{ fontSize: "13px", width: "80px", color: "#e63900", border: "solid 1px #e63900", borderRadius: "5px" }}
                                      >
                                        <Close sx={{ fontSize: "14px" }}></Close>Decline
                                      </IconButton>
                                    </Box>
                                  </Box>
                                ) : null}

                                <Slide direction="left" in={!pendingConnections[index].isVisible} mountOnEnter unmountOnExit>
                                  <Typography style={styles[pendingConnections[index].status]}>{pendingConnections[index].status}</Typography>
                                </Slide>
                              </Box>
                            </>
                          ) : (
                            <Typography sx={styles[row.status]}>{row.status}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={totalRquest}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        ) : (
          <Box>
            <Typography sx={{ textAlign: "center", p: 3 }}>There are currently no connection requests.</Typography>
          </Box>
        )}
      </Paper>
      {/* <LoaderComponent open={openLoader} hideBackdrop="true" type="spinner"></LoaderComponent> */}

      <BaseDialog
        openState={open}
        setOpenState={handleCloseConfirm}
        title="Connection Request Confirmation"
        titleSx={{ textAlign: "center" }}
        content={`Do you confirm the connection request from ${requests[index]?.company}?`}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Accept",
            style: styles.btnAcceptSx,
            onClick: () => {
              handleAcceptRequest();
            },
          },
          {
            label: "Cancel",
            style: styles.btnCloseSx,
            onClick: () => {
              handleCloseConfirm();
            },
          },
        ]}
      ></BaseDialog>

      <BaseDialog
        openState={openDecline}
        setOpenState={handleCloseDecline}
        title="Connection Request Confirmation"
        titleSx={{ textAlign: "center" }}
        content={`Do you confirm to reject the request from ${requests[index]?.company}?`}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Decline",
            style: styles.btnDeclineSx,
            onClick: () => {
              handleDeclineRequest();
            },
          },
          {
            label: "Cancel",
            style: styles.btnCloseSx,
            onClick: () => {
              handleCloseDecline();
            },
          },
        ]}
      ></BaseDialog>
    </Box>
  );
};
