import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assets } from "../../assets";
import { Box, Typography } from "@mui/material";
import { BaseDialog } from "../common/BaseDialog";

const styles = {
  primarySx: {
    color: "#1769aa",
  },
  secondySx: {
    color: "#ab003c",
  },
};

export const ErrorCoverageArea = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    resetErrorBoundary();
    navigate("/"); // Navega a la página de inicio
  };

  const handleRetry = () => {
    resetErrorBoundary(); // Solo resetea el error
  };

  const userFriendlyErrorMessage = () => {
    switch (error.status) {
      case 400:
        return "The information submitted was not correct. Please check your input and try again.";
      case 401:
        return "You are not authorized to perform this action. Please log in and try again.";
      case 500:
        return "We're sorry, there was a problem creating your account. Please try again later.";
      default:
        return "An unexpected error occurred. Please try again.";
    }
  };

  const ErrorMessage = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={assets.images.locationNotFound}
            alt="Location not found"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
        <Typography sx={{ color: "red" }}>{userFriendlyErrorMessage()}</Typography>
      </>
    );
  };


  return (
    <div key="error">
      <BaseDialog
        openState={true}
        title="Error when fetch you stored location"
        content={<ErrorMessage></ErrorMessage>}
        actions={[
          {
            label: "Return to Home",
            onClick: handleGoHome,
            style: styles.primarySx,
          },
          {
            label: "Try Againg",
            onClick: handleRetry,
            style: styles.secondySx,
          },
        ]}
      ></BaseDialog>
    </div>
  );
};
