import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import signupBusinessStateSlice, {
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
  updateSignupBusinessState,
} from "../redux/features/signupBusinessStateSlice";
import { MapPredictive } from "./mapPredictive";
import { SignUpNextButton } from "./signupNextButton";
import IconButtonFileUpload from "./ImageUploader";

export const ConnectEmployeeThirteen = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState,
  componentState,
}) => {
  const currPage = "/signupbusiness";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const signupBusinessState = useSelector((state) => state.signupBusinessState);
  const { currentView } = signupBusinessState;
  const handleLocationSelect = (city, state, zipCode, country, address) => {
    setCompanyCity(city);
    setCompanyState(state);
    setCompanyZipCode(zipCode);
    setCompanyCountry(country); // Asegúrate de actualizar el estado del país
    setCompanyAddress(address);
    console.log(address, state, city, zipCode, country);
    dispatch(
      updateSignupBusinessState({ address, state, city, zipCode, country })
    );
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateSignupBusinessState({ [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    const formData = new FormData(event.currentTarget);

    const userData = {
      phone: formData.get("phone"),
      employeeIdNumber: formData.get("employeeIdNumber"),
      // address: formData.get("address"),
      // country: "",
      // state: "",
      // zipCode: "",
    };
    dispatch(updateSignupBusinessState(userData));
    setIsLoading(false);
    setOpen(false);
    dispatch(nextViewSignupBusinessState());
    setIsFormValid(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "630px",
        }}
        sx={{
          marginTop: { xs: 2, md: 4 },
          width: { xs: "100%", md: 600 },
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            color: "White",
            background: "#233044",
            borderRadius: "10px",
            textAlign: "center",
            width: "100%", // Full width
            padding: "10px",
            marginBottom: "5%",
          }}
        >
          Let's create your profile
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: "90px", height: "90px" }}
              src={componentState.imageUrl}
            />
            <IconButtonFileUpload updateComponentState={updateComponentState} />

            <TextField
              margin="normal"
              required
              id="phone"
              type="phone"
              label="Phone Number"
              name="phone"
              size="small"
              variant="outlined"
              autoFocus
              onChange={handleInputChange}
              value={signupBusinessState.phone}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginBottom: "-1%",
              }}
            />
            <TextField
              margin="normal"
              required
              id="employeeIdNumber"
              label="Employer Identification Number"
              name="employeeIdNumber"
              size="small"
              variant="outlined"
              autoFocus
              onChange={handleInputChange}
              value={signupBusinessState.employeeIdNumber}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginBottom: "2%",
              }}
            />
            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <MapPredictive
                componentState={componentState}
                updateComponentState={updateComponentState}
                onLocationSelect={handleLocationSelect}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="City"
                name="city"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupBusinessState.city}
                InputLabelProps={{
                  shrink: !!signupBusinessState.city, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="State"
                name="state"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupBusinessState.state}
                InputLabelProps={{
                  shrink: !!signupBusinessState.state, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%" ,marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="Zip Code"
                name="zipCode"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupBusinessState.zipCode}
                InputLabelProps={{
                  shrink: !!signupBusinessState.zipCode, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%",}}>
              <TextField
                required
                fullWidth
                label="Country"
                name="country"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupBusinessState.country}
                InputLabelProps={{
                  shrink: !!signupBusinessState.country, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container margin="auto" sx={{ height: "100%" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              // marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SignUpNextButton
              sx={{
                marginTop: "20px",
              }}
              type="submit"
              nextViewEntityState={nextViewSignupBusinessState}
              previousViewEntityState={previousViewSignupBusinessState}
            ></SignUpNextButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
