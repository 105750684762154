import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BackPage from "./common/BackPage";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { gateway } from "../gateway";
import { useParams, useSearchParams } from "react-router-dom";
import { LoaderComponent } from "./Loader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Confetti from 'react-confetti';
import { resetConnectBusinessState } from "../redux/features/connectBusinessStateSlice";

export const ConnectACleanerIndividualAction = () => {
  // useParams es un hook que te permite acceder a los parámetros de la URL
  const { token } = useParams();
  const [open, setOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBackHomeClick = () => {
    dispatch(resetConnectBusinessState());
    navigate('/', { replace: true }); // Redirige al usuario a la ruta de inicio
    window.location.reload(); // Forza una recarga de la página
  };
  // useSearchParams es un hook que te permite trabajar con la cadena de consulta de la URL
  const [searchParams] = useSearchParams();
  const option = searchParams.get("option"); // 'accepted' o 'declined' en tu caso

  const handleConnectAction = async (token, option) => {
    setOpen(true);

    try {
      // Asumiendo que tienes un método de gateway configurado para hacer llamadas HTTP
      // y que tu endpoint de backend para manejar la acción es '/connect/individual/:token'
      const response = await gateway.get(
        `/connect/employee/action/${token}?option=${option}`
      );

      console.log("Response:", response);
      if (response.status === 200) {
        console.log("The action was processed correctly: ", response.data);
        // Aquí podrías redirigir al usuario o mostrar un mensaje de éxito
      } else {
        console.error("There was an error processing the action");
        // Aquí podrías manejar errores específicos según el código de estado
      }
    } catch (error) {
      console.error("Error in the request", error);
      // Manejar errores de la red o excepciones aquí
    } finally {
      setOpen(false); // Cerrar cualquier diálogo/modal si es necesario
    }
  };
  useEffect(() => {
    handleConnectAction(token, option);
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        
       {option === "accepted" ? (
          <Card sx={{ backgroundColor: "#1d4999", color: "#fff" }}>
             <Confetti /> 
            <CardContent>
            <Typography variant="h6" gutterBottom align="center"> {/* Centra el texto */}
              CONGRATULATIONS!
                </Typography>
                <Typography variant="h6" gutterBottom align="center"> {/* Centra el texto */}
              YOU ARE NOW CONNECTED
                </Typography>
                <Typography variant="body1" paragraph align="center"> {/* Centra el texto */}
              We have sent you an email with the company's information.
                </Typography>
                <Typography variant="body1" paragraph align="center"> {/* Centra el texto */}
              Please verify.
                </Typography>
                <Grid container justifyContent="center"> 
                <Grid item xs={4}> {/* Esto establece el tamaño del Grid a 12 y lo centra */}
                  <Button
                    variant="contained"
                    // color="secondary"
                    sx={{ mt: 2, borderRadius: 20, px: 3,width:'180px',ml:-1 }}
                    onClick={handleBackHomeClick}
                  >
                    BACK TO HOME
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : option === "declined" ? (
          <Card sx={{ backgroundColor: "#1d4999", color: "#fff" }}>
            <CardContent>
            <Typography variant="h6" gutterBottom align="center"> {/* Centra el texto */}
                WE ARE SORRY TO HEAR THAT!
              </Typography>
              <Typography variant="body1" paragraph align="center"> {/* Centra el texto */}
                No worries! You can still be connected!
              </Typography>
              <Grid container justifyContent="center"> 
                <Grid item xs={4}> {/* Esto establece el tamaño del Grid a 12 y lo centra */}
                  <Button
                    variant="contained"
                    // color="secondary"
                    sx={{ mt: 2, borderRadius: 20, px: 3,width:'180px',ml:-4 }}
                    onClick={handleBackHomeClick}
                  >
                    BACK TO HOME
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <LoaderComponent open={open}></LoaderComponent>
        )}
      </Box>
    </Container>
  );
};
