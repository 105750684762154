import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, Stack, Checkbox, Toolbar, Tooltip, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";
import { deleteUsersByAdmin, getUsersSummary } from "../utils";
import BackPage from "./common/BackPage";
import { setAppState } from "../redux/features/appStateSlice";
import { alpha } from "@mui/material/styles";
import { Delete, DeleteOutline, FilterListOutlined } from "@mui/icons-material";
import { LoaderComponent } from "./Loader";
import { SuperAdminSearch } from "./SuperAdminSearch";

export function UserSummaryTable({ typeState = undefined, currPage = "/", title = "" }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersData, setusersData] = useState({ items: [], totalItems: 0, totalPages: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = React.useState(true);

  const usersState = useSelector((state) => state.usersState);
  const token = localStorage.getItem("token");
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppState({ currPathState: currPage }));
  }, []);

  const handleGetUsersSummary = async () => {
    try {
      setLoading(true);
      const users = await getUsersSummary(typeState, page + 1, rowsPerPage, token);
      setusersData(users);
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUsersSummary();
  }, []);

  useEffect(() => {
    handleGetUsersSummary();
  }, [usersState, page, rowsPerPage]);

  useEffect(() => {
    console.log(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log(usersData);
  }, [usersData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      const allUserIds = usersData.items.map((row) => row.id);
      setSelectedRows(allUserIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowCheckboxChange = (event, userId) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, userId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== userId));
    }
  };

  const isSelected = (uid) => {
    return selectedRows.includes(uid);
  };

  const handleDeleteUsers = async () => {
    try {
      setLoading(true);
      const users = await deleteUsersByAdmin(selectedRows, token);
      setSelectedRows([]);
      await handleGetUsersSummary();
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BackPage currPage={currPage}></BackPage>
      <Box sx={{ width: "80%", padding: 2, mt: 10 }}>
        <Typography sx={{ mb: 2, mt: 2 }} variant="h3">
          {title}
        </Typography>
        <SuperAdminSearch></SuperAdminSearch>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selectedRows.length > 0 && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              }),
            }}
          >
            {selectedRows.length > 0 ? (
              <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                {selectedRows.length} selected
              </Typography>
            ) : (
              <></>
            )}

            {selectedRows.length > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    handleDeleteUsers();
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Toolbar>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {/* <Checkbox
                      color="primary"
                      indeterminate={selectedRows.length > 0 && selectedRows.length < usersData.items.length}
                      checked={selectedRows.length === usersData.items.length}
                      onChange={handleCheckboxChange}
                      inputProps={{
                        "aria-label": "select all users",
                      }}
                    /> */}
                  </TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  {typeState !== "employee" ? <TableCell>Company</TableCell> : <></>}
                  <TableCell>Phone</TableCell>

                  {typeState !== "employee" ? <TableCell>Plan</TableCell> : <></>}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox color="primary" checked={isSelected(row.uid)} onChange={(event) => handleRowCheckboxChange(event, row.uid)} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.firstName ?? ""} ${row.lastName ?? ""}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.email ?? ""}`}
                    </TableCell>
                    {typeState !== "employee" ? (
                      <TableCell component="th" scope="row">
                        {`${row.companies?.[0]?.businessName ?? ""}`}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell component="th" scope="row">
                      {`${row?.phone ?? ""}`}
                    </TableCell>{" "}
                    {typeState !== "employee" ? (
                      <TableCell component="th" scope="row">
                        {`${row?.subscription?.plan?.product?.name ?? " - "}`}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersData.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Box>
      <LoaderComponent open={loading} type="card" hideBackdrop={true}></LoaderComponent>
    </>
  );
}
