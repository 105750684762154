import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience, {
  CheckExperienceCommercial,
} from "./checkboxExperienceCommercial";
import CheckAvailability from "./checkboxAvailability";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { TimeSelectorCommercial } from "./checkBoxAvailabilityCommercial";
import { gateway } from "../gateway";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { LoaderComponent } from "./Loader";
import { SaveButton } from "./saveButton";
import { useSelector } from "react-redux";
import { updateUser } from "../redux/features/userStateSlice";

export const ConnectBusinessAvalability = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateSignupEmployeeState,
  checkedState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currPage = "/signupEmployee";
  const commertialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);
  const userState = useSelector((state) => state.userState);
  const tokenLocal = localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [availability, setMyAvailability] = useState();

  const getMyAvailability = async () => {
    try {
      const response = await gateway.get(`/availability/self`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;

      setMyAvailability(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveMyAvailability = async () => {
    try {
      const response = await gateway.put(
        `/availability/self`,
        {
          availability: userState.availability,
        },
        {
          headers: {
            Authorization: `${tokenLocal}`,
          },
        }
      );
      const data = response.data.data;
      setMyAvailability(data);
      setChanges(false);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvailabilityInfo = async () => {
    try {
      setOpen(true);
      await getMyAvailability();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const handleAvailabilityUpdate = async () => {
    try {
      setOpen(true);
      await saveMyAvailability();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (cancel) {
      handleAvailabilityInfo(); // Esta función ya obtiene los servicios y actualiza los estados.
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  useEffect(() => {
    handleAvailabilityInfo();
  }, []);
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          // height: "85vh",
        }}
        sx={{
          marginTop: { xs: "10%" },
          width: { xs: "100%", md: 600 },
          // marginLeft: { md: 60 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              Schedule Availability
            </Typography>
            <TimeSelector
              setChanges={setChanges}
              checkedState={availability}
              updateComponentState={updateUser}
            ></TimeSelector>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <SaveButton
              disabled={!changes}
              setCancel={setCancel}
              onSubmit={handleAvailabilityUpdate}
            ></SaveButton>
          </Grid>
        </Grid>
      </Box>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
