import { MoreVertOutlined } from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { gateway } from "../gateway";
import { Loader } from "./common/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserSelected } from "../redux/features/userSelectedStateSlice";
import { LoaderComponent } from "./Loader";

export const UserQuickMenu = ({ sx, user, roleScope, userUid, companyId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid, email, roles, firstName, lastName, birthDate, country } = user;

  const [anchor, setAnchor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchor);
  const token = localStorage.getItem("token");
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
    console.log(event.currentTarget);
  };

  // On select view more:
  const handleViewMore = () => {
    console.log(user);
    dispatch(
      updateUserSelected({
        uid,
        email,
        roleName: roles[0]?.name ?? null,
        roleType: roles[0]?.type ?? null,
        firstName,
        lastName,
        birthDate,
        country,
      })
    );
    navigate(`/businessprofile/${roleScope}/${user?.uid}`);
  };

  ////
  const handleClose = () => {
    setAnchor(null);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await gateway.delete(
        `users/${roleScope}/${userUid}/${companyId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        console.log(response);
      }
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <IconButton
        sx={typeof sx === "object" ? { ...sx } : undefined}
        onClick={handleClick}
      >
        <MoreVertOutlined></MoreVertOutlined>
      </IconButton>
      <Menu
        id="UserQuickMenu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="viewMore" onClick={handleViewMore}>
          {"View more"}
        </MenuItem>

        <MenuItem key="delete" onClick={handleDelete}>
          {"Delete"}
        </MenuItem>

        <MenuItem key="update" onClick={handleClose}>
          {"Modify"}
        </MenuItem>
      </Menu>
      <LoaderComponent open={isLoading} type="spinner"></LoaderComponent>
    </div>
  );
};
