import React, { useState } from "react";

import { Dialog, DialogContent } from "@mui/material";
import { LoaderNature } from "./common/LoaderNature";
import { LoaderClassic } from "./common/LoaderClassic";
import { Loader } from "./common/Loader";
import { LoadingCard } from "./common/LoadingCard";

export const LoaderComponent = ({ open, type = "classic", hideBackdrop = false }) => {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
      hideBackdrop={hideBackdrop}
    >
      <DialogContent>{type === "spinner" ? <Loader></Loader> : type === "classic" ? <LoaderClassic></LoaderClassic> : type === "card" ? <LoadingCard></LoadingCard> : <></>}</DialogContent>
    </Dialog>
  );
};
