import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import BackPage from "./common/BackPage";
import { MapPredictive } from "./mapPredictive";
import { SignUpNextButton } from "./signupNextButton";
import IconButtonFileUpload from "./ImageUploader";

export const ConnectEmployeeOne = ({
  updateComponentState,
  componentState,
}) => {
  const currPage = "/signupEmployee";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const signupEmployeeState = useSelector((state) => state.signupEmployeeState);
  const { currentView } = signupEmployeeState;
  const [isFormValid, setIsFormValid] = useState(false);

  const handleLocationSelect = (city, state, zipCode, country, address) => {
    setCompanyCity(city);
    setCompanyState(state);
    setCompanyZipCode(zipCode);
    setCompanyCountry(country); // Asegúrate de actualizar el estado del país
    setCompanyAddress(address);
    console.log(address, state, city, zipCode, country);
    dispatch(
      updateSignupEmployeeState({ address, state, city, zipCode, country })
    );
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateSignupEmployeeState({ [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    const formData = new FormData(event.currentTarget);

    const userData = {
      phone: formData.get("phone"),
      age: formData.get("age"),
      // address: formData.get("address"),
    };
    dispatch(updateSignupEmployeeState(userData));
    dispatch(nextViewSignupEmployeeState(userData));
    setIsLoading(false);
    setOpen(false);
    setIsFormValid(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "630px",
        }}
        sx={{
          marginTop: { xs: 2, md: 1 },
          width: { xs: "100%", md: 600 },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Tahoma, sans-serif",
                fontWeight: "bold",
                color: "White",
                background: "#233044",
                padding: "10px",
                width: "100%", // Full width
                marginBottom: "10px",
                borderRadius: "10px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Let's create your profile
            </Typography>
            <Avatar
              sx={{ width: "90px", height: "90px" }}
              src={componentState.imageUrl}
            />
            <IconButtonFileUpload updateComponentState={updateComponentState} />
            {/* Here is where we need to put form */}
            <TextField
              margin="normal"
              // required
              optional
              id="age"
              type="text"
              label="Age (Optional)"
              name="age"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={signupEmployeeState.age}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginBottom: "3%",
              }}
            />
            <TextField
              margin="normal"
              required
              id="phone"
              type="phone"
              label="Phone Number"
              name="phone"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={signupEmployeeState.phone}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginTop: "-1%",
              }}
              autoFocus
            />

            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <MapPredictive
                onLocationSelect={handleLocationSelect}
                updateComponentState={updateComponentState}
                componentState={componentState}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="City"
                name="city"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupEmployeeState.city}
                InputLabelProps={{
                  shrink: !!signupEmployeeState.city, // La etiqueta se encogerá si el campo 'city' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%", marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="State"
                name="state"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupEmployeeState.state}
                InputLabelProps={{
                  shrink: !!signupEmployeeState.state, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%" ,marginBottom: "2%" }}>
              <TextField
                required
                fullWidth
                label="Zip Code"
                name="zipCode"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupEmployeeState.zipCode}
                InputLabelProps={{
                  shrink: !!signupEmployeeState.zipCode, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ width: "100%", }}>
              <TextField
                required
                fullWidth
                label="Country"
                name="country"
                size="small"
                variant="outlined"
                onChange={handleInputChange}
                value={signupEmployeeState.country}
                InputLabelProps={{
                  shrink: !!signupEmployeeState.country, // La etiqueta se encogerá si el campo 'state' tiene un valor
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container margin="auto" sx={{ height: "100%" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              // marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SignUpNextButton
              sx={{
                marginTop: "20px",
              }}
              type="submit"
              nextViewEntityState={nextViewSignupEmployeeState}
              previousViewEntityState={previousViewSignupEmployeeState}
            ></SignUpNextButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
