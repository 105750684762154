import { IconButton } from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { resetUser } from "../redux/features/userStateSlice";
import { useDispatch } from "react-redux";
export function Signoff() {
  const tokenLocal = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignOff = () => {
    if (tokenLocal) {
      localStorage.removeItem("token");
      dispatch(resetUser());
      navigate("/signin");
    }
  };
  return (
    <IconButton onClick={handleSignOff}>
      <LogoutIcon></LogoutIcon>
    </IconButton>
  );
}
