import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonBaseDemo from "./layout/buttonBusinessServices";
import {
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
} from "../redux/features/signupBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectEmployeeServiceOption = () => {
  const currPage = "/connect";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(false); // true indica que inicialmente está deshabilitado
  const signupBusinessState = useSelector((state) => {
    return state.signupBusinessState;
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    dispatch(nextViewSignupBusinessState());
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height:"auto"
        }}
        sx={{
          // height: { xs: "100vh", md: "80vh" },
          marginTop: { xs: 2, md: 0},
          width: { xs: "100%", md: 700 },
          marginLeft: { xs: 0, md: -15 },
        }}
      >
        <Grid container spacing={2}>
          <Typography
            variant="body"
            style={{
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              color: "white",
              background: "rgba(35, 48, 68, 1)",
              borderRadius: "10px",
              textAlign: "center",
              width: "100%",
              padding: "10px",
              marginTop: "5%",
              marginBottom: "20px",
            }}
            sx={{
              width: { xs: "85%", md: 700 },
              marginLeft: { xs: "3%", md: 2 },
            }}
          >
            Type of services you offer?
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: { xs: 0, md: 0 },
          }}
        >
          <ButtonBaseDemo setSelectedParent={setSelected}></ButtonBaseDemo>
        </Grid>

        <Grid container margin="auto" sx={{ height: "100px" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              // marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SignUpNextButton
              // type="submit"
              disabled={!selected} // Deshabilitar o habilitar el botón basado en el estado `selected`
              sx={{
                marginTop: { xs: "5%", md: "35%" },
              }}
              nextViewEntityState={nextViewSignupBusinessState}
              previousViewEntityState={previousViewSignupBusinessState}
            ></SignUpNextButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
