import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Alert, Collapse, Slide } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { getPlanByCodeDiscount } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setPlanSelected } from "../redux/features/planStateSlice";

const statusMessageCode = {
  WARMING: "warning",
  SUCCESS: "success",
  ERROR: "error",
};

export const PromoCodeDisccount = ({ setDisccount }) => {
  const [code, setCode] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({ status: statusMessageCode.WARMING, describe: "" });
  const [usedCodes, setUsedCodes] = useState({});
  const planState = useSelector((state) => state?.planState);
  const dispatch = useDispatch();
  const validateDisccountCode = async (setDisccount = () => {}) => {
    if (usedCodes[code]) {
      setMessage({ status: statusMessageCode.WARMING, describe: "This discount code has already been used and cannot be applied again." });
      setShowMessage(true);
      return;
    }

    const planWithDiscount = await getPlanByCodeDiscount(code);
    console.log(planWithDiscount);
    const currDisccount =
      Number(planState?.planSelected.price?.amount) - Number(planWithDiscount?.billing_cycles?.find((billing_cycle) => billing_cycle?.tenure_type === "REGULAR")?.pricing_scheme?.fixed_price?.value);

    if (planWithDiscount && planWithDiscount?.id) {
      dispatch(
        setPlanSelected({
          planSelected: {
            ...planState?.planSelected,
            id: planWithDiscount?.paypalPlanId,
            discount: currDisccount,
          },
        }),
      );
    }

    if (planWithDiscount) {
      setDisccount((prev) => prev + currDisccount);
      setUsedCodes((prev) => {
        return { ...prev, [`${code}`]: true };
      });
      setMessage({ status: statusMessageCode.SUCCESS, describe: "Discount code applied successfully!" });
    } else {
      setMessage({ status: statusMessageCode.ERROR, describe: "Invalid discount code. Please check the code and try again." });
    }
    setShowMessage(true);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box display={"flex"} alignItems={"center"} sx={{ mr:.5}}>
          <LocalOfferIcon sx={{ mr: 1, transform: "scaleX(-1)" }} />
          <Typography fontWeight={"bold"}>ADD PROMO CODE</Typography>
        </Box>
        <TextField
          id="outlined-password-input"
          label="code"
          type="text"
          size="small"
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          color="info"
          onClick={() => {
            validateDisccountCode(setDisccount);
          }}
          disabled={code.length <= 0}
        >
          Apply
        </Button>
      </Box>

      <Slide sx={{ overflow: "hidden", mt: 2 }} direction="left" in={showMessage} mountOnEnter unmountOnExit>
        <Alert
          severity={message.status}
          onClose={() => {
            setShowMessage(false);
          }}
          // sx={{ overflow: "hidden" }}
        >
          {message.describe}
        </Alert>
      </Slide>
    </Box>
  );
};
