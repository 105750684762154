import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  notificationsChanges: true,
};

export const notificationsSlice = createSlice({
  name: "notificationsState",
  initialState,
  reducers: {
    updateNotificationsChanges: (state, action) => {
      return {
        notificationsChanges: !state.notificationsChanges,
      };
    },
    updateNotifications: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    pushNotification: (state, action) => {
      return {
        ...state,
        notifications: [action.payload, ...state?.notifications],
      };
    },
  },
  devTools: "production",
});

export const {
  updateNotificationsChanges,
  updateNotifications,
  pushNotification,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
