import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const countrySlice = createSlice({
  name: "contryState",
  initialState,
  reducers: {
    updateCountry: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateCountry } = countrySlice.actions;
export default countrySlice.reducer;
