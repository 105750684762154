import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  resetConnectBusinessState,
  nextViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import { Loader } from "./common/Loader";
import { gateway } from "../gateway";
import { ConnectBusinessExpandNoEmployees } from "./connectBusinessExpandNoEmployees";
import { LoaderComponent } from "./Loader";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
export const ConnectBusinessSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem("token");
  const firstMiles = 50;
  const secondMiles = 80;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const [state, setState] = useState("running");
  const [miles, setMiles] = useState(firstMiles);

  const connectBusinessState = useSelector(
    (state) => state.connectBusinessState
  );
  const { lat, lng } = connectBusinessState;

  // Función para manejar el clic en el botón "No"
  const handleNoClick = () => {
    setLoading(true);
    setIsVisible(false); // Inicia el desvanecimiento
    setTimeout(() => {
      dispatch(resetConnectBusinessState());
      setLoading(false);
      navigate("/"); // Cambia esto por tu ruta de origen principal
    }, 1000);
  };

  // Función para manejar el clic en el botón "Yes"
  const handleYesClick = () => {
    // dispatch(nextViewConnectBusinessState());
    // navigate('/next-view'); // Descomentar y cambiar por la ruta deseada
    setOpen(true);
    setState("running");
    setMiles(secondMiles);
  };

  const handleEmployeeNearby = async () => {
    try {
      const response = await gateway.get(
        `/users/nearby?latitude=${lat}&longitude=${lng}&coverageMiles=${miles}`, {
          headers: {
            Authorization: `${tokenLocal}`,
          },
        }
      );
      const data = response.data.data;
      console.log(data);
      setOpen(false);
      if (data.length <= 0) {
        if (miles === secondMiles) {
          setState("noEmployees");
          return;
        }
        setState("error");
        return;
      }
      dispatch(updateConnectBusinessState({ bestChoices: data }));
      dispatch(nextViewConnectBusinessState());
    } catch (error) {
      console.error(error);
      setOpen(false);
      setState("error");
    }
  };

  useEffect(() => {
    handleEmployeeNearby();
  }, [state]);

  return (
    <div
      key="connectacleaner-search"
      style={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
      }}
    >
      {open ? (
        <LoaderComponent open={open} type="spinner"></LoaderComponent>
      ) : state === "error" ? (
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box sx={{ bgcolor: "rgba(35, 48, 68, 1)", color: "white", p: 3 }}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={4}>
              <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                WE ARE SORRY
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <SentimentVeryDissatisfiedIcon sx={{ fontSize: 40, display: 'block', margin: 'auto' }}/>
            </Grid>
          </Grid>
            <Typography variant="h6" align="center" sx={{ mt: 1 }}>
              We couldn’t find any cleaner in your area
            </Typography>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{ p: 3, justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Would you like to expand your miles of coverage?
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", bgcolor: "rgba(35, 48, 68, 1)" }}
                onClick={handleNoClick}
              >
                NO
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", bgcolor: "rgba(35, 48, 68, 1)" }}
                onClick={handleYesClick}
              >
                YES
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : state === "noEmployees" ? (
        <ConnectBusinessExpandNoEmployees></ConnectBusinessExpandNoEmployees>
      ) : (
        <div>
          {/* Aquí puedes poner cualquier contenido adicional para cuando no hay error y el diálogo está cerrado */}
        </div>
      )}
      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </div>
  );
};
