import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ButtonBaseDemo from "./buttonUserIdentificationFirstView";
import { nextViewSignupEmployeeState } from "../redux/features/signupEmployeeStateSlice";
import ButtonBaseDemoRoutes from "./buttonUserIdentificationFirstViewRoutes";

export const ConnectEmployeeFirstView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    dispatch(nextViewSignupEmployeeState());
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <div
      key="classify Yoursel"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "0px",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          padding: "20px",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
        sx={{
          height: { xs: "100vh", md: "100vh", lg: "75vh" }, // Altura para xs, md y lg
          marginTop: { xs: 2, md: 0 },
          width: { xs: "90%", md: "80%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Typography
            variant="body"
            style={{
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              color: "white",
              background: "rgba(35, 48, 68, 1)",
              borderRadius: "10px",
              textAlign: "center",
              width: "100%",
              padding: "10px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
            sx={{
              width: { xs: "100%", md: 700 },
              marginLeft: { xs: "3%", md: 2 },
            }}
          >
            How do you classify yourself?
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: { xs: 0, md: 0 },
          }}
        >
          <ButtonBaseDemoRoutes></ButtonBaseDemoRoutes>
        </Grid>
      </Box>
    </div>
  );
};
