import { BrowserRouter, Route, Routes, useLocation, useNavigate, useParams, Navigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux"; // Importa el Provider de Redux
import { SignUp } from "./components/signup";
import { store } from "./redux/store";
import SigninupLayout from "./components/layout/SigninupLayout";
import { SignIn } from "./components/signin";
import { EmailConfirmation } from "./components/EmailConfirmation";
import { useEffect } from "react";
import { setAppState } from "./redux/features/appStateSlice";
import { ForgotPassword } from "./components/forgotPassword";
import { ResetPassword } from "./components/resetPassword";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import HomePage from "./pages/home/HomePage";
import { Analytics } from "@mui/icons-material";
import DashboardPageLayout from "./pages/dashboard/DashboardPageLayout";
import DefaultPage from "./pages/dashboard/DefaultPage";
import PageWrapper from "./components/layout/PageWrapper";
import SaasPage from "./pages/dashboard/SaasPage";
import Axios from "axios";
import { ValidateToken } from "./middlewares/validateToken";
import { EmailConfirmed } from "./components/EmailConfirmated";
import AdminsPageLayout from "./pages/dashboard/AdminsPageLayout";
import { ConnectEmployeePage } from "./pages/dashboard/ConnectEmployeePage";
import { CalculatorPage } from "./pages/dashboard/CalculatorPage";
import { SignUpEmployee } from "./components/signupEmployee";
import { SignupBusinessConnect } from "./components/signupBusinessConnect";

import { EmailConfirmationWithPassword } from "./components/EmailConfirmationWithPassword";
import EmployeesPageLayout from "./pages/dashboard/EmployeesPageLayout";
import EmployeePageLayout from "./pages/dashboard/EmployeePageLayout";
import "./App.css";
import MainLayout2 from "./components/layout/MainLayout2";
import DashboardMainPageLayout from "./pages/dashboard/DashboardMainPageLayout";
import ResponsiveAppBar from "./components/common/AppBar";
import { ConnectEmployeeFirstView } from "./components/connectEmployeeFirstView";
import { CoverageAreaBusinessPage } from "./pages/dashboard/CoverageAreaBusinessPage";
import { ServicesBusinessPage } from "./pages/dashboard/ServicesBusinessPage";
import { ConnectBusinessAlgorithm } from "./components/connectBusinessAlgorithm";
import { ConnectBusinessAccount } from "./components/ConnectBusinessAccount";
import { ConnectBusinessServices } from "./components/connectBusinessServices";
import { ConnectBusinessRequirements } from "./components/connectBusinessRequirements";
import { ConnectBusinessRequirementsBar } from "./components/connectBusinessRequirementsBar";
import { ConnectBusinessAvalability } from "./components/connectBusinessAvalability";
import { ConnectEmployeeWelcome } from "./components/connectEmployeeWelcome";
import { ConnectIndividualWelcomeMessage } from "./components/connectIndividualWelcomeMessage";
import { ConnectBusinessCoverageArea } from "./components/connectBusinessCoverageArea";
import { ConnectEmpCoverageArea } from "./components/connectEmpCoverageArea";
import { ConnectEmployeeAccount } from "./components/connectEmployeeAccount";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorHandler } from "./components/Errors/ErrorHandler";
import { IndividualDashboardLayout } from "./components/layout/IndividualDashboardLayout";
import { ConnectACleanerIndividualAction } from "./components/ConnectACleanerAction";
import { HoursOfOperation } from "./components/hoursOfOperation";
import { DeactivationMessage } from "./components/deactivationMessage";
import { updateUser } from "./redux/features/userStateSlice";
import { Loader } from "./components/common/Loader";
import { LoaderClassic } from "./components/common/LoaderClassic";
import { NotificationsCenter } from "./components/common/NotificationsCenter";
import { PlansLayout } from "./components/layout/PlansLayout";
import { AdminDashboardLayout } from "./components/layout/SuperAdminDashboardLayout";
import { NotificationSettings } from "./components/common/NotificationSettings";
import { UserSummaryTable } from "./components/Table";
import { Typography } from "@mui/material";
import { Playground } from "./components/playground";
import { setMetaDescription } from "./utils";
import { BusinessHome } from "./components/businessHome";
import { ConnectionRequests } from "./components/connectionRequests";

function App() {
  const path = "/";
  function onConnect() {
    console.log("Front is connected");
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const userState = useSelector((state) => state.userState);
  const { type: roleType } = userState.role;

  const [base, reset, resetToken] = pathname.split("/");
  
  useEffect(() => {
    dispatch(setAppState({ prevPathState: path, currPathState: pathname }));
    setMetaDescription();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <Routes>
            <Route
              path="/connectacleaner/individual/:token"
              element={
                <ValidateToken checkByParam={true} isUserToken={false}>
                  <SigninupLayout>
                    <ConnectACleanerIndividualAction></ConnectACleanerIndividualAction>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            <Route
              path="/deactivation/user/:token"
              element={
                <ValidateToken checkByParam={true} isUserToken={false}>
                  <SigninupLayout>
                    <DeactivationMessage></DeactivationMessage>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            <Route
              path="/reset/:token"
              element={
                <ValidateToken checkByParam={true} isUserToken={false}>
                  <SigninupLayout>
                    <ResetPassword></ResetPassword>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            <Route
              path="/join/user/:token"
              element={
                <ValidateToken checkByParam={true} isUserToken={false}>
                  <SigninupLayout>
                    <EmailConfirmationWithPassword></EmailConfirmationWithPassword>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            <Route
              path="/join/user/:token"
              element={
                <ValidateToken checkByParam={true} isUserToken={false}>
                  <SigninupLayout>
                    <EmailConfirmationWithPassword></EmailConfirmationWithPassword>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            {/* 
            Se pretende tener varios urls de activation
            "/activation/users/superadmin/:token": Activa una cuenta que tiene todos los accesos
            "/activation/users/admin/:token": Activa una cuenta a Admin (Hecho por un SuperAdmin)
            "/activation/users/employee/:token" "Activa una cuenta como employe de un Admin o Super Admin"
          */}
            <Route
              path="/activation/users/:token"
              element={
                <ValidateToken>
                  <EmailConfirmed></EmailConfirmed>
                </ValidateToken>
              }
            />

            <Route
              path="/signin/forgotpassword"
              element={
                <SigninupLayout>
                  <ForgotPassword></ForgotPassword>
                </SigninupLayout>
              }
            />

            <Route
              path="/signin"
              element={
                <ValidateToken>
                  <SigninupLayout>
                    <ResponsiveAppBar></ResponsiveAppBar>
                    <SignIn></SignIn>
                  </SigninupLayout>
                </ValidateToken>
              }
            />
            <Route
              path="/signup"
              element={
                <ValidateToken>
                  <SigninupLayout>
                    {" "}
                    <ResponsiveAppBar></ResponsiveAppBar>
                    <ConnectEmployeeFirstView> </ConnectEmployeeFirstView>
                    {/* <SignUpEmployee /> */}
                    <Routes>
                      <Route path="/emailconfirmation" element={<EmailConfirmation></EmailConfirmation>}></Route>
                    </Routes>
                  </SigninupLayout>
                </ValidateToken>
              }
            />

            <Route
              path="/signup/individual"
              element={
                <ValidateToken>
                  <SigninupLayout>
                    <ResponsiveAppBar></ResponsiveAppBar>
                    <SignUpEmployee />
                    <Routes>
                      <Route path="/emailconfirmation" element={<EmailConfirmation></EmailConfirmation>}></Route>
                    </Routes>
                  </SigninupLayout>
                </ValidateToken>
              }
            />
            <Route
              path="/signup/business"
              element={
                //  <ValidateToken>
                <SigninupLayout>
                  {" "}
                  <SignupBusinessConnect />
                  <Routes>
                    <Route path="/emailconfirmation" element={<EmailConfirmation></EmailConfirmation>}></Route>
                  </Routes>
                </SigninupLayout>
                //  </ValidateToken>
              }
            />
            <Route
              path="/signupEmployee"
              element={
                //  <ValidateToken>
                <SigninupLayout>
                  {" "}
                  <SignUpEmployee />
                  <Routes>
                    <Route path="/emailconfirmation" element={<EmailConfirmation></EmailConfirmation>}></Route>
                  </Routes>
                </SigninupLayout>
                //  </ValidateToken>
              }
            />
            <Route
              path="/"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2
                    child={
                      roleType === "business" ? (
                        <ConnectBusinessAlgorithm></ConnectBusinessAlgorithm>
                      ) : !userState.coverageArea && localStorage.getItem("token") && roleType === "employee" ? (
                        <ConnectIndividualWelcomeMessage></ConnectIndividualWelcomeMessage>
                      ) : localStorage.getItem("token") && roleType === "employee" ? (
                        <IndividualDashboardLayout></IndividualDashboardLayout>
                      ) : localStorage.getItem("token") && roleType === "superadmin" ? (
                        <AdminDashboardLayout></AdminDashboardLayout>
                      ) : (
                        <Loader open={true}></Loader>
                      )
                    }
                  ></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/my-connections"
              element={
                <ValidateToken>
                  <MainLayout2
                    child={
                        <ConnectionRequests></ConnectionRequests>
                    }
                  ></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/settingsaccount"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2
                    child={
                      roleType === "business" ? (
                        <ConnectBusinessAccount componentState={userState} updateComponentState={updateUser}></ConnectBusinessAccount>
                      ) : (
                        <ConnectEmployeeAccount componentState={userState} updateComponentState={updateUser}></ConnectEmployeeAccount>
                      )
                    }
                  ></MainLayout2>
                </ValidateToken>
              }
            />
            {/* <Route
            path="/dashboard"
            element={
              <ValidateToken>
                <MainLayout child={<DashboardPageLayout />}></MainLayout>
              </ValidateToken>
            }
          /> */}
            <Route
              path="/businessprofile/admins"
              element={
                <ValidateToken>
                  <MainLayout child={<AdminsPageLayout />}></MainLayout>{" "}
                </ValidateToken>
              }
            />

            <Route
              path="/businessprofile/employees"
              element={
                <ValidateToken>
                  <MainLayout child={<EmployeesPageLayout />}></MainLayout>{" "}
                </ValidateToken>
              }
            />

            <Route
              path="/businessprofile/employees/:id"
              element={
                <ValidateToken>
                  <MainLayout child={<EmployeePageLayout />}></MainLayout>{" "}
                </ValidateToken>
              }
            />

            <Route
              path="/businessprofile/clients"
              element={
                <ValidateToken>
                  <MainLayout child={<DefaultPage />}></MainLayout>{" "}
                </ValidateToken>
              }
            />

            {/* Connect a cleaner business routes */}

            <Route
              path="/coverageareas"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2
                    child={
                      // <ErrorBoundary FallbackComponent={ErrorCoverageArea}>
                      roleType === "business" ? (
                        <ConnectBusinessCoverageArea updateEntityState={updateUser}></ConnectBusinessCoverageArea>
                      ) : (
                        <ConnectEmpCoverageArea
                          markersLimit={1}
                          allowsDeleteMarkers={false}
                          title={userState?.coverageAreas?.length > 0 ? "" : "Add the location radius where you offer your services"}
                          updateEntityState={updateUser}
                        ></ConnectEmpCoverageArea>
                      )
                      // </ErrorBoundary>
                    }
                  ></MainLayout2>
                </ValidateToken>
              }
            />

            <Route
              path="/notifications-center"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2 child={<NotificationsCenter></NotificationsCenter>}></MainLayout2>
                </ValidateToken>
              }
            />

            <Route
              path="/notifications-settings"
              element={
                <ValidateToken>
                  <MainLayout2 child={<NotificationSettings></NotificationSettings>}></MainLayout2>
                </ValidateToken>
              }
            />

            <Route
              path="/services"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2
                    child={
                      // <ErrorBoundary FallbackComponent={ErrorCoverageArea}>
                      roleType === "business" ? (
                        <ConnectBusinessServices
                          title={"Services offered by your company"}
                          residentialTitle="Residential Cleaning Services"
                          commercialTitle="Commercial Cleaning Services"
                        ></ConnectBusinessServices>
                      ) : (
                        <ConnectBusinessServices title={"Skills"} residentialTitle="Residential" commercialTitle="Commercial"></ConnectBusinessServices>
                      )
                    }
                  ></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/requirements"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2 child={<ConnectBusinessRequirementsBar></ConnectBusinessRequirementsBar>}></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/avalability"
              // element={<Navigate to="/signin" replace />}
              element={
                <ValidateToken>
                  <MainLayout2
                    child={roleType === "business" ? <HoursOfOperation updateComponentState={updateUser}></HoursOfOperation> : <ConnectBusinessAvalability></ConnectBusinessAvalability>}
                  ></MainLayout2>
                </ValidateToken>
              }
            />
            {<Route
              path="/plan-center"
              element={
                <ValidateToken>
                  <MainLayout2 child={<PlansLayout></PlansLayout>}></MainLayout2>
                </ValidateToken>
              }
            />}

            <Route
              path="/settings"
              element={
                <ValidateToken>
                  <MainLayout child={<DefaultPage />}></MainLayout>{" "}
                </ValidateToken>
              }
            />

            <Route
              path="/reports"
              element={
                <ValidateToken>
                  <MainLayout child={<DefaultPage />}></MainLayout>{" "}
                </ValidateToken>
              }
            />
            <Route
              path="/tickets"
              element={
                <ValidateToken>
                  <MainLayout child={<DefaultPage />}></MainLayout>{" "}
                </ValidateToken>
              }
            />
            <Route
              path="/connect"
              element={
                <ValidateToken>
                  <MainLayout child={<ConnectEmployeePage />}></MainLayout>
                </ValidateToken>
              }
            />
            <Route
              path="/calculator"
              element={
                <ValidateToken>
                  <MainLayout child={<CalculatorPage></CalculatorPage>}></MainLayout>
                </ValidateToken>
              }
            />

            {/* Super admin routes */}
            <Route
              path="/sales"
              element={
                <ValidateToken>
                  <MainLayout2 child={<NotificationSettings></NotificationSettings>}></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/total-registered-users"
              element={
                <ValidateToken>
                  <MainLayout2 child={<UserSummaryTable currPage="/total-registered-users" title={"Total Registered Users"}></UserSummaryTable>}></MainLayout2>
                </ValidateToken>
              }
            />

            <Route
              path="/total-registered-individuals"
              element={
                <ValidateToken>
                  <MainLayout2 child={<UserSummaryTable currPage="/total-registered-individuals" typeState="employee" title={"Total Registered Individuals"}></UserSummaryTable>}></MainLayout2>
                </ValidateToken>
              }
            />
            <Route
              path="/total-registered-business"
              element={
                <ValidateToken>
                  <MainLayout2 child={<UserSummaryTable currPage="/total-registered-business" typeState="business" title={"Total Registered Business"}></UserSummaryTable>}></MainLayout2>
                </ValidateToken>
              }
            />
            
            <Route // For test components
              path="/playground"
              element={
                <ValidateToken>
                  <MainLayout2 child={ <Playground/> } />
                </ValidateToken>
              }
            />

            <Route path="*" element={ <Navigate to="/"/> } />
            
          </Routes>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
