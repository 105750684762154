import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nextViewConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import ButtonConnectBusinessServices from "./buttonConnectBusinessServices";

export const ConnectBusinessTypeOfJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(nextViewConnectBusinessState());
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height:"auto",
          // height: "auto",
          width: { xs: "100%", md: 750 },
          // height: { xs: 500, md: 500 },
          marginTop:{ xs: 6, md: 2 }
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            // backgroundColor: "#233044",
            // color: "white",
            textAlign: "center",
            padding: "10px",
            borderRadius: "10px",
            wordWrap: "break-word",
            width: "100%",
            marginBottom: "20px",
            fontWeight: 'bold' // Esto hará que el texto sea en negrita
          }}
        >
         First lets confirm the type of job
        </Typography>

        <ButtonConnectBusinessServices updateComponentState = {nextViewConnectBusinessState}></ButtonConnectBusinessServices>

      </Box>
    </div>
  );
};

export default ConnectBusinessTypeOfJob;
