import { VerifiedUserOutlined } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { setSuperAdminSearchState } from "../redux/features/superAdminSearchStateSlice";
import { useDispatch } from "react-redux";

export const SuperAdminSearch = () => {
  const [userInput, setUserInput] = useState("");
  const dispatch = useDispatch();
  const handleInput = (event) => {
    setUserInput(event.target.value);
  };

  useEffect(() => {
    dispatch(
      setSuperAdminSearchState({
        fullName: userInput,
      }),
    );
  }, [userInput]);
  return (
    <Box sx={{ width: "100%", height: "100%", m: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <SearchIcon fontSize="large" sx={{ mr: 2 }}></SearchIcon>
      <TextField id="user-search" onChange={handleInput} label="Full Name"></TextField>
    </Box>
  );
};
