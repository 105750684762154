import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience, {
  CheckExperienceCommercial,
} from "./checkboxExperienceCommercial";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { gateway } from "../gateway";
import {
  nextViewConnectBusinessState,
  previousViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { NextButton } from "./nextButton";

export const ConnectBusinessCommercial = ({
  checkedState,
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateConnectBusinessState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currPage = "/signupEmployee";
  const commertialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);
  const buttonText = Object.values(checkedState).some(Boolean) ? "Next" : "Skip";


  const getWorkServiceByType = async (type) => {
    try {
      const response = await gateway.get(
        `/workservices?type=${commertialType}`
      );
      const data = response.data.data.items;

      setCommercialServices(data);
      console.log("the data: ", data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWorkServiceByType(commertialType);
  }, []);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: 2, md: 10 },
          width: { xs: "100%", md: 600 },
          marginLeft: { md: 10 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              What experience areas are you looking for?
            </Typography>
            <CheckExperienceCommercial
              checkedState={checkedState}
              data={commercialServices}
              updateComponentState={updateComponentState}
            />
          </Grid>
        </Grid>

        <NextButton
          // type="submit"
          sx={{
            marginTop: "20px",
          }}
          nextViewEntityState={nextViewConnectBusinessState}
          previousViewEntityState={previousViewConnectBusinessState}
          buttonText={buttonText} // Pasa el texto del botón calculado

        ></NextButton>
      </Box>
    </div>
  );
};
