import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
  Popover,
} from "@mui/material";
import WbSunnyOutlined from "@mui/icons-material/WbSunnyOutlined";
import NightsStayOutlined from "@mui/icons-material/NightsStayOutlined";
import { WbTwilight } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateSignupEmployeeState } from "../redux/features/signupEmployeeStateSlice";
import Tooltip from "@mui/material/Tooltip";

export const TimeSelector = ({
  updateComponentState = updateSignupEmployeeState,
  checkedState,
  setChanges = () => {},
}) => {
  const dispatch = useDispatch();
  const initialState = checkedState || {
    AM: { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false },
    Midday: { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false },
    PM: { mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false },
  };
  const [internalCheckedState, setCheckedState] = useState(initialState);

  useEffect(() => {
    if (checkedState) {
      setCheckedState(checkedState);
    }
  }, [checkedState]);

  useEffect(() => {
    dispatch(updateComponentState({ availability: internalCheckedState }));
  }, [internalCheckedState, dispatch, updateComponentState]);

  const handleCheckboxChange = (time, day) => (event) => {
    setChanges(true);
    setCheckedState((prevState) => ({
      ...prevState,
      [time]: { ...prevState[time], [day]: event.target.checked },
    }));
  };

  const isChecked = (time, day) => internalCheckedState[time] && internalCheckedState[time][day];

  // Estados y manejadores para cada Popover
  const [anchorElAM, setAnchorElAM] = useState(null);
  const [anchorElMidday, setAnchorElMidday] = useState(null);
  const [anchorElPM, setAnchorElPM] = useState(null);

  const handleOpenPopoverAM = (event) => setAnchorElAM(event.currentTarget);
  const handleClosePopoverAM = () => setAnchorElAM(null);

  const handleOpenPopoverMidday = (event) => setAnchorElMidday(event.currentTarget);
  const handleClosePopoverMidday = () => setAnchorElMidday(null);

  const handleOpenPopoverPM = (event) => setAnchorElPM(event.currentTarget);
  const handleClosePopoverPM = () => setAnchorElPM(null);

  const openAM = Boolean(anchorElAM);
  const idAM = openAM ? 'popover-am' : undefined;

  const openMidday = Boolean(anchorElMidday);
  const idMidday = openMidday ? 'popover-midday' : undefined;

  const openPM = Boolean(anchorElPM);
  const idPM = openPM ? 'popover-pm' : undefined;

  const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        padding: 2,
        borderRadius: "10px",
        backgroundColor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "opacity 1s ease-in-out",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {/* Days of the week */}
        <Grid item xs={3}>
          <Box sx={{ textAlign: "center", marginRight: 2, marginTop: "20%" }}>
            {days.map((day) => (
              <Typography key={day} sx={{ paddingY: "8.5px" }}>
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </Typography>
            ))}
          </Box>
        </Grid>
        {/* AM checkboxes */}
        <Grid item xs={3} container direction="column" alignItems="center">
        <Tooltip title="Schedule 7AM-2PM" enterDelay={300} leaveDelay={200}>
          <IconButton onClick={handleOpenPopoverAM}>
            <WbSunnyOutlined />
          </IconButton>
        </Tooltip>
          <Popover
            id={idAM}
            open={openAM}
            anchorEl={anchorElAM}
            onClose={handleClosePopoverAM}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
          >
            <Typography sx={{ p: 1 }}>Schedule 7AM-2PM</Typography>
          </Popover>
          {days.map((day) => (
            <FormControlLabel
              key={`am-${day}`}
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked("AM", day)}
                  onChange={handleCheckboxChange("AM", day)}
                />
              }
              label=""
              sx={{ margin: 0 }}
            />
          ))}
        </Grid>
        {/* Midday checkboxes */}
        <Grid item xs={3} container direction="column" alignItems="center">
        <Tooltip title="Schedule 3PM-8PM" enterDelay={300} leaveDelay={200}>
          <IconButton onClick={handleOpenPopoverMidday}>
            <WbTwilight />
          </IconButton>
        </Tooltip>
          <Popover
            id={idMidday}
            open={openMidday}
            anchorEl={anchorElMidday}
            onClose={handleClosePopoverMidday}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
          >
            <Typography sx={{ p: 1 }}>Schedule 3PM-8PM</Typography>
          </Popover>
          {days.map((day) => (
            <FormControlLabel
              key={`mid-${day}`}
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked("Midday", day)}
                  onChange={handleCheckboxChange("Midday", day)}
                />
              }
              label=""
              sx={{ margin: 0 }}
            />
          ))}
        </Grid>
        {/* PM checkboxes */}
        <Grid item xs={3} container direction="column" alignItems="center">
        <Tooltip title="Schedule 9PM-6AM" enterDelay={300} leaveDelay={200}>
          <IconButton onClick={handleOpenPopoverPM}>
            <NightsStayOutlined />
          </IconButton>
        </Tooltip>
          <Popover
            id={idPM}
            open={openPM}
            anchorEl={anchorElPM}
            onClose={handleClosePopoverPM}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
          >
            <Typography sx={{ p: 1 }}>Schedule 9PM-6AM</Typography>
          </Popover>
          {days.map((day) => (
            <FormControlLabel
              key={`pm-${day}`}
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked("PM", day)}
                  onChange={handleCheckboxChange("PM", day)}
                />
              }
              label=""
              sx={{ margin: 0 }}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
