import { Box, Toolbar } from "@mui/material";
import React from "react";
import { colorConfigs } from "../../config/colorConfigs";
import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../common/AppBar";
import { SidebarConnect } from "../common/SidebarConnect";
import { useSelector } from "react-redux";

export default function MainLayout2({ child }) {
  const userState = useSelector((state) => state.userState);
  const { type: roleType } = userState.role;

  return (
    <div className="App">
      {roleType === "employee" || roleType === "business" || roleType === "superadmin" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ResponsiveAppBar></ResponsiveAppBar>
          <SidebarConnect></SidebarConnect>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 1,
              width: "100%",
              minHeight: "100vh",
              backgroundColor: colorConfigs.mainBg,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: "3em",
            }}
          >
            <Toolbar></Toolbar>
            <Outlet></Outlet>
            {child}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </div>
  );
}
