import { redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import { gateway } from "../gateway";
import { useEffect, useState } from "react";
import { updateUser } from "../redux/features/userStateSlice";
import { useDispatch } from "react-redux";
import { decodeToken } from "../utils";
import { registerWithServer } from "../sockets";
import { LoaderComponent } from "../components/Loader";

export const ValidateToken = ({ children, checkByParam = false }) => {
  const { token: tokenParam } = useParams();
  const tokenLocal = localStorage.getItem("token");
  const accessToken = checkByParam ? tokenParam : tokenLocal;
  const [isValidToken, setIsValidToken] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const forbiddenPathsForTokenUser = ["reset", "join", "signin", "signup", "activation", "deactivation", "connectacleaner"];
  let { pathname } = useLocation();
  const [basePath, indexPath, tokenReqParam] = pathname.split("/");
  console.log("indexPath: ", indexPath);
  const navigate = useNavigate();

  const validateTokenLocal = async (tokenLocal) => {
    setOpen(true);
    try {
      const response = await gateway.get(`/tokens/validate`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      if (response.data?.status === 200) {
        console.log("Token was correct redirecting you to home");
        navigate("/");
        return;
      }
      setOpen(false);
    } catch (error) {
      localStorage.removeItem("token");
      dispatch(updateUser({}));
    }
  };

  const validateToken = async (token) => {
    setOpen(true);
    console.log("input token: ", token);
    try {
      // if (tokenLocal?.length > 0) {
      //   console.log("Se encontro tokenLocal, validando si es corecto...");
      //   await validateTokenLocal(tokenLocal);
      // }
      const response = await gateway.get(`/tokens/validate${checkByParam ? "?usagelimit=true" : ""}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      //Works fine with others integrations:
      // if (tokenLocal?.length > 0) {
      //   console.log("Se encontro tokenLocal, validando si es corecto...");
      //   await validateTokenLocal(tokenLocal);
      // }
      // const response = await gateway.get(
      //   `/tokens/validate${checkByParam ? "?usagelimit=true" : ""}`,
      //   {
      //     headers: {
      //       Authorization: `${token}`,
      //     },
      //   }
      // );

      console.log(response.data);

      // Redirect to home if token and user tried to enter signin, signup, reset
      if (!forbiddenPathsForTokenUser.includes(indexPath) && response.data?.status === 200) {
        console.log("Redirecting to home");
        if (!checkByParam) {
          const userInfo = decodeToken(token);
          if (userInfo) {
            dispatch(
              updateUser({
                uid: userInfo?.uid,
                avatar: userInfo?.avatar,
                address: userInfo?.address,
                email: userInfo?.email,
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                birthDate: userInfo?.birthDate,
                country: userInfo?.country,
                state: userInfo?.state,
                city: userInfo?.city,
                zipCode: userInfo?.zipCode,
                company: {
                  businessName: userInfo?.companies?.[0]?.businessName,
                  businessPhone: userInfo?.companies?.[0]?.businessPhone,
                  dba: userInfo?.companies?.[0]?.dba,
                  ssn: userInfo?.companies?.[0]?.ssn,
                  country: userInfo?.companies?.[0]?.country,
                  state: userInfo?.companies?.[0]?.state,
                  address: userInfo?.companies?.[0]?.address,
                  zipCode: userInfo?.companies?.[0]?.zipCode,
                  coverageCountry: userInfo?.companies?.[0]?.coverageCountry,
                  coverageState: userInfo?.companies?.[0]?.coverageState,
                  coverageNationWide: userInfo?.companies?.[0]?.coverageNationWide,
                },
                coverageArea: userInfo?.coverageArea
                  ? {
                      location: userInfo?.coverageArea?.location,
                      state: userInfo?.coverageArea?.state,
                      city: userInfo?.coverageArea?.city,
                      address: userInfo?.coverageArea?.addres,
                      milesOfCoverage: userInfo?.coverageArea?.milesOfCoverage,
                      latitude: userInfo?.coverageArea?.latitude,
                      longitude: userInfo?.coverageArea?.longitude,
                    }
                  : null,
                role: {
                  type: userInfo?.roles?.[0]?.type,
                  name: userInfo?.roles?.[0]?.name,
                },
                subscription: userInfo?.subscription?.id
                  ? {
                      id: userInfo?.subscription?.id,
                      paypalSubscriptionId: userInfo?.subscription?.paypalId,
                      planName: userInfo?.subscription?.plan?.product?.name,
                      planId: userInfo?.subscription?.plan?.id,
                      planPaypalId: userInfo?.subscription?.plan?.paypalPlanId,
                      hexColor: userInfo?.subscription?.plan?.product?.hexColor,
                      createdAt: userInfo?.subscription?.createdAt,
                    }
                  : null,

                benefits: userInfo?.benefits ?? [],
              }),
            );
            registerWithServer(userInfo.uid);
          }
        }
        setOpen(false);
        return navigate("/");
      }
    } catch (error) {
      console.log("Error en la solicitud", error);
      if (tokenLocal?.length > 0) {
      }
      console.log(error.response.data);
      // if (forbiddenPathsForTokenUser.includes(indexPath)) return;
      navigate("/signin");
    }
  };

  useEffect(() => {
    setOpen(true);
    if (accessToken) {
      validateToken(accessToken).then(() => {
        setIsValidToken(true);
      });
    } else {
      if (!forbiddenPathsForTokenUser.includes(indexPath)) {
        navigate("/signin");
        setOpen(false);
      }
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    if(!accessToken && !forbiddenPathsForTokenUser.includes(indexPath)){
      navigate("/signin");
    }
  })

  return (
    <>
      {isValidToken ? (
        children
      ) : !accessToken && forbiddenPathsForTokenUser.includes(indexPath) ? (
        children
      ) : (

        <LoaderComponent open={open} type="spinner"></LoaderComponent>
      )}
       <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </>
  );
};
