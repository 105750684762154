import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { colorConfigs } from "../../config/colorConfigs";
import { useSelector } from "react-redux";

export default function SidebarItem({ item }) {
  const { appState } = useSelector((state) => state.appState);
  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        width: "100%",
        "&: hover": { backgroundColor: colorConfigs.sidebar.hoverBg },
        backgroundColor:
          appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
        paddingY: "12px",
        paddingX: "30px",
      }}
    >
      <ListItemIcon sx={{ color: colorConfigs.sidebar.color }}>
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      <ListItemText>{item.sidebarProps.displayText}</ListItemText>
    </ListItemButton>
  ) : null;
}
