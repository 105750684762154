import React from "react";
import { Paper, Box, Typography, Avatar } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { assets } from "../assets";
import { useSelector } from "react-redux";
import { MapComponent } from "./coverageAreaMap";
import { ConnectEmpCoverageArea } from "./connectEmpCoverageArea";
import { updateUser } from "../redux/features/userStateSlice";

const formatAddress = ({ city, state, zipCode, country }) => {
  // Filtra los elementos que no son falsy (null, undefined, "", etc.)
  const addressParts = [city, state, zipCode, country].filter(Boolean);
  // Une las partes con comas y espacio.
  return addressParts.join(", ");
};

export function CoverageDashboard() {
  const userState = useSelector((state) => state.userState);
  const { city, state, country, zipCode } = userState.coverageArea;
  const fullAddress = formatAddress({ city, state, zipCode, country });

  return (
    <Paper
      sx={{
        width: "80%",
        // mx: "auto",
        p: 2,
        mt: 2,
        // height: "70vh",
        marginBottom: "5%",
      }}
    >
      <Paper
        component="div"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          borderRadius: "4px",
          bgcolor: "#1d4999", // color de fondo azul
          color: "text.secondary", // color del texto e íconos secundarios
          m: "0px",
        }}
      >
        <Avatar sx={{ bgcolor: "#F8F8F8", mr: 1 }}>
          <LocationOnIcon sx={{ color: "red" }} />
        </Avatar>
        <Typography
          sx={{ ml: 1, flex: 1, color: "white", mb: "0px" }}
          color="initial"
        >
          {fullAddress}
        </Typography>
      </Paper>
      <Box sx={{ width: "100%", height: "100%", m: "0px" }}>
        <ConnectEmpCoverageArea
          mapContainerStyle={{
            width: "100%", // Asegúrate de que 'md' tenga el estilo correcto
            height: "300px",
            borderRadius: "5px",
          }}
          markersLimit={1}
          zoom={7}
          isInformative={true}
          allowsDeleteMarkers={false}
          title={
            userState?.coverageAreas?.length > 0
              ? ""
              : "Add the location radius where you offer your services"
          }
          updateEntityState={updateUser}
        ></ConnectEmpCoverageArea>
      </Box>
    </Paper>
  );
}
