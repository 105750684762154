import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add"; // Nuevo icono
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const blobUrlToFile = async (blobUrl, filename = "image") => {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], `${filename}.${blob.type.split("/")[1]}`, {
      type: blob.type,
    });
  } catch (error) {
    console.error("Error al convertir URL de Blob a File:", error);
    return null;
  }
};

export default function IconButtonFileUpload({
  updateComponentState,
  setChanges = () => {},
}) {
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const handleFileChange = async (event) => {
    setChanges(true);
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      console.log("the imageUrl: ", previewUrl);
      console.log("the file: ", file);
      dispatch(updateComponentState({ imageUrl: previewUrl }));
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("avatar", file);

    try {
      setUploading(true);
      setUploadStatus("Subiendo...");
      const response = await fetch("/api/upload-avatar", {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Error al subir imagen");
      }
      const data = await response.json();
      setUploadStatus("Subida exitosa");
      console.log(data);
    } catch (error) {
      console.error("Error al subir:", error);
      setUploadStatus("Error en la subida");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          disabled={uploading}
        >
          {uploading ? <CircularProgress /> : <EditIcon />}
        </IconButton>
        <VisuallyHiddenInput
          accept="image/*"
          id="icon-button-file"
          type="file"
          onChange={handleFileChange}
        />
      </label>
      <Typography
        sx={{
          fontSize: {
            xs: "0.8em",
            md: "1em",
          },
        }}
        variant="h3"
        color="initial"
      >
        {uploadStatus}
      </Typography>
    </Box>
  );
}
