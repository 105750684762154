import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { PlanCheckoutSuccess } from "../PlanCheckoutSuccess";
import { PlanCheckoutTicket } from "../PlanCheckoutTicket";
import { PlanCheckoutError } from "../PlanCheckoutError";
import { PlanCheckout } from "../PlanCheckout";
import "../../assets/styles/plans.css"

const planCheckoutComponentMap = {
  success: PlanCheckoutSuccess,
  error: PlanCheckoutError,
};

export const PlanCheckoutLayout = () => {
  const { planSelected } = useSelector((state) => state.planState);
  const [subscriptionState, setSubscriptionState] = useState({ state: "none", data: { message: "", subscriptionId: null, orderId: null } });
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState(false);
  // Get Component Map Factory
  const PlanCheckoutComponent = planCheckoutComponentMap[subscriptionState?.state] || PlanCheckout;

  useEffect(() => {
    setIsCheckoutSuccess(subscriptionState?.state === "success");
  }, [subscriptionState]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        animation: "fadeIn 1.5s",
        "@keyframes fadeIn": {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        transition: "transform 0.3s ease-in-out",
        "&:hover": !planSelected
          ? {
              transform: "scale(1.025)",
            }
          : undefined,
      }}
      className="planLayout"
    >
      <Grid container sx={{ width: "100%", height: "100%" }} className={subscriptionState.state !== "none" ? "successCheckout" : "checkout"}>
        <Grid item sx={{ transition: "transform 0.5s ease-in-out", transform: isCheckoutSuccess ? "translateX(100%)" : "translateX(0%)" }} className= "column xs-11 sm-6">
          <PlanCheckoutComponent subscriptionState={subscriptionState} setSubscriptionState={setSubscriptionState} />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", transition: "transform 0.5s ease-in-out", transform: isCheckoutSuccess ? "translateX(-100%)" : "translateX(0%)" }}
          className="column xs-11 md-6"
        >
          
          <PlanCheckoutTicket
            planSelected={planSelected}
            title={
              isCheckoutSuccess ? (
                <>
                  Subscription ID: <br /> {subscriptionState?.data?.subscriptionId}
                </>
              ) : (
                "Summary"
              )
            }
            subscriptionState={subscriptionState}
          ></PlanCheckoutTicket>
        </Grid>
      </Grid>
    </Box>
  );
};
