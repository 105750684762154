import logoImage from "./images/house_cleaning.png";
import chartCircleImage from "./images/chart.png";
import chartBarImage from "./images/chartBar.png";
import carpet from "./images/carpet.png";
import deepCleaning from "./images/deepCleaning.png";
import floorBuffing from "./images/floorBuffing.png";
import floorPolishing from "./images/floorPolishing.png";
import floorStripping from "./images/floorStripping.png";
import houseCleaning from "./images/houseCleaning.png";
import janitorial from "./images/janitorial.png";
import moveOut from "./images/moveOut.png";
import officeCleaning from "./images/officeCleaning.png";
import preasureWashing from "./images/pressureWashing.png";
import window from "./images/window.png";
import add from "./images/add.png";
import english from "./images/english.png";
import dl from "./images/dl.png";
import experience from "./images/experience.png";
import experienceClean from "./images/experienceClean.png";
import employee from "./images/employee.png";
import subcontractror from "./images/subcontractor.png";
import bicy from "./images/bicy.jpg";
import bus from "./images/bus.jpg";
import moto from "./images/moto.jpg";
import car from "./images/car.png";
import corporation from "./images/corporation.jpg";
import sole from "./images/sole.png";
import partner from "./images/partner.jpg";
import f1099 from "./images/f1099.png";
import w2 from "./images/w2.png";
import formulario from "./images/formulario.png";
import maps from "./images/maps.png";
import star from "./images/star.png";
import individual from "./images/individual.jpg";
import bussines from "./images/bussines.jpg";
import sensorsImage from "./images/sensors.png";
import logo from "./images/logo.png";
import residential from "./images/residential.png";
import commercial from "./images/commercial.jpg";
import both from "./images/both.jpg";
import connectacleanerlogoPng from "./images/connectacleaner.png";
import connectacleanerbannerImg from "./images/connectacleanerbanner.jpg";
import serverbrokenImg from "./images/serverbroken.png";
import locationNotFoundImg from "./images/locationNotFound.png";
import residentialImg from "./images/residentialIcon.png";
import commercialImg from "./images/commercialIcon.png";
import availabilityImg from "./images/availabilityIcon.png";
import mark from "./images/mark.png";
import markArea from "./images/markArea.png";
import commercialService from "./images/commercialService.png";
import residentialService from "./images/residentialService.png";
import connectacleanerbadgeImg from "./images/quickbadge.png";
import sucessSubsImg from "./images/successSubs.png";
import errorSubsImg from "./images/errorSubs.png";
import bussinesAdminIconImg from "./images/bussinesAdminIcon.png";
import salesAdminIconImg from "./images/salesAdminIcon.png";
import personsAdminIconImg from "./images/personsAdminIcon.png";
import sendContactInfo from "./images/sendContactInfo.jpg"

export const assets = {
  images: {
    // logo: logoImage,
    chartCircle: chartCircleImage,
    chartBar: chartBarImage,
    carpet: carpet,
    deepCleaning: deepCleaning,
    floorBuffing: floorBuffing,
    floorPolishing: floorPolishing,
    floorStripping: floorStripping,
    houseCleaning: houseCleaning,
    janitorial: janitorial,
    moveOut: moveOut,
    officeCleaning: officeCleaning,
    preasureWashing: preasureWashing,
    window: window,
    add: add,
    englis: english,
    dl: dl,
    car: car,
    experience: experience,
    experienceClean: experienceClean,
    employee: employee,
    subcontractror: subcontractror,
    bicy: bicy,
    bus: bus,
    moto: moto,
    corporation: corporation,
    sole: sole,
    partner: partner,
    f1099: f1099,
    w2: w2,
    maps: maps,
    formulario: formulario,
    star: star,
    sensors: sensorsImage,
    individual: individual,
    bussiness: bussines,
    logo: logo,
    residential: residential,
    commercial: commercial,
    both: both,
    connectacleanericon: connectacleanerlogoPng,
    connectacleanerbanner: connectacleanerbannerImg,
    serverbroken: serverbrokenImg,
    locationNotFound: locationNotFoundImg,
    residentialIcon: residentialImg,
    commercialIcon: commercialImg,
    availavilityIcon: availabilityImg,
    mark: mark,
    markArea: markArea,
    commercialService: commercialService,
    residentialService: residentialService,
    connectacleanerbadge: connectacleanerbadgeImg,
    sucessSubs: sucessSubsImg,
    errorSubs: errorSubsImg,
    bussinesAdminIcon: bussinesAdminIconImg,
    salesAdminIcon: salesAdminIconImg,
    personsAdminIcon: personsAdminIconImg,
    sendContactInfo: sendContactInfo,
  },
};
