import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience from "./checkboxExperience";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { gateway } from "../gateway";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectEmployeeSeven = ({
  checkedState,
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateSignupEmployeeState,
}) => {
  const currPage = "/signupEmployee";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const residencialType = "residencial"; //The type that will be put on the
  const [residencialServices, setResidencialServices] = useState([]);

  const commercialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);

  const getWorkServiceByType = async (type, setStateService) => {
    try {
      const response = await gateway.get(`/workservices?type=${type}`);
      const data = response.data.data.items;
      setStateService(data);
      console.log("the data: ", data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getWorkServiceByType(residencialType, setResidencialServices);
    // getWorkServiceByType(commercialType, setCommercialServices);
  }, []);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: 2, md: 1 },
          width: { xs: "100%", md: 600 },
          marginLeft: { md: -15 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              Areas of experience
            </Typography>
            <CheckExperience
              checkedState={checkedState}
              data={residencialServices}
              updateComponentState={updateComponentState}
            />
            {/* <CheckExperience data={commercialServices} /> */}
          </Grid>
        </Grid>

        <SignUpNextButton
          // type="submit"
          sx={{
            marginTop: "10px",
          }}
          nextViewEntityState={nextViewComponentState}
          previousViewEntityState={previousViewEntityState}
        ></SignUpNextButton>

        {/* <Button
          style={{
            marginTop: "20px",
            borderRadius: "10px",
            height: "40px",
            width: "50%", // Utiliza porcentajes en lugar de valores fijos
            backgroundColor: "#233044",
            color: "white",
            borderColor: "#224f91",
          }}
          variant="contained"
          disableElevation
          onClick={handleSubmit}
        >
          Next
        </Button> */}
      </Box>
      {/* </Box> */}
    </div>
  );
};
