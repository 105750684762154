import React, { useEffect, useState } from "react";
import { Map } from "./maps";
import {
  IconButton,
  Container,
  Box,
} from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Loader } from "./common/Loader";
import { SmallHeaderMenu } from "./smallHeaderMenu";
import { LoaderComponent } from "./Loader";

const containerStyle = {
  width: "90%",
  height: "80%",
};

export const FindMyDevice = ({ uid }) => {
  useEffect(() => {}, []);
  const [open, setOpen] = useState(false);

  const findMyDeviceCb = () => {
    console.log("The uid to find: ", uid);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <>
      <Box
        maxWidth="xs"
        sx={{
          ...containerStyle,
          display: "flex",
          flexDirection: "column",
          padding: 0,
          margin: 0,
        }}
      >
        <SmallHeaderMenu>
          <IconButton
            aria-label="delete"
            size="large"
            sx={{
              width: "50px",
              height: "50px",
            }}
            onClick={findMyDeviceCb}
          >
            <TravelExploreIcon></TravelExploreIcon>
          </IconButton>
        </SmallHeaderMenu>
        <Map></Map>
      </Box>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </>
  );
};
