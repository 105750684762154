import React, { useEffect, useState, useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { gateway } from "../gateway";
import { useDispatch, useSelector } from "react-redux";
import { createConnectSubscription } from "../utils";
import { useErrorBoundary } from "react-error-boundary";
import { updateUser } from "../redux/features/userStateSlice";
import { Box, Button } from "@mui/material";
import { LoaderComponent } from "./Loader";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

export const PlanPaymentFree = ({ setSubscriptionState = () => {} }) => {
  const planState = useSelector((state) => state?.planState);
  const planSelected = planState?.planSelected ?? null;
  const [loading, setLoading] = useState(false);

  const [currentPlanId, setCurrentPlanId] = useState(planSelected?.id);
  const { showBoundary } = useErrorBoundary();

  const tokenLocal = localStorage.getItem("token");

  const [message, setMessage] = useState("");

  useEffect(() => {
    setCurrentPlanId(planState.planSelected.id);
  }, [planState]);

  const handleCreateConnectSubscription = useCallback(
    async (currentSubscriptionId) => {
      try {
        setLoading(true);
        console.log(planState);
        const connectSubs = await createConnectSubscription(
          currentSubscriptionId, //this is provided by paypalweb
          currentPlanId,
          planState?.planSelected?.price?.amount,
          planState?.planSelected?.discount ?? 0,
          Number(planState?.planSelected?.price?.amount) - Number(planState?.planSelected?.discount ?? 0),
          tokenLocal,
        );
        return connectSubs;
      } catch (error) {
        showBoundary(error);
      } finally {
        setLoading(false);
      }
    },
    [planState],
  );

  const subscribe = async () => {
    try {
      const subsinfo = await handleCreateConnectSubscription("free_trial");
      setSubscriptionState({
        state: "success",
        data: {
          message: `You have successfully subscribed to the plan.`,
          subscriptionId: "free_trial_01",
          orderId: "free_trial_01",
          subscription: subsinfo?.subscription?.id
            ? {
                id: subsinfo?.subscription?.id,
                paypalSubscriptionId: subsinfo?.subscription?.paypalId,
                planName: subsinfo?.subscription?.plan?.product?.name,
                planId: subsinfo?.subscription?.plan?.id,
                planPaypalId: subsinfo?.subscription?.plan?.paypalPlanId,
                hexColor: subsinfo?.subscription?.plan?.product?.hexColor,
                createdAt: subsinfo?.subscription?.createdAt,
              }
            : null,

          benefits: subsinfo?.benefits ?? [],
        },
      });
    } catch (error) {
      setSubscriptionState({
        state: "error",
        data: {
          message: `Failed to activate the subscription.`,
          subscriptionId: "free_trial_01",
          orderId: "free_trial_01",
        },
      });
    }
  };

  return (
    <div className="PlanPaymentFree">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1976d2",
            marginTop: "auto",
            marginBottom: "30px"
          }}
          //TODO: Plan Selected
          onClick={() => {
            subscribe();
          }}
        >
          CHOOSE PLAN
        </Button>
      </Box>
      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </div>
  );
};
