import * as React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCountry } from "../redux/features/countryStateSlice";
import { useState } from "react";

const countries = [
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
  { code: "GB", name: "United Kingdom" },
  { code: "AU", name: "Australia" },
  { code: "DE", name: "Germany" },
  { code: "FR", name: "France" },
  { code: "IT", name: "Italy" },
  { code: "ES", name: "Spain" },
  { code: "JP", name: "Japan" },
  { code: "CN", name: "China" },
  { code: "IN", name: "India" },
  { code: "BR", name: "Brazil" },
  { code: "MX", name: "Mexico" },
  { code: "AR", name: "Argentina" },
  { code: "CL", name: "Chile" },
  { code: "ZA", name: "South Africa" },
  // Agrega más países según sea necesario
];

export function CountryPicker({ name }) {
  const [selectedCountry, setSelectedCountry] = useState("");
  const dispatch = useDispatch();

  const onChange = (e) => {
    const { value } = e.target;

    setSelectedCountry(value); // Almacena el objeto país completo
    dispatch(updateCountry({ [`${name ?? "default"}`]: value.name })); // Despacha el nombre del país
  };

  return (
    <FormControl sx={{ width: "100%", mt: 1 }}>
      <InputLabel size="small">Country</InputLabel>
      <Select
        value={selectedCountry || ""}
        onChange={(e) => onChange(e)}
        size="small"
        label="Country"
        required
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
