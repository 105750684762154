import { Box, Toolbar } from "@mui/material";
import React from "react";
import Topbar from "../common/Topbar";
import { sizeConfigs } from "../../config/sizeConfig";
import Sidebar from "../common/Sidebar";
import { colorConfigs } from "../../config/colorConfigs";
import { Outlet } from "react-router-dom";

export default function MainLayout({ child }) {
  return (
    <div className="App">
      <Box sx={{ display: "flex" }}>
        <Topbar></Topbar>
        <Box
          component="nav"
          sx={{ width: sizeConfigs.sidebar.width, flexShrink: 0 }}
        >
          <Sidebar></Sidebar>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            width: `calc(100% -${sizeConfigs.sidebar.width})`,
            minHeight: "90vh",
            backgroundColor: colorConfigs.mainBg,
          }}
        >
          <Toolbar></Toolbar>
          <Outlet></Outlet>
          {<div>{child}</div>}
        </Box>
      </Box>
    </div>
  );
}
