import React from "react";
import { Box, CssBaseline, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, Checkbox } from "@mui/material";
import { LoaderComponent } from "../Loader";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Switch from "@mui/material/Switch";
import { SaveButton } from "../saveButton";
import { useErrorBoundary } from "react-error-boundary";
import { getMyNotificationsSettings, updateMyNotificationsSettings } from "../../utils";

export const NotificationSettings = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = React.useState();
  const [notifications, setNotifications] = React.useState(true);
  const [changes, setChanges] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const { showBoundary } = useErrorBoundary();

  const [allowSounds, setAllowSounds] = React.useState(false);
  const [lockScreen, setLockScreen] = React.useState(false);
  const [remindersAndIncomingCalls, setRemindersAndIncomingCalls] = React.useState(false);

  const handleNotificationSettingInfo = async () => {
    try {
      setLoading(true);
      const notificationSetting = await getMyNotificationsSettings(token);
      setNotifications(notificationSetting?.isActive);
      setAllowSounds(notificationSetting?.allowSounds);
      setLockScreen(notificationSetting?.lockScreen);
      setRemindersAndIncomingCalls(notificationSetting?.remindersAndIncomingCalls);
    } catch (error) {
      console.log(error);
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationSettingSave = async () => {
    try {
      setLoading(true);
      const notificationSetting = await updateMyNotificationsSettings({ allowSounds, isActive: notifications, lockScreen, remindersAndIncomingCalls }, token);
      setChanges(false);
    } catch (error) {
      console.log(error);
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (cancel) {
      handleNotificationSettingInfo();
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  React.useEffect(() => {
    handleNotificationSettingInfo();
  }, []);

  const handleChangeNotifications = (event) => {
    setNotifications(event.target.checked);
    if (event.target.checked === false) {
      setAllowSounds(false);
      setLockScreen(false);
      setRemindersAndIncomingCalls(false);
    }
    setChanges(true);
  };

  const handleChangeAllowSounds = (event) => {
    setAllowSounds(event.target.checked);
    setChanges(true);
  };
  const handleChangeLockScreen = (event) => {
    setLockScreen(event.target.checked);
    setChanges(true);
  };
  const handleChangeRemindersAndIncomingCalls = (event) => {
    setRemindersAndIncomingCalls(event.target.checked);
    setChanges(true);
  };

  return (
    <>
      <CssBaseline></CssBaseline>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          minHeight: "100%",
          mt: "5em",
          flexDirection: "column",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Typography variant="h5" sx={{ color: "var(--glaucous-hex)", mb: 4 }}>
            Notifications Settings
          </Typography>

          <Box sx={{ position: "absolute", top: 3, right: -40 }}>
            <SettingsIcon sx={{ color: "var(--glaucous-hex)" }} />
          </Box>
        </Box>

        <List sx={{ width: "70%", height: "auto", borderColor: "black" }}>
          <ListItem key="header" sx={{ backgroundColor: "#f3f3f3" }}>
            <ListItemIcon>
              <NotificationsNoneIcon></NotificationsNoneIcon>{" "}
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight="bold">Notifications</Typography>
              <Typography>Get notifications from apps and other senders</Typography>
            </ListItemText>
            <Typography> {notifications ? "On" : "Off"}</Typography>
            <Switch checked={notifications} onChange={handleChangeNotifications} inputProps={{ "aria-label": "controlled" }} />
          </ListItem>
          <Divider sx={{ color: "black", backgroundColor: "black" }}></Divider>

          <ListItem sx={{ backgroundColor: "#ffffff", pl: "10%" }}>
            <Checkbox checked={allowSounds} onChange={handleChangeAllowSounds} inputProps={{ "aria-label": "controlled" }} />
            <ListItemText>
              <Typography>Allow notifications to play sounds</Typography>
            </ListItemText>
          </ListItem>
          <Divider sx={{ color: "black", backgroundColor: "black", opacity: 0.1 }}></Divider>
          <ListItem sx={{ backgroundColor: "#ffffff", pl: "10%" }}>
            <Checkbox checked={lockScreen} onChange={handleChangeLockScreen} inputProps={{ "aria-label": "controlled" }} />
            <ListItemText>
              <Typography>Show notifications on the lock screen</Typography>
            </ListItemText>
          </ListItem>
          <Divider sx={{ color: "black", backgroundColor: "black", opacity: 0.1 }}></Divider>
          <ListItem sx={{ backgroundColor: "#ffffff", pl: "10%" }}>
            <Checkbox checked={remindersAndIncomingCalls} onChange={handleChangeRemindersAndIncomingCalls} inputProps={{ "aria-label": "controlled" }} />
            <ListItemText>
              <Typography>Show reminders and incoming VoIP calls on the lock screen</Typography>
            </ListItemText>
          </ListItem>
          <Divider sx={{ color: "black", backgroundColor: "black", opacity: 0.1 }}></Divider>
        </List>
        <SaveButton
          sx={{ mt: "10px" }}
          onSubmit={() => {
            handleNotificationSettingSave();
          }}
          disabled={!changes}
          setCancel={setCancel}
        >
          {" "}
        </SaveButton>
      </Box>
      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </>
  );
};
