import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  country: "",
  businessName: "",
  businessPhone: "",
  dba: "",
  ssn: "",
  companyCountry: "",
  companyState: "",
  companyAddress: "",
  companyZipCode: "",
  companyCoverageCountry: "",
  companyCoverageState: "",
  companyCoverageNationWide: "",
  signupGeneralInfo: false,
  state: "generalInfo",
};

export const formStateSlice = createSlice({
  name: "formStateSlice",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetForm: () => {
      return initialState;
    },
  },
});

export const { updateForm, resetForm } = formStateSlice.actions;
export default formStateSlice.reducer;
