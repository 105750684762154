import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { assets } from "../assets";

export const ConnectEmployeeTwo = () => {
  // Estado para mantener los servicios seleccionados
  const [selectedServices, setSelectedServices] = useState([]);

  // Función para manejar la selección/deselección de servicios
  const toggleService = (service) => {
    const serviceObj = JSON.parse(service);
    const isAlreadySelected = selectedServices.some(s => s.id === serviceObj.id);

    if (isAlreadySelected) {
      setSelectedServices(selectedServices.filter(s => s.id !== serviceObj.id));
    } else {
      setSelectedServices([...selectedServices, serviceObj]);
    }
  };

  // useEffect para consolar cada cambio en selectedServices
  useEffect(() => {
    console.log("Servicios seleccionados: ", selectedServices);
  }, [selectedServices]);

  const isServiceSelected = (serviceName) => {
    return selectedServices.some(s => s.name === serviceName);
  };

  const handleSubmit = async (event) => {
    console.log(selectedServices); // Aquí tienes la lista de servicios seleccionados
    // Código para manejar el submit
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
        sx={{
          maxWidth:{ xs:"100%", md:"50%"},
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginTop: "5px",
            backgroundColor: "#233044",
            color: "#FFFFFF",
            fontWeight: "bold",
            padding: "10px",
            borderRadius: "10px",
            textAlign: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          Choose the necessary services
        </Typography>

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Ejemplos de botones para diferentes servicios */}
          {[
            { name: 'windowCleaning', id: 1, image: assets.images.window },
            { name: 'carpetCleaning', id: 2, image: assets.images.carpet },
            { name: 'janitorial', id: 3, image: assets.images.janitorial },
            { name: 'floorBuffing', id: 4, image: assets.images.floorBuffing },
            { name: 'floorPolishing', id: 5, image: assets.images.floorPolishing },
            { name: 'floorStripping', id: 6, image: assets.images.floorStripping },
            { name: 'preasureWashing', id: 7, image: assets.images.preasureWashing },
            { name: 'moveOut/In', id: 8, image: assets.images.moveOut },
            { name: 'officeCleaning', id: 9, image: assets.images.officeCleaning }
          ].map(service => (
            <Grid item xs={6} sm={6} md={4} lg={3} display={"flex"} flexDirection={"column"} key={service.id}>
              <Button
                variant="contained"
                onClick={() => toggleService(JSON.stringify({ name: service.name, id: service.id }))}
                sx={{
                  marginLeft: "10px",
                  width: "95%",
                  height: "137px",
                  margin: "0 auto",
                  backgroundColor: isServiceSelected(service.name) ? '#233044' : '#EAEBEB',
                  '&:hover': {
                    backgroundColor: isServiceSelected(service.name) ? '#1A2A36' : '#D3D3D3',
                  },
                }}
              >
                <img
                  src={service.image}
                  alt={service.name}
                  style={{ width: "100%", height: "100%", borderRadius: "10px" }}
                />
              </Button>
              <Typography
                textAlign={"center"}
                sx={{ fontWeight: "bold", color: "#233044" }}
              >
                {service.name.replace(/([A-Z])/g, ' $1').trim()}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            style={{
              marginTop: "20px",
              borderRadius: "10px",
              height: "40px",
              backgroundColor: "#233044",
              color: "white",
              borderColor: "#224f91",
            }}
            variant="contained"
            disableElevation
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Box>
    </div>
  );
};
