import React from "react";
import { Box, Typography, Avatar, Stack, Grid, Button } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetConnectBusinessState } from "../redux/features/connectBusinessStateSlice";
import { gateway } from "../gateway";
import { TravelingCard } from "./TravelingCard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { ArrowForward, Close, Email, Phone, Place, Work } from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";
import { EBenefitTypes, generateRandomPhoneNumber } from "../utils";
import LockIcon from "@mui/icons-material/Lock";
import { appStates, setAppState } from "../redux/features/appStateSlice";
import { BaseDialog } from "./common/BaseDialog";
import { LoaderComponent } from "./Loader";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

const styles = {
  titleSx: {
    fontWeight: "bold",
  },
  titleConnectionSx: {
    textAlign: "center",
    color: "blue",
    fontSize: "1.2em",
  },
  actionSx: {
    justifyContent: "center",
  },
  btnCloseSx: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
  btnAcceptSx: {
    backgroundColor: "#3c7cde",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
};

export const EmployeeBasicCard = ({ uid, avatar, firstName, lastName, country, state, city, distances, children, isInOwnedCoverage = false }) => {
  const userState = useSelector((state) => state.userState);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openInsufficientBenefits, setOpenInsufficientBenefits] = React.useState(false);
  const [openNotInYourCoverages, setOpenNotInYourCoverages] = React.useState(false);

  const benefitType = EBenefitTypes.FINDACLEANER;

  const usersBenefits =
    userState?.benefits?.find((benefit) => {
      if (benefit?.type === benefitType) return true;
      return false;
    }) ?? null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleOpenInsufficientBenefitsDialog = () => {
    setOpenInsufficientBenefits(true);
  };

  const handleOpenNotInYourCoveragesDialog = () => {
    setOpenNotInYourCoverages(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleAcceptConfirmDialog = async () => {
    setOpenConfirmDialog(false);
    // Aquí la lógica de conexión, seguida de abrir el diálogo de conexión exitosa
    try {
      setLoading(true);
      await handleConnect();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setOpenDialog(true);
    }
  };

  const handleOpenDialogFactory = () => {
    //TODO: IMPORTANT: Discoment this to use benefits limit feat
    if (!isInOwnedCoverage) {
      return handleOpenNotInYourCoveragesDialog;
    } else if (usersBenefits?.UserBenefits?.usageCount < usersBenefits?.config?.limitedUsage) {
      return handleOpenConfirmDialog;
    } else {
      return handleOpenInsufficientBenefitsDialog;
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(resetConnectBusinessState());
    navigate("/");
  };
  const token = localStorage.getItem("token");
  const connectBusinessState = useSelector((state) => state.connectBusinessState);
  const connectBusinessService = connectBusinessState.serviceType === "residential" ? connectBusinessState.residentialServices : connectBusinessState.commercialServices;
  const connectBusinessRequirements = connectBusinessState.requirements;

  const serviceIds = connectBusinessService.map((item) => {
    return encodeURIComponent(item.id);
  });

  const servicesParam = serviceIds.join(",");

  const requirementsIds = connectBusinessRequirements.map((item) => {
    return encodeURIComponent(item.id);
  });
  const requirementsParams = requirementsIds.join(",");

  const handleConnect = async () => {
    try {
      handleOpenConfirmDialog(); // Abre el diálogo después de la lógica de conexión
      // alert(servicesParam);
      console.log(servicesParam);
      // alert(JSON.stringify(connectPayload));
      const res = await gateway.get(
        `/connect/employee/${uid}/${usersBenefits?.UserBenefits?.id}/self?expires=${24}${servicesParam?.length > 0 ? "&services=" + encodeURIComponent(servicesParam) : ""}${
          requirementsParams.length > 0 ? "&requirements=" + encodeURIComponent(requirementsParams) : ""
        }${connectBusinessState.address ? "&address=" + encodeURIComponent(connectBusinessState.address) : ""}${
          connectBusinessState.country ? "&country=" + encodeURIComponent(connectBusinessState.country) : ""
        }${connectBusinessState.state ? "&state=" + encodeURIComponent(connectBusinessState.state) : ""}${connectBusinessState.city ? "&city=" + encodeURIComponent(connectBusinessState.city) : ""}${
          connectBusinessState.zipcode ? "&zipcode=" + encodeURIComponent(connectBusinessState.zipcode) : ""
        }${connectBusinessState.lat ? "&lat=" + encodeURIComponent(connectBusinessState.lat) : ""}${connectBusinessState.lng ? "&lng=" + encodeURIComponent(connectBusinessState.lng) : ""}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        },
      );

      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: 300,
        // height: 450,
        mx: "auto",
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 3,
        mb: 5,
        position: "relative",
      }}
    >
      {children}
      <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
        {" "}
        <Grid item xs={12} display="flex" justifyContent="center">
          {" "}
          <Avatar alt={avatar} src={avatar} sx={{ width: 100, height: 100 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontWeight: "medium", textAlign: "center" }}>
            {" "}
            {firstName} {lastName}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: 'center',
          gap: 0.5,
          marginTop: "10px",
          // marginInline: "10px",
        }}
      >
        <Stack direction="row" gap={1}>
          <Place color="action" />
          {city}, {state}, {country}
        </Stack>
        {/* <Stack direction="row" gap={1} sx={{ mt: 2 }}>
          <Work color="action" />
        </Stack> */}
        <Stack direction="row" gap={1} sx={{ mt: 2, position: "relative" }}>
          <Email color="action" />
          <Typography
            sx={{
              filter: "blur(3px)", // Ajusta el valor según la cantidad de desenfoque que deseas
            }}
          >
            email@gmail.com
          </Typography>

          <LockIcon sx={{ position: "absolute", right: 30 }} color="action"></LockIcon>
        </Stack>
        <Stack direction="row" gap={1} sx={{ mt: 2, position: "relative" }}>
          <Phone color="action" />
          <Typography
            sx={{
              filter: "blur(3px)", // Ajusta el valor según la cantidad de desenfoque que deseas
            }}
          >
            {generateRandomPhoneNumber()}
          </Typography>

          <LockIcon sx={{ position: "absolute", right: 30 }} color="action"></LockIcon>
        </Stack>
        <Stack direction="row" gap={1} sx={{ mt: 2 }}>
          <Grid container sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TravelingCard icon={<DirectionsCarIcon></DirectionsCarIcon>} duration={distances?.driving?.duration} distance={distances?.driving?.distance}></TravelingCard>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          const fc = handleOpenDialogFactory();
          fc();
        }}
        sx={{
          width: "100%",
          height: "30px",
          backgroundColor: isInOwnedCoverage ? "#233044" : "#737373",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        CONNECT
      </Button>

      <BaseDialog
        openState={openInsufficientBenefits}
        setOpenState={setOpenInsufficientBenefits}
        title="Benefits Limit Reached!"
        titleSx={styles.titleSx}
        content="You don't have the required benefits or you've exceeded the usage limit to perform this action. Please review your plan for details or contact support for assistance."
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Close",
            onClick: () => {
              setOpenInsufficientBenefits(false);
            },
            style: styles.btnCloseSx,
            icon: <ArrowForward></ArrowForward>,
          },
          {
            label: "Go to plans",
            onClick: () => {
              navigate("/plan-center");
              dispatch(resetConnectBusinessState());
              dispatch(
                setAppState({
                  currState: appStates.PLANS,
                }),
              );
            },
            style: styles.btnAcceptSx,
            icon: <Close></Close>,
          },
        ]}
      ></BaseDialog>

      <BaseDialog
        openState={openConfirmDialog}
        setOpenState={setOpenConfirmDialog}
        title="Are you sure you want to connect with this cleaner?"
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Cancel",
            onClick: () => {
              handleCloseConfirmDialog();
            },
            style: styles.btnCloseSx,
          },
          {
            label: "Accept",
            onClick: () => {
              handleAcceptConfirmDialog();
            },
            style: styles.btnAcceptSx,
          },
        ]}
      ></BaseDialog>

      <BaseDialog
        openState={openNotInYourCoverages}
        setOpenState={setOpenNotInYourCoverages}
        title="Cleaner is not located in your coverage areas!"
        titleSx={styles.titleSx}
        content="The current cleaner is not in any of you coverages areas, set a new coverage area to connect with him/her or update the alrready coverages on you account."
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Close",
            onClick: () => {
              setOpenNotInYourCoverages(false);
            },
            style: styles.btnCloseSx,
            icon: <Close></Close>,
          },
          {
            label: "Go to coverages areas",
            onClick: () => {
              navigate("/coverageareas");
              dispatch(resetConnectBusinessState());
              dispatch(
                setAppState({
                  currState: appStates.COVERAGES,
                }),
              );
            },
            style: styles.btnAcceptSx,
            icon: <CrisisAlertIcon></CrisisAlertIcon>,
          },
        ]}
      ></BaseDialog>

      <BaseDialog
        openState={openDialog}
        setOpenState={handleCloseDialog}
        disableScape={true}
        title="SUCCESSFUL CONNECTION"
        titleSx={styles.titleConnectionSx}
        content={<Typography sx={{ fontWeight: "bold", textAlign: "center", marginTop: "20px", color: "black" }}>We have sent you an email to continue with the connection process.</Typography>}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Return Home",
            onClick: () => {
              handleCloseDialog();
              dispatch(
                setAppState({
                  currState: appStates.HOME,
                }),
              );
            },
            style: styles.btnCloseSx,
            autoFocus: true,
          },
        ]}
      ></BaseDialog>

      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </Box>
  );
};
