import React, { useEffect, useState, useRef } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton, Box, Typography, ListItemIcon, ListItemText, Container, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { colorConfigs } from "../../config/colorConfigs";
import { useErrorBoundary } from "react-error-boundary";
import { getMyNotifications, updateMyNotificatioById } from "../../utils";
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff";
import { useDispatch, useSelector } from "react-redux";
import { pushNotification, updateNotifications, updateNotificationsChanges } from "../../redux/features/notificationSlice";
import { socket } from "../../sockets";
import SettingsIcon from "@mui/icons-material/Settings";
import { CheckCircle, Description, HowToReg, MarkEmailUnread } from "@mui/icons-material";

const NotificationIcons = {
  sucessfullConnect: <HowToReg color="primary"></HowToReg>,
  confirmationConnect: <MarkAsUnreadIcon color="primary"></MarkAsUnreadIcon>,
  noResponseConnect: <WifiTetheringOffIcon color="primary"></WifiTetheringOffIcon>,
  planSubscription: <CheckCircle color="primary"></CheckCircle>,
  sendContactInfo: <MarkEmailUnread color="primary"></MarkEmailUnread>,
  recievedContactInfo: <Description color="primary"></Description>,
};

export const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationsState = useSelector((state) => state.notificationsState);
  const { notifications } = notificationsState;
  const token = localStorage.getItem("token");
  const [notificationsPending, setNotificationsPending] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const handleViewMoreClick = () => {
    handleClose();
    navigate("/notifications-center");
  };

  useEffect(() => {
    getMyNotifications(token).then((res) => {
      dispatch(
        updateNotifications({
          notifications: [...res?.items],
        }),
      );
      setNotificationsPending(res?.totalItems);
    });

    socket.on("notification", (data) => {
      dispatch(pushNotification(JSON.parse(data)));
    });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = (notificationId) => {
    const idx = notifications.findIndex((notification) => notification.id === notificationId);
    const updatedNotifications = notifications?.map((notification, i) => {
      if (i === idx) {
        return { ...notification, isRead: true };
      }
      return notification;
    });

    dispatch(updateNotifications({ notifications: updatedNotifications }));
    setNotificationsPending(notifications?.filter((notification) => !notification.isRead)?.length);
    updateMyNotificatioById(token, notifications[idx]?.id, {
      isRead: true,
    }).catch((err) => showBoundary(err));
  };

  useEffect(() => {
    setNotificationsPending(notifications?.filter((notification) => !notification.isRead).length);
  }, [notifications]);

  return (
    <Box sx={{ position: "relative", mr: 1, ml: 1 }}>
      <IconButton aria-controls={open ? "notification-positioned-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <NotificationsIcon color={notificationsPending ? "info" : "disabled"} fontSize="large"></NotificationsIcon>
      </IconButton>
      {notificationsPending ? (
        <Box
          sx={{
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            backgroundColor: "#ff4545",
            position: "absolute",
            top: 0, // Adjust these values as needed
            right: 0, // to position the red Box over the IconButton
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" sx={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
            {notificationsPending <= 99 ? notificationsPending : 99}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="notification-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          mt: 6,
          boxShadow: "none",
        }}
      >
        <Container maxWidth="xs" sx={{ minHeight: "200px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Typography variant="h5" sx={{ color: "var(--glaucous-hex)" }}>
                Notifications
              </Typography>
              <Box sx={{ position: "absolute", top: -3, right: -40 }}>
                <IconButton
                  sx={{ color: "var(--glaucous-hex)" }}
                  onClick={() => {
                    navigate("/notifications-settings");
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>
            </Box>

            <Typography
              component="a"
              sx={{
                color: "var(--glaucous-hex)",
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleViewMoreClick}
            >
              View more
            </Typography>
          </Box>
          <Divider sx={{ mb: 3 }}></Divider>
          {notificationsPending ? (
            notifications
              ?.filter((notification) => !notification.isRead)
              ?.slice(0, 3)
              ?.map((notification, index) =>
                !notification.isRead ? (
                  <MenuItem
                    key={`notification-menu-item-${index}`}
                    onClick={() => {
                      markAsRead(notification?.id);
                    }}
                  >
                    <ListItemIcon>{NotificationIcons[notification?.type]}</ListItemIcon>
                    <ListItemText
                      primary={notification.message}
                      sx={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: colorConfigs.sidebar.bg,
                      }}
                    />
                  </MenuItem>
                ) : (
                  <></>
                ),
              )
          ) : (
            <Typography variant="h7" sx={{ color: "var(--glaucous-hex)" }}>
              You currently have no new notifications!
            </Typography>
          )}
        </Container>
      </Menu>
    </Box>
  );
};
