import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === "production" ? undefined : "http://localhost:3001";

// export const socket = io("http://3.101.30.111");
export const socket = io(process.env.REACT_APP_API_URL ?? "http://localhost:3001");

export function registerWithServer(uid) {
  socket.emit("register", uid);
}

export function disconnectWithServer() {
  socket.emit("user_disconnect");
}
