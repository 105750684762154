import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Loader } from "./common/Loader";
import BackPage from "./common/BackPage";
import { setAppState } from "../redux/features/appStateSlice";
import { useDispatch, useSelector } from "react-redux";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { gateway } from "../gateway";
import { LoaderComponent } from "./Loader";
import { HelpFloatingButton } from "./helpFloatingButton";

export const SignIn = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [emailInUseMessage, setemailInUseMessage] = useState("");
  const appState = useSelector((state) => state.appState);
  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const pathSignup = "/signup";
  const pathForgot = "forgotpassword";
  const currPath = "/signin";
  const home = "/";

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Mostrar el Loader
    setOpen(true); // Abrir el diálogo

    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email").trim().toLocaleLowerCase(),
      password: data.get("password"),
    };

    try {
      const response = await gateway.post("/users/signin", userData);

      if (response.status === 201) {
        const accessToken = response.data.data.token;
        setToken(accessToken);
        localStorage.setItem("token", accessToken);
        console.log(accessToken);

        navigate("/");
        window.location.reload(); //TODO: Discomented this check later.
      } else if (response.status === 401) {
        setError("Bad Credentials. Please try again.");
      } else {
        console.error("Error en el inicio de sesión");
      }
    } catch (error) {
      const response = error?.response?.data;
      console.log("Erro: ", response);
      if (!response?.data?.isAvailable) {
        //Email is alrready in use, chose other
        setEmailInUse(true);
        setemailInUseMessage(response?.message);
        console.log("an error occured: ", response?.message);
      }

      console.error("Error en la solicitud", error);
    } finally {
      setIsLoading(false); // Ocultar el Loader
      setOpen(false); // Cerrar el diálogo
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: -20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            minHeight: "50vh",
            opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
            transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
          }}
        >
          <Avatar sx={{ m: 5, bgcolor: "#2196f3", width: 80, height: 80 }}>
            <LockOutlined />
          </Avatar>

          <Typography component="h1" variant="h3">
            Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              error={emailInUse}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              error={emailInUse}
              helperText={emailInUse ? "Bad Credentials" : ""}
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <RouterLink
                  onClick={() => {
                    dispatch(
                      setAppState({
                        prevPathState: currPath,
                        currPathState: pathSignup,
                      })
                    );
                  }}
                  variant="body2"
                  to={pathForgot}
                >
                  Forgot password?
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink
                  onClick={() => {
                    dispatch(
                      setAppState({
                        prevPathState: currPath,
                        currPathState: pathSignup,
                      })
                    );
                  }}
                  to={pathSignup}
                >
                  {"Don't have an account? Sign Up"}
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <HelpFloatingButton bgColor="#1976d2"></HelpFloatingButton>
      </Container>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
};
