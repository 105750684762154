import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, CssBaseline, Typography, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, IconButton } from "@mui/material";
import { IndividualServices } from "../IndividualServices";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import { colorConfigs } from "../../config/colorConfigs";
import { getMyNotifications, updateMyNotificatioById } from "../../utils";
import { LoaderComponent } from "../Loader";
import { useErrorBoundary } from "react-error-boundary";
import WifiTetheringOffIcon from "@mui/icons-material/WifiTetheringOff";
import { useDispatch, useSelector } from "react-redux";
import { updateNotifications } from "../../redux/features/notificationSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { CheckCircle, Description, HowToReg, MarkEmailUnread } from "@mui/icons-material";

const NotificationIcons = {
  sucessfullConnect: <HowToReg color="primary"></HowToReg>,
  confirmationConnect: <MarkAsUnreadIcon color="primary"></MarkAsUnreadIcon>,
  noResponseConnect: <WifiTetheringOffIcon color="primary"></WifiTetheringOffIcon>,
  planSubscription: <CheckCircle color="primary"></CheckCircle>,
  sendContactInfo: <MarkEmailUnread color="primary"></MarkEmailUnread>,
  recievedContactInfo: <Description color="primary"></Description>,
};

export const NotificationsCenter = () => {
  const dispatch = useDispatch();
  const notificationsState = useSelector((state) => state.notificationsState);
  const { notifications } = notificationsState;
  const token = localStorage.getItem("token");
  // const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const markAsRead = (notificationId) => {
    const idx = notifications.findIndex((notification) => notification.id === notificationId);

    // Crear un nuevo array con los elementos actualizados
    const updatedNotifications = notifications.map((notification, i) => {
      if (i === idx) {
        return { ...notification, isRead: true }; // Actualizar solo el elemento que fue clickeado
      }
      return notification; // Devolver los elementos no modificados tal cual
    });
    dispatch(updateNotifications({ notifications: updatedNotifications }));

    updateMyNotificatioById(token, notifications[idx]?.id, {
      isRead: true,
    }).catch((err) => showBoundary(err));
  };

  const fetchNotifications = (page) => {
    setLoading(true);
    getMyNotifications(token, page, 20).then((res) => {
      if (res) {
        dispatch(
          updateNotifications({
            notifications: [...notifications, ...res?.items],
          }),
        );
        setCurrentPage(res.currentPage);
        setTotalPages(res.totalPages);
        console.log(res);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <CssBaseline></CssBaseline>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          minHeight: "100%",
          mt: "5em",
          flexDirection: "column",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Typography variant="h5" sx={{ color: "var(--glaucous-hex)", mb: 4 }}>
            Notifications
          </Typography>

          <Box sx={{ position: "absolute", top: -3, right: -40 }}>
            <IconButton
              sx={{ color: "var(--glaucous-hex)" }}
              onClick={() => {
                navigate("/notifications-settings");
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Box>
        <List
          sx={{
            backgroundColor: "background.paper",
            width: "100%",
            minHeight: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            borderRadius: "3px",
            margin: "0",
          }}
        >
          {notifications?.length > 0 ? (
            notifications?.map((notification, index) => (
              <>
                <ListItem key={`notification-menu-item-${index}`} sx={{ opacity: notification.isRead ? 0.5 : 1 }}>
                  <ListItemButton
                    onClick={() => {
                      markAsRead(notification?.id);
                    }}
                  >
                    <ListItemIcon>{NotificationIcons[notification?.type]}</ListItemIcon>
                    <ListItemText 
                      primary={index + 1 + ".- " + notification.message}
                      sx={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: colorConfigs.sidebar.bg,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ width: "100%" }}></Divider>
              </>
            ))
          ) : (
            <Typography variant="h7" sx={{ color: "var(--glaucous-hex)" }}>
              You currently have no new notifications!
            </Typography>
          )}
        </List>
        <Button
          color={"primary"}
          disabled={currentPage + 1 > totalPages}
          onClick={() => {
            fetchNotifications(currentPage + 1);
          }}
        >
          Load more
        </Button>
      </Box>
      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </>
  );
};
