import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { gateway } from "../gateway";
import CheckExperience from "./checkboxExperience";
import { CheckExperienceCommercial } from "./checkboxExperienceCommercial";
import { updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { updateUser } from "../redux/features/userStateSlice";
import { LoaderComponent } from "./Loader";
import { SaveButton } from "./saveButton";
import { useErrorBoundary } from "react-error-boundary";

export const ConnectBusinessServices = ({
  title,
  residentialTitle,
  commercialTitle,
}) => {
  const { showBoundary } = useErrorBoundary();
  const currPage = "/signupBusiness";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem("token");

  const residencialType = "residencial"; //The type that will be put on the
  const [residencialServices, setResidencialServices] = useState([]);

  const commercialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);

  const [myResidencialServices, setMyResidencialServices] = useState([]);

  const [myCommercialServices, setMyCommercialServices] = useState([]);

  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const userState = useSelector((state) => state.userState);

  const checkedState = [
    ...userState.residentialServices,
    ...userState.commercialServices,
  ];

  const getWorkServiceByType = async (type, setStateService) => {
    try {
      const response = await gateway.get(`/workservices?type=${type}`);
      const data = response.data.data.items;
      setStateService(data);
    } catch (error) {
      showBoundary(error);
      console.error(error);
    }
  };
  const getMyServices = async () => {
    try {
      const response = await gateway.get(`/workservices/self`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;
      const { residential, commercial } = data;

      setChanges(false);
      setMyResidencialServices(residential);
      setMyCommercialServices(commercial);
      console.log("my services", myCommercialServices, myResidencialServices);
    } catch (error) {
      showBoundary(error);
      console.error(error);
    }
  };

  const saveMyServices = async () => {
    try {
      const response = await gateway.put(
        `/workservices/self`,
        {
          services: checkedState,
        },
        {
          headers: {
            Authorization: `${tokenLocal}`,
          },
        }
      );
      const data = response.data.data;
      const { residential, commercial } = data;
      setChanges(false);
      setMyResidencialServices(residential);
      setMyCommercialServices(commercial);
      console.log(data);
    } catch (error) {
      showBoundary(error);
      console.error(error);
    }
  };

  const handleServiceInfo = async () => {
    try {
      setOpen(true);
      await getWorkServiceByType(residencialType, setResidencialServices);
      await getWorkServiceByType(commercialType, setCommercialServices);
      await getMyServices();
    } catch (error) {
      showBoundary(error);
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const handleServiceUpdate = async () => {
    try {
      setOpen(true);
      await saveMyServices();
    } catch (error) {
      showBoundary(error);
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (cancel) {
      handleServiceInfo(); // Esta función ya obtiene los servicios y actualiza los estados.
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  useEffect(() => {
    handleServiceInfo();
  }, []);
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: 8, md: 9 },
          // marginLeft: { xs: "0", md: 50 },
          width: { xs: "100%", md: 800 },
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              backgroundColor: "#233044",
              color: "white",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CheckExperience
              title={residentialTitle}
              setChanges={setChanges}
              checkedState={myResidencialServices}
              updateComponentState={updateUser}
              data={residencialServices}
            />{" "}
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckExperienceCommercial
              title={commercialTitle}
              setChanges={setChanges}
              checkedState={myCommercialServices}
              updateComponentState={updateUser}
              data={commercialServices}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: 15 }}
          >
            <Grid item>
              <SaveButton
                disabled={!changes}
                setCancel={setCancel}
                onSubmit={handleServiceUpdate}
              ></SaveButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
