import { Box, Button, Grid, TextField, Typography, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { CountryPicker } from "./countryPicker";
import Axios from "axios";
import { Loader } from "./common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { CheckBox } from "@mui/icons-material";
import { StatePicker } from "./statePicker";
import { gateway } from "../gateway";
import { updateForm } from "../redux/features/formStateSlice";
import Checkbox from "@mui/material/Checkbox";
import { LoaderComponent } from "./Loader";

export const BusinessProfile = () => {
  const currPage = "/business";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [emailInUse, setEmailInUse] = useState(false);
  const [emailInUseMessage, setemailInUseMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Estado inicial como "false"

  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido

    dispatch(updateForm({ state: "businessInformation" }));

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const user = useSelector((state) => state.formState);
  const country = useSelector((state) => state.countryState["companyCountry"]);

  const state = useSelector((state) => state.statePickerState["companyState"]);
  console.log("fdgfgfddfjajaja : state: ", state);

  const companyCoverageCountry = useSelector((state) => state.countryState["companyCoverageCountry"]);
  const companyCoverageState = useSelector((state) => state.statePickerState["companyCoverageState"]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Mostrar el Loader
    setOpen(true); // Abrir el diálogo

    const formData = new FormData(event.currentTarget);

    const businessInfo = {
      businessName: formData.get("businessName"),
      businessPhone: formData.get("businessPhone"),
      dba: formData.get("dba"),
      ssn: formData.get("ssn"),
      companyCountry: country,
      companyState: state,
      companyAddress: formData.get("address"),
      companyZipCode: formData.get("zipCode"),
      companyCoverageCountry,
      companyCoverageState,
      companyCoverageNationWide: isChecked,
    };

    dispatch(updateForm(businessInfo));

    try {
      const { signupGeneralInfo, isUserCreated, ...body } = {
        ...user,
        ...businessInfo,
      };

      console.log("The body: ", JSON.stringify(body));
      const response = await gateway.post("/users/superadmin", {
        ...body,
      });

      if (response.status < 400) {
        // Registro exitoso, redirige al usuario a la página de confirmación de correo.
        dispatch(updateForm({ isUserCreated: true }));
        // navigate("/signup/emailconfirmation");
      }
    } catch (error) {
      console.log(error);
      const response = error?.response?.data;

      if (!response?.data?.isAvailable) {
        //Email is alrready in use, chose other
        setEmailInUse(true);
        setemailInUseMessage(response?.message);
        console.log(response?.message);
      }
    } finally {
      setIsLoading(false); // Ocultar el Loader
      setOpen(false); // Cerrar el diálogo
    }
  };
  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit}
        xs
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Alinea los elementos al final de la dirección principal (vertical)
          minHeight: "40vh",
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
        }}
        // sx={{
        //   mt: 1,
        //   minHeight: "70vh",
        //   display: "flex",
        //   flexDirection: "column",
        //   bgcolor: "pink",
        // }}
      >
        <Grid container margin="normal" sx={{ height: "30px" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              id="businessName"
              label="Business Name"
              name="businessName"
              size="small"
              variant="outlined"
              sx={{
                fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
                padding: "5px", // Ajusta el espaciado interno según sea necesario
              }}
              autoFocus
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              id="businessPhone "
              label="Business Phone"
              name="businessPhone"
              size="small"
              variant="outlined"
              sx={{
                fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
                padding: "5px", // Ajusta el espaciado interno según sea necesario
              }}
              autoFocus
            />
          </Grid>
        </Grid>
        <TextField
          margin="normal"
          required
          id="dba"
          fullWidth
          label="DBA (if different from above)"
          name="dba"
          size="small"
          variant="outlined"
          sx={{
            fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
            padding: "5px", // Ajusta el espaciado interno según sea necesario
          }}
          autoFocus
        />
        <TextField
          // margin="normal"
          required
          id="ssn"
          label="EIN / SSN"
          fullWidth
          name="ssn"
          size="small"
          variant="outlined"
          sx={{
            fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
            padding: "5px", // Ajusta el espaciado interno según sea necesario
            mb: "10px",
          }}
          autoFocus
        />
        <Grid container margin="normal" sx={{ height: "30px" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CountryPicker name="companyCountry" />
          </Grid>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StatePicker name="companyState"></StatePicker>
          </Grid>
        </Grid>
        <Grid container margin="normal" sx={{ height: "30px", mt: "25px" }}>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              margin="dense"
              required
              id="address"
              label="Address"
              name="address"
              size="small"
              variant="outlined"
              sx={{
                fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
                padding: "5px", // Ajusta el espaciado interno según sea necesario
              }}
              autoFocus
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              margin="dense"
              required
              id="zipCode"
              label="Zip Code"
              name="zipCode"
              size="small"
              variant="outlined"
              sx={{
                fontSize: "12px", // Ajusta el tamaño de fuente según sea necesario
                padding: "5px", // Ajusta el espaciado interno según sea necesario
              }}
              autoFocus
            />
          </Grid>
        </Grid>
        <Typography
          component="h1"
          margin="20px"
          variant="h1"
          sx={{
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          Coverage Areas
        </Typography>
        <CountryPicker name={"companyCoverageCountry"} />

        <Grid container margin="normal" sx={{ height: "30px", mt: 1 }}>
          <Grid
            item
            xs
            sx={{
              mt: 1,
              mr: 4,
              height: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StatePicker name={"companyCoverageState"}></StatePicker>
          </Grid>
          <Grid
            item
            xs
            sx={{
              mt: 1,
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControlLabel control={<Checkbox />} onClick={() => setIsChecked(!isChecked)} label="Nation Wide" name="companyCoverageNationWide" />
          </Grid>
        </Grid>

        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Submit
        </Button>
      </Box>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
};
