import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { setPlanSelected } from "../redux/features/planStateSlice";
import { assets } from "../assets";
import { useNavigate } from "react-router-dom";
export const PlanCheckoutError = ({ subscriptionState }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Typography variant="h4" color="initial" sx={{ fontWeight: "bold" }}>
        Error Payment
      </Typography>
      <Box sx={{ width: "20%", height: "30%", mt: 2 }}>
        <img width="100%" height="100%" src={assets.images.errorSubs}></img>
      </Box>

      <Alert severity="error" icon={false}>
        <Typography variant="h5" color="initial" sx={{ fontWeight: "bold" }}>
          We are having issues processing your payment, please try again later
        </Typography>
      </Alert>
      <Button
        variant="contained"
        sx={{ mt: 2, backgroundColor: "#0068e6" }}
        onClick={() => {
          dispatch(
            setPlanSelected({
              planSelected: undefined,
            }),
          );
          navigate("/");
        }}
      >
        Go Home
      </Button>
    </Box>
  );
};
