import React from "react";
import { Button, Typography, Box, Container } from "@mui/material";
import { assets } from "../../assets";
import { ErrorConnectionRefused } from "./ErrorConnectionRefused";
import { ErrorCoverageArea } from "./ErrorCoverageArea";

export const ErrorHandler = ({ error, resetErrorBoundary }) => {
  console.log("Error");
  console.log(error);
  return (
    <>
      {error?.name === "ERR_CONNECTION_REFUSED" && (
        <ErrorConnectionRefused resetErrorBoundary={resetErrorBoundary} />
      )}

      {error?.name === "ERR_CANNOT_FIND_LOCATION" && (
        // Componente o lógica para manejar ERR_CANNOT_FIND_LOCATION
        <ErrorCoverageArea
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        ></ErrorCoverageArea>
      )}

      {error?.name === "AxiosError" &&
        error?.response?.data?.message !== "Token is not valid or expired." && (
        // Componente o lógica para manejar AxiosError
        <>
          <div>{error?.name}</div>
          <div>{error?.message}</div>
          <div>{JSON.stringify(error)}</div>
        </>

        // <div>An unexpected error occurred. Please try again later.</div>
      )}

      {/* Otros posibles errores */}
      {error &&
        error?.name !== "ERR_CONNECTION_REFUSED" &&
        error?.name !== "ERR_CANNOT_FIND_LOCATION" &&
        error?.name !== "AxiosError" && (
          <div>Unknown error: {error.message}</div>
        )}
    </>
  );
};
