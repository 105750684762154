import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Loader } from "./common/Loader";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { EmailConfirmation } from "./EmailConfirmation";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { gateway } from "../gateway";
import { LoaderComponent } from "./Loader";
export const EmailConfirmationWithPassword = () => {
  const currPage = "/signup";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isUserCreated, setIsUserCreated] = useState(false);
  let { pathname } = useLocation();
  const pathSplited = pathname.split("/");
  console.log("This spliteed: ", pathSplited);
  const [base, reset, resetToken] = pathSplited;

  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const birthDate = useSelector((state) => state.formState.birthDate);
  const country = useSelector((state) => state.countryState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Mostrar el Loader
    setOpen(true); // Abrir el diálogo

    const formData = new FormData(event.currentTarget);

    const userData = {
      password: formData.get("password"),
    };

    try {
      const response = await gateway.post(
        `/users/join/company/${pathSplited[pathSplited.length - 1]}`,
        userData
      );

      if (response.status < 400) {
        // Registro exitoso, redirige al usuario a la página de confirmación de correo.
        // setIsUserCreated(true);
        navigate("/signin");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Ocultar el Loader
      setOpen(false); // Cerrar el diálogo
    }
  };
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        {isUserCreated ? (
          <div>
            <EmailConfirmation> </EmailConfirmation>
          </div>
        ) : (
          <Box
            sx={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end", // Alinea los elementos al final de la dirección principal (vertical)
              minHeight: "50vh",
              opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
              transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
            }}
          >
            <Avatar sx={{ m: 5, bgcolor: "#2196f3", width: 80, height: 80 }}>
              <LockResetOutlinedIcon
                sx={{ width: "50%", height: "50%" }}
              ></LockResetOutlinedIcon>
            </Avatar>

            <Typography
              component="h1"
              variant="h3"
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "20px",
                  md: "30px",
                  lg: "40px",
                },
              }}
            >
              Create a password for your new account
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => setUserPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility></Visibility>
                      ) : (
                        <VisibilityOff></VisibilityOff>
                      )}
                    </IconButton>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordRepeat"
                label="Repeat New Password"
                type="password"
                id="passwordRepeat"
                value={passwordRepeat}
                onChange={(e) => {
                  const repeatPassword = e.target.value;
                  setPasswordRepeat(repeatPassword);

                  // Verifica si las contraseñas coinciden
                  if (repeatPassword !== userPassword) {
                    setPasswordMatch(false); // Las contraseñas no coinciden
                  } else {
                    setPasswordMatch(true); // Las contraseñas coinciden
                  }
                }}
                error={!passwordMatch} // Marca el campo en error si las contraseñas no coinciden
                helperText={!passwordMatch ? "Passwords do not match" : ""}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up and Join
              </Button>
            </Box>
          </Box>
        )}
      </Container>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
};
