import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import {
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { gateway } from "../gateway";
import { updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";
import { Loader } from "./common/Loader";
import { blobUrlToFile } from "./ImageUploader";
import { useParams } from "react-router-dom";

import { useErrorBoundary } from "react-error-boundary";

export const DeactivationMessage = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [wasDeleted, setWasDeleted] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDeactivateUser = async () => {
    try {
      setOpen(true);
      const response = await gateway.delete(`/users/delete/${token}`);
      console.log(response.data);
      setOpen(false);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    try {
      handleDeactivateUser();
    } catch (error) {
      showBoundary(error);
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div key="deleteDiv">
          {" "}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  backgroundColor: "#233044",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                <>{`Account succesfully deactivated`}</>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};
