import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import Avatar from '@mui/material/Avatar';
import { assets } from "../assets";
import {
  nextViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";

const images = [
  {
    avatar: assets.images.commercialService, // Asegúrate de reemplazar esto con la ruta correcta
    title: "Commercial",
    type: "commercial",
  },
  {
    avatar:assets.images.residentialService,
    title: "Residential",
    type: "residential",
  },
];

const ImageButton = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

export default function ButtonConnectBusinessServices() {
  const dispatch = useDispatch();

  const handleOnClick = (type) => () => {
    dispatch(updateConnectBusinessState({ serviceType: type }));
    dispatch(nextViewConnectBusinessState());
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ width: "100%", margin: '0 auto' }}>
    {images.map((image) => (
      <Grid item key={image.title} xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant="h5"
          color="inherit"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {image.title}
        </Typography>
        <ImageButton
          alt={image.title}
          src={image.avatar}
          onClick={handleOnClick(image.type)}
        />
      </Grid>
    ))}
  </Grid>
  );
}
