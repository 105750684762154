import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, LoadScriptNext, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { TextField, Button, Box, Typography } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { updateState } from "../redux/features/connectACleanerForJobStateSlice";
import { BaseDialog } from "./common/BaseDialog";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const styles = {
  actionSx: {
    justifyContent: "center",
  },
  btnDialog: {
    backgroundColor: "gray",
    color: "white",
    "&:hover": {
      backgroundColor: "#1d4999",
      color: "white",
    },
    margin: "0 8px",
  },
};

export const MapPredictive = ({
  onLocationSelect,
  updateComponentState = null,
  componentState,
  isMapPickerHidden = true,
  setIsEmpty = () => {},
  setChanges = () => {},
  setAddressSelected = () => {},
}) => {
  const userState = useSelector((state) => state.userState);
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [address, setAddress] = useState(userState?.address || "");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setAddress(componentState?.address);
  }, [componentState?.address]);

  const getAddressInComponents = (addressComponents = []) => {
    console.log(addressComponents);
    const notAnAddress = ["postal_code", "country", "administrative_area_level_1", "administrative_area_level_2", "administrative_area_level_3", "locality"];
    const address = addressComponents
      .filter((item) => {
        return !item?.types?.some((itemType) => notAnAddress.includes(itemType));
      })
      .map((item) => item?.long_name);
    return address.join(",");
  };

  const handleSelect = async (value) => {
    setChanges(true);

    try {
      const results = await geocodeByAddress(value);
      if (results.length > 0) {
        const latLng = await getLatLng(results[0]);
        setCurrentPosition({ lat: latLng.lat, lng: latLng.lng });

        // Extraer ciudad, estado y código postal
        const addressComponents = results[0].address_components;
        const fullAddress = getAddressInComponents(addressComponents) ?? "";
        const city = addressComponents.find((component) => component.types.includes("locality"))?.long_name ?? "";
        const state = addressComponents.find((component) => component.types.includes("administrative_area_level_1"))?.long_name ?? "";
        const zipcode = addressComponents.find((component) => component.types.includes("postal_code"))?.long_name ?? "";
        const country = addressComponents.find((component) => component.types.includes("country"))?.long_name ?? "";

        onLocationSelect(city, state, zipcode, country, fullAddress);
        setCountry(country);
        setCity(city);
        setState(state);
        setZipcode(zipcode);
        setAddress(fullAddress);
        setIsEmpty(false);
        setChanges(true);
      } else {
        console.log("No se encontraron resultados para la dirección");
      }
    } catch (error) {
      console.error("Error obteniendo coordenadas: ", error);
    }
    setIsDialogOpen(true);
  };

  const handleCorrect = () => {
    let updateStrategy = updateComponentState ?? updateState;
    dispatch(
      updateStrategy({
        address,
        country,
        lat: currentPosition.lat ?? 0,
        lng: currentPosition.lng ?? 0,
        city,
        state,
        zipcode,
      }),
    );
    setIsDialogOpen(false);
  };

  const geocodeLatLng = async (latLng) => {
    const geocoder = new window.google.maps.Geocoder();
    try {
      const response = await geocoder.geocode({ location: latLng });
      if (response.results && response.results.length > 0) {
        return response.results[0];
      } else {
        console.log("No se encontraron resultados.");
        return null;
      }
    } catch (error) {
      console.error("Error en la geolocalización inversa: ", error);
    }
  };

  const onMarkerDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setCurrentPosition({ lat: newLat, lng: newLng });

    const newLatLng = { lat: newLat, lng: newLng };
    const addressObject = await geocodeLatLng(newLatLng);
    if (addressObject) {
      setAddress(addressObject.formatted_address);
      // Aquí actualizas los demás datos como ciudad, estado y código postal
      // utilizando addressObject.address_components
    }
  };
  // Centrar el mapa en la posición actual del usuario
  useEffect(() => {
    if (map && currentPosition) {
      map.panTo(currentPosition);
    }
  }, [map, currentPosition]);

  useEffect(() => {
    dispatch(
      updateComponentState({
        address,
        country,
        city,
        state,
        zipcode,
      }),
    );
  }, [address, country, city, state, zipcode]);

  return (
    <LoadScriptNext googleMapsApiKey="AIzaSyAVA2xUJNYnk3pWEbP60u9Iz--eyc0iL9w" libraries={["places"]}>
      <Box sx={{ width: "100%" }}>
        <PlacesAutocomplete
          value={address !== "" ? address : componentState?.address}
          onChange={(handleInputChange) => {
            setAddress(handleInputChange); // Actualiza el estado de address con el valor proporcionado
            dispatch(updateComponentState({ address: handleInputChange })); // Actualiza el estado global si es necesario
          }}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField
                required
                {...getInputProps({
                  label: "Address",
                  className: "location-search-input",
                })}
                size="small"
                style={{ width: "100%" }}
              />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = suggestion.active ? { backgroundColor: "#a8a8a8", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return <div {...getSuggestionItemProps(suggestion, { style })}>{suggestion.description}</div>;
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Box>

      {isMapPickerHidden ? (
        <></>
      ) : (
        <BaseDialog
          openState={isDialogOpen}
          setOpenState={() => setIsDialogOpen(false)}
          content={
            <GoogleMap mapContainerStyle={containerStyle} center={currentPosition || { lat: 0, lng: 0 }} zoom={15}>
              {currentPosition && <Marker position={currentPosition} draggable={true} onDragEnd={onMarkerDragEnd} />}
            </GoogleMap>
          }
          actionSx={styles.actionSx}
          actions={[
            {
              label: "Confirm",
              onClick: () => {
                handleCorrect();
              },
              style: styles.btnDialog,
            },
            {
              label: "Cancel",
              onClick: () => {
                setIsDialogOpen(false);
                setAddress("");
              },
              style: styles.btnDialog,
            },
          ]}
        ></BaseDialog>
      )}
    </LoadScriptNext>
  );
};
