import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gateway } from "../gateway";
import { SignUpNextButton } from "./signupNextButton";
import { CheckEmployeeRequirements } from "./checkEmployeeRequirements";
import { updateSignupEmployeeState } from "../redux/features/signupEmployeeStateSlice";
import { updateUser } from "../redux/features/userStateSlice";
import { LoaderComponent } from "./Loader";
import { SaveButton } from "./saveButton";
import { useErrorBoundary } from "react-error-boundary";

export const ConnectBusinessRequirementsBar = ({ checkedState, nextViewComponentState, previousViewEntityState, updateComponentState = updateSignupEmployeeState }) => {
  const currPage = "/signupEmployee";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem("token");
  const { showBoundary } = useErrorBoundary();

  const [requirements, setRequirements] = useState([]);
  const [myRequirements, setMyRequirements] = useState([]);

  const [open, setOpen] = useState(false);
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const userState = useSelector((state) => state.userState);

  const getWorkRequirements = async (type) => {
    try {
      const response = await gateway.get(`/workrequirements`);
      const data = response.data.data.items;
      setRequirements(data);
      console.log("the data: ", data);
    } catch (error) {
      console.error(error);
    }
  };

  const getMyRequirements = async () => {
    try {
      const response = await gateway.get(`/workrequirements/self`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;
      setChanges(false);
      setMyRequirements(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveMyRequirements = async () => {
    try {
      const response = await gateway.put(
        `/workrequirements/self`,
        {
          requirements: userState.requirements,
        },
        {
          headers: {
            Authorization: `${tokenLocal}`,
          },
        },
      );
      const data = response.data.data;
      console.log("the data: ", data);
      setChanges(false);
      setMyRequirements(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequirementsInfo = async () => {
    try {
      setOpen(true);
      await getWorkRequirements();
      await getMyRequirements();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const handleRequirementsUpdate = async () => {
    try {
      setOpen(true);
      await saveMyRequirements();
      // await handleRequirementsInfo();
    } catch (error) {
      showBoundary(error);
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (cancel) {
      handleRequirementsInfo();
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  useEffect(() => {
    handleRequirementsInfo();
  }, []);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: 8, md: 5 },
          width: { xs: "100%", md: 600 },
          // marginLeft: { md: 60 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              Update your information
            </Typography>
            <CheckEmployeeRequirements setChanges={setChanges} checkedState={myRequirements} updateComponentState={updateUser} data={requirements} />
            {/* <CheckExperience data={commercialServices} /> */}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginTop: 15 }}>
          <Grid item>
            <SaveButton disabled={!changes} setCancel={setCancel} onSubmit={handleRequirementsUpdate}></SaveButton>
          </Grid>
        </Grid>
      </Box>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
