import {
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { sizeConfigs } from "../../config/sizeConfig";
import { colorConfigs } from "../../config/colorConfigs";
import { decodeToken } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Signoff } from "../signoff";
import { Loader } from "./Loader";
import { LoaderComponent } from "../Loader";

export default function Topbar() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [userInfo, setUserInfo] = useState(null);
  const user = useSelector((state) => state.userState);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          width: `100%`,
          ml: sizeConfigs.sidebar.width,
          boxShadow: "unset",
          color: colorConfigs.topbar.color,
          backgroundColor: colorConfigs.topbar.bg,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">
            {user?.firstName || user?.lastName
              ? `Hola, ${user?.firstName + " " + user?.lastName ?? "Usuario"}!`
              : ""}
          </Typography>
          <Typography variant="h6">{`${""}`}</Typography>
          {/* <Typography variant="h6">{`${user.role.name ?? ""} `}</Typography> */}
          <Signoff></Signoff>
        </Toolbar>
      </AppBar>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
}
