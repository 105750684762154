import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    companyName: "",
    countryCode: "USA-1",
    phone: "",
    location: "",
    message: ""
};

export const contactInfoStateSlice = createSlice({
    name: "contactInfoStateSlice",
    initialState,
    reducers: {
        updateContactInfo: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },

        resetContactInfo: () => {
            return initialState;
        },
    },
});

export const { updateContactInfo, resetContactInfo } = contactInfoStateSlice.actions;
export default contactInfoStateSlice.reducer;