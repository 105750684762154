import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Card, CardContent, CardActions, IconButton, Tooltip, Button, Grid, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from "@mui/material";
import { Today, CheckCircleOutline, Cancel, Upgrade, Info, Payment, Lock } from "@mui/icons-material";
import { useErrorBoundary } from "react-error-boundary";
import { cancelMySubscriptionInfo } from "../utils";
import { updateUser } from "../redux/features/userStateSlice";
import { LoaderComponent } from "./Loader";
import { setPlanSelected } from "../redux/features/planStateSlice";
import { BaseDialog } from "./common/BaseDialog";

const styles = {
  actionSx: {
    justifyContent: "center",
  },
  btnDialogSx: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
};

export const PlanInfo = ({ setCancel = () => {} }) => {
  const userState = useSelector((state) => state.userState);
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState(null);

  const token = localStorage.getItem("token");
  const { showBoundary } = useErrorBoundary();
  // Calcula la fecha de expiración del plan (31 días después de la creación)
  const expirationDate = new Date(userState.subscription.createdAt);
  expirationDate.setDate(expirationDate.getDate() + 31);
  const dispatch = useDispatch();
  // Calcula la fecha de reseteo de los beneficios
  const resetDate = new Date(userState.subscription.createdAt);
  resetDate.setMonth(resetDate.getMonth() + 1);

  // Asume que el próximo cobro es el día antes de la fecha de expiración
  const nextBillingDate = new Date(expirationDate);
  nextBillingDate.setDate(nextBillingDate.getDate() - 1);

  const isFreeTrialPlan = userState?.subscription?.paypalSubscriptionId === "free_trial";
  // Formatea la fecha para mostrarla
  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
  };

  useEffect(() => {}, [userState.subscription]);

  const handleCancelMySubscription = async () => {
    try {
      setLoading(true);
      const cancelSubs = await cancelMySubscriptionInfo(token);

      dispatch(
        updateUser({
          subscription: null,
          benefits: [],
        }),
      );

      setCancel(true);
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradeMySubscription = async () => {
    try {
      setLoading(true);

      dispatch(
        updateUser({
          subscription: null,
          benefits: [],
        }),
      );
      dispatch(
        setPlanSelected({
          upgradeFreeTrial: true,
        }),
      );

      setCancel(true);
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAction = () => {
    handleCancelMySubscription();
    setOpenDialog(false);
  };

  return (
    <Box sx={{ margin: 2, width: "100%" }}>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Plan: {userState.subscription.planName}
          </Typography>
          <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Today color="action" />
            Expiration Date: {isFreeTrialPlan ? <strong>---</strong> : <strong>{formatDate(expirationDate)}</strong>}
          </Typography>
          <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1, marginTop: 1 }}>
            <Payment color="action" />
            Next Billing Date: {isFreeTrialPlan ? <strong>---</strong> : <strong>{formatDate(nextBillingDate)}</strong>}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            startIcon={isFreeTrialPlan ? <Upgrade /> : <Cancel />}
            variant="outlined"
            color="secondary"
            sx={{ ml: 1 }}
            onClick={() => {
              isFreeTrialPlan ? handleUpgradeMySubscription() : handleOpenDialog();
            }}
          >
            {isFreeTrialPlan ? <Typography>Upgrade Plan</Typography> : <Typography>Cancel Subscription</Typography>}
          </Button>
        </CardActions>
      </Card>

      <BaseDialog
        openState={openDialog}
        setOpenState={handleCloseDialog}
        title={isFreeTrialPlan ? "Are you sure you want to upgrade your subscription?" : "Are you sure you want to cancel your subscription?"}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "Discard",
            onClick: handleCloseDialog,
            style: styles.btnDialogSx
          },
          {
            label: "Confirm",
            onClick: handleAction,
            style: styles.btnDialogSx
          }
        ]}
      ></BaseDialog>

      <Grid container spacing={2}>
        {userState.benefits.map((benefit, index) => {
          const isBenefitActive = benefit.config.isLimitedUsage ? benefit.UserBenefits.usageCount < benefit.config.limitedUsage : true;
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: "100%", opacity: isBenefitActive ? 1 : 0.5 }}>
                <CardContent>
                  <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                    {benefit.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {benefit.config.isLimitedUsage ? `Usages left: ${benefit.config.limitedUsage - benefit.UserBenefits.usageCount}/${benefit.config.limitedUsage}` : "Unlimited usage"}
                  </Typography>
                  <Typography variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Info color="action" />
                    Resets on: <strong>{formatDate(resetDate)}</strong>
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title={isBenefitActive ? "More info" : "Benefit used up"}>
                    <span>
                      {" "}
                      {/* Span para que el Tooltip funcione con elementos deshabilitados */}
                      <IconButton aria-label="more info" disabled={!isBenefitActive}>
                        {isBenefitActive ? <CheckCircleOutline /> : <Lock />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <LoaderComponent open={loading} type="spinner"></LoaderComponent>
    </Box>
  );
};
