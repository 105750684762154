import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { PlanNameFromType, setPlanSelected } from "../redux/features/planStateSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { PromoCodeDisccount } from "./PromoCodeDisccount";
import "../assets/styles/plans.css"
import { useDispatch } from "react-redux";

export const PlanCheckoutTicket = ({ title, planSelected, subscriptionState }) => {
  const [discount, setDisccount] = useState(0);

  const getTotalPrice = (price, discount) => {
    const total = price - discount;
    return total >= 0 ? total : 0;
  };

  return (
    <Box
      sx={{
        border: 1,
        p: 2,
        pt: 3,
        borderColor: "grey.500",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
      }}
      className="summary"
    >
      <Typography variant="h5" color="primary.main" sx={{ fontWeight: "bold", mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="h7" color="primary.main" sx={{ fontWeight: "bold" }}>
        {`ADVANTAGES OF THE ${planSelected?.name?.toUpperCase()} PLAN`}
      </Typography>
      <List>
        {planSelected?.benefits.map((benefit) => (
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: "info.main" }}></CheckCircleOutlineIcon>
            </ListItemIcon>
            <ListItemText>
              <Typography>{benefit}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Typography variant="h7" color="primary.main" sx={{ fontWeight: "bold", mb: 1 }}>
        {`Cost`}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Subtotal</Typography>
        <Typography>${planSelected?.price?.amount ?? 0}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Promo Code Disccount</Typography>
        <Typography>${discount}</Typography>
      </Box>
      <Divider sx={{ width: "100%", height: "100%", mt: 2, mb: 2 }}></Divider>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography fontWeight={"bold"}>Total</Typography>
        <Typography fontWeight={"bold"}>${getTotalPrice(planSelected?.price?.amount ?? 0, discount ?? 0)}</Typography>
      </Box>
      {subscriptionState.state === "success" || subscriptionState.state === "error" ? (
        <></>
      ) : planSelected?.price?.amount === 0 ? (
        <></>
      ) : (
        <PromoCodeDisccount setDisccount={setDisccount}></PromoCodeDisccount>
      )}
    </Box>
  );
};
