import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BackPage from "./common/BackPage";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { gateway } from "../gateway";
import { Token } from "@mui/icons-material";

export const EmailConfirmed = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const [base, path, subPath, uidToken] = pathname.split("/");
  const confirmEmail = async () => {
    setIsLoading(true);
    setOpen(true);

    try {
      const response = await gateway.get(`/users/activation/${uidToken}`);
      console.log(path, subPath, uidToken);
      console.log(response);
      if (response.status === 200) {
        console.log("Account was activated properly: ", response);
        // setToken(accessToken);
        // localStorage.setItem("token", accessToken);
        // console.log(accessToken);
        // navigate("/");
        // window.location.reload();
      } else if (response.status === 401) {
        // setError("Bad Credentials. Please try again.");
      } else {
        console.error("Error en el inicio de sesión try");
      }
    } catch (error) {
      console.error("Error en la solicitud catch", error);
    } finally {
      setIsLoading(false); // Ocultar el Loader
      setOpen(false); // Cerrar el diálogo
    }
  };

  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    confirmEmail();
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        <Card sx={{ backgroundColor: "#2196f3", color: "#fff" }}>
          <CardContent>
            <Typography 
              variant="h4" 
              gutterBottom 
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Thank you for activating your account!
            </Typography>
            <Typography 
              variant="body1" 
              paragraph 
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Now you're ready to log in
            </Typography>
          </CardContent>
        </Card>

        <Button
          variant="contained"
          color="primary"
          LinkComponent={"/"}
          style={{ marginTop: "10px" }}
        >
          Log in
        </Button>
      </Box>
    </Container>
  );
};
