import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import HouseIcon from "@mui/icons-material/House";
import { useErrorBoundary } from "react-error-boundary";
import { LoadingCard } from "./common/LoadingCard";
import { getMyServices } from "../utils";
import { assets } from "../assets";

export const IndividualServices = ({ title, type }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const [isVisible, setIsVisible] = useState(false);
  const [myServices, setMyServices] = useState([]);

  const [visibleItemCount, setVisibleItemCount] = useState(4); // Nuevo estado
  // Función para alternar la visualización
  const toggleVisibleItems = () => {
    if (visibleItemCount === 4) {
      setVisibleItemCount(myServices.length);
    } else {
      setVisibleItemCount(4);
    }
  };

  useEffect(() => {
    setLoading(true);
    getMyServices(type, token)
      .then((res) => {
        setMyServices(res);
      })
      .catch((err) => showBoundary(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          setIsVisible(true);
        }, 1000);
      });
  }, [token]); // added token dependency

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
        margin: "0",
        position: "relative",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginTop: "1em", m: "0", mb: "1em", height: "100%" }}
      >
        <Avatar
          sx={{ marginTop: "1em" }}
          src={
            type === "commercial"
              ? assets.images.commercialIcon
              : assets.images.residentialIcon
          }
        ></Avatar>
        <Typography
          variant="subtitle1"
          marginLeft={2}
          sx={{ marginTop: "1em" }}
        >
          {title}
        </Typography>
      </Box>
      <Divider sx={{ width: "80%" }} />

      {!loading ? (
        <>
          <List
            sx={{
              width: "100%",
              minHeight: {
                md: "235px",
                xs: "235px",
              },
              display: "flex",
              flexDirection: "column",
 
            }}
          >
            {myServices.slice(0, visibleItemCount).map((service, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={service?.workServices?.name}></ListItemText>
              </ListItem>
            ))}
          </List>
          {myServices.length > 4 && ( // Mostrar el botón solo si hay más de 4 servicios
            <Button
              onClick={toggleVisibleItems}
              sx={{
                mt: visibleItemCount === myServices.length ? -1 : -3,
              }}
            >
              {visibleItemCount === myServices.length
                ? "Show less"
                : "Show more"}
            </Button>
          )}
        </>
      ) : (
        <LoadingCard />
      )}
    </Box>
  );
};
