import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { assets } from "../../assets";

export const ErrorConnectionRefused = (resetErrorBoundary) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "5%" }}>
      <Box>
        <img
          src={assets.images.serverbroken}
          alt="Server Down"
          style={{ maxWidth: "100%", height: "auto" }}
        />
        <Typography variant="h5" gutterBottom>
          Oops! It looks like the server is down.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We're experiencing some technical issues. Please try again later.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
        >
          Try Again
        </Button>
      </Box>
    </Container>
  );
};
