import React from "react";
import { Grid,Typography } from "@mui/material";
export const TravelingCard = ({ icon, distance, duration }) => {

  const getColor = (duration) => {
    if (duration >= 60) {
      return 'red';
    } else if (duration > 30) {
      return 'orange';
    } else {
      return 'green'; // Verde para duraciones de 30 minutos o menos
    }
  };

  const formatDuration = (duration) => {
    if (duration >= 60) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours}h ${minutes}min`; // Formato de horas y minutos
    }
    return `${duration} min`; // Solo minutos si es menos de 60
  };
  return (
    <>
      {" "}
      {isNaN(distance) || isNaN(duration) ? (
        <></>
      ) : (
         <Grid container alignItems="center" justifyContent="left">
          <Grid item> {/* Quitamos xs={12} para que no tome toda la fila */}
            {icon}
          </Grid>
          <Grid item>
            <Typography 
              variant="body1" 
              sx={{ 
                fontWeight: 'bold', 
                color: getColor(Math.floor(duration)), // Aquí aplicamos la función para determinar el color
                mx: 1 
              }}>
              {formatDuration(Math.floor(duration))} {/* Aplica la función para formatear la duración */}
            </Typography>
          </Grid>
          <Grid item>
            {distance?.toFixed(1)} miles
          </Grid>
        </Grid>
      )}
    </>
  );
};
