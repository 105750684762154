import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SettingsIcon from "@mui/icons-material/Settings";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import CalculateIcon from '@mui/icons-material/Calculate';
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
export const appRoutes = [
  {
    index: true,
    state: "home",
  },

  {
    path: "/",
    // element: <DashboardPageLayout />,
    // state: "dashboard",
    policy: ["superadmin", "admin"],
    sidebarProps: {
      displayText: "Business Profiles",
      icon: <BusinessCenterIcon></BusinessCenterIcon>,
    },
    child: [
      {
        index: true,
        state: "dashboard.index",
      },
      {
        path: "/businessprofile/admins",
        policy: ["superadmin"],
        sidebarProps: {
          displayText: "Admins",
          icon: <SupportAgentIcon></SupportAgentIcon>,
        },
      },
      {
        path: "/businessprofile/employees",
        policy: ["superadmin", "admin"],
        sidebarProps: {
          displayText: "Employess",
          icon: <BadgeIcon></BadgeIcon>,
        },
      },
      {
        path: "/businessprofile/clients",
        policy: ["superadmin", "admin"],
        sidebarProps: {
          displayText: "Clients",
          icon: <PersonPinIcon></PersonPinIcon>,
        },
      },
    ],
  },
  {
    path: "/reports",
    state: "reports",
    policy: ["superadmin"],
    sidebarProps: {
      displayText: "Reports",
      icon: <LeaderboardIcon></LeaderboardIcon>,
    },
  },
  {
    path: "/tickets",
    state: "tickets",
    policy: ["superadmin", "admin"],
    sidebarProps: {
      displayText: "Tickets",
      icon: <EditNoteIcon></EditNoteIcon>,
    },
  },

  {
    path: "/settings",
    state: "settings",
    policy: ["superadmin", "admin", "employee"],
    sidebarProps: {
      displayText: "Setings",
      icon: <SettingsIcon></SettingsIcon>,
    },
  },

  {
    path: "/connect",
    state: "connect",
    policy: ["superadmin"],
    sidebarProps: {
      displayText: "Connect Employee",
      icon: <PersonPinIcon></PersonPinIcon>,
    },
  },
  {
    path: "/calculator",
    state: "connect",
    sidebarProps: {
      displayText: "Calculator",
      icon: <CalculateIcon></CalculateIcon>,
    },
  },
];
