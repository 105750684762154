import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useNavigate } from "react-router-dom";

export const SaveButton = ({ onSubmit = () => {}, type, disabled = false, setCancel, sx, refresh = false }) => {
  const navigate = useNavigate();
  return (
    <div key={"saveButton"}>
      <Grid container spacing={2} sx={typeof sx === "object" ? { ...sx } : undefined}>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type={type}
            disabled={disabled} // Deshabilitar el botón si el formulario no es válido
            sx={disabled === true ? { opacity: 0.5, height: "80%" } : { opacity: 1, height: "80%" }}
            onClick={async () => {
              try {
                setCancel(true);
              } catch (error) {}
            }}
            style={{
              // marginTop: "5%",
              borderRadius: "5px",
              width: "100%",
              backgroundColor: "#233044",
              color: "white",
            }}
            variant="contained"
            disableElevation
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type={type}
            disabled={disabled} // Deshabilitar el botón si el formulario no es válido
            sx={disabled === true ? { opacity: 0.5, height: "80%" } : { opacity: 1, height: "80%" }}
            onClick={async () => {
              try {
                if(refresh) {
                  setCancel(true);
                }
                await onSubmit();
              } catch (error) {
                console.error("Error when traying to execute: onSubmit();");
                console.error(error);
              }
            }}
            style={{
              marginTop: "5%",
              borderRadius: "5px",
              width: "100%",
              backgroundColor: "#233044",
              color: "white",
            }}
            variant="contained"
            disableElevation
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
