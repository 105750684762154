import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Divider } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";
import { LoadingCard } from "./common/LoadingCard";
import { getMyLocation } from "../utils";
import { assets } from "../assets";
import { CoverageAreaEmpMap } from "./coverageAreaEmpMap";
import { CoverageDashboard } from "./coverageAreaDashboard";
// import { IndividualMap } from "./IndividualMap";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/features/userStateSlice";
export const IndividualLocationMap = ({ title, type }) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const [myLocation, setMyLocation] = useState({
    lat: 20.980314835564766,
    lng: -89.59187576257989,
  });

  useEffect(() => {
    setLoading(true);
    getMyLocation(token)
      .then((res) => {
        if (res && res.length > 0) {
          setMyLocation(res[0]);
          dispatch(updateUser({ coverageArea: { ...res[0] } }));
        }
      })
      .catch((err) => showBoundary(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, [token]); // added token dependency

  useEffect(() => {}, [myLocation]);

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
        margin: "0",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginTop: "1em", m: "0", mb: "1em" }}
      >
        <Avatar sx={{ marginTop: "1em" }} src={assets.images.mark}></Avatar>
        <Typography
          variant="subtitle1"
          marginLeft={2}
          sx={{ marginTop: "1em" }}
        >
          {title}
        </Typography>
      </Box>
      <Divider sx={{ width: "80%" }} />

      {!loading ? <CoverageDashboard></CoverageDashboard> : <LoadingCard />}
    </Box>
  );
};
