import jwt_decode from "jwt-decode";
import { ResponseError, gateway } from "../gateway";

export const IRoles = {
  Business: "business",
  Employee: "employee",
  SuperAdmin: "superadmin",
};

export const EBenefitTypes = {
  FINDACLEANER: "findacleaner",
  SUPPORT: "support",
  AREASCOVERAGES: "areascoverages",
};

export const EConnectionStatus = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
  PENDING: "pending",
};

export const decodeToken = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    // Manejo de errores en caso de que el token sea inválido o haya expirado
    console.error("Error al decodificar el token:", error);
    throw error;
  }
};

export const milesToKm = (miles) => {
  return miles * 1.60934;
};

export const kmToMiles = (km) => {
  return km * 0.621371;
};

export const generateErrorCodeHandlerInBaseOfError = (error, errorName, errDescription) => {
  try {
    let e = null;
    if ((error.name === "AxiosError" && error?.response?.data?.status === 400) || error?.response?.data?.status === 500) {
      e = new ResponseError(errorName, errDescription);
    }
    return e ?? error;
  } catch (error) {
    console.error("Error when tried generateErrCode:", error);
    throw error;
  }
};

export const getMyServices = async (type, token) => {
  try {
    const res = await gateway.get("/workservices/self", {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    const service = data[`${type}`];
    return service;
  } catch (error) {
    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_SERVICES", "Error when tried to find your skills");
    throw e;
  }
};

export const getMyAvailability = async (token) => {
  try {
    const response = await gateway.get(`/availability/self`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = response.data.data;
    return data;
  } catch (error) {
    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_SERVICES", "Error when tried to find your skills");
    throw e;
  }
};

export const processSchedule = (inputData) => {
  const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const fullDayNames = {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  };

  let output = [];

  days.forEach((day) => {
    let dayData = {
      AM: inputData["AM"][day] || false,
      Midday: inputData["Midday"][day] || false,
      PM: inputData["PM"][day] || false,
    };
    let dayEntry = {};
    dayEntry[fullDayNames[day]] = dayData;
    output.push(dayEntry);
  });

  return output;
};

export const getMyRequirements = async (token) => {
  try {
    const response = await gateway.get(`/workrequirements/self`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = response.data.data;

    return data;
  } catch (error) {
    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_REQUIREMENTS", "Error when tried to find your skills");
    throw e;
  }
};

export const mapToCoverageModel = (data) => {
  let result = [];
  if (data && data.length > 0) {
    result = data.map((item) => {
      if (!isNaN(Number(item.lat)) && !isNaN(Number(item.lng))) {
        const { lat, lng, radius, ...restBody } = item;
        return {
          latitude: Number(lat),
          longitude: Number(lng),
          milesOfCoverageArea: kmToMiles(Number(radius)) || 16093.4,
          ...restBody,
        };
      }
    });
  }
  return result;
};

export const mapToCoverageClient = (data) => {
  let result = [];
  if (data && data.length > 0) {
    result = data.map((item) => {
      if (!isNaN(Number(item.latitude)) && !isNaN(Number(item.longitude))) {
        const { latitude, longitude, milesOfCoverageArea, ...restBody } = item;
        return {
          lat: Number(latitude),
          lng: Number(longitude),
          radius: Number(milesOfCoverageArea),
          ...restBody,
        };
      }
    });
  }
  console.log("mapToCoverageClient: ", result);
  return result;
};

export const getMyLocation = async (token) => {
  try {
    // throw new ResponseError("ERR_CONNECTION_REFUSED", "error throwed.");
    const res = await gateway.get("/coverageareas/self", {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    let result = mapToCoverageClient(data);
    return result;
  } catch (error) {
    console.log("error when find coveragearea ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_LOCATION", "It was not posible to find your location.");
    throw e;
  }
};

export const getMyNotifications = async (token, page = 1, pageSize = 20, where = {}) => {
  try {
    const params = {
      pageSize: pageSize,
      page: page,
      where: JSON.stringify(where),
    };

    const res = await gateway.get(`/notifications/self`, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find your notifications ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_NOTIFICATIONS", "It was not posible to find your notification.");
    throw e;
  }
};

export const getMyNotificationsSettings = async (token) => {
  try {
    const res = await gateway.get(`/notifications/settings-self`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find your notifications settings ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_NOTIFICATIONS_SETTINGS", "It was not posible to find your notification settings.");
    throw e;
  }
};

export const updateMyNotificationsSettings = async (body, token) => {
  try {
    const res = await gateway.put(
      `/notifications/settings-self`,
      { ...body },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find your notifications settings ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_NOTIFICATIONS_SETTINGS", "It was not posible to find your notification settings.");
    throw e;
  }
};

export const updateMyNotificatioById = async (token, id, body) => {
  try {
    const res = await gateway.put(`/notifications/self/${id}`, body, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when tried to update your notification", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_UPDATE_NOTIFICATION", "It was not posible to update your notification.");
    throw e;
  }
};

export const createOrUpdateMyLocation = async (token, payload, userBenefitId) => {
  try {
    const coverageMapToCoverageModel = mapToCoverageModel(payload);
    let res = await gateway.put(
      `/coverageareas/self/${userBenefitId}`,
      {
        coverageAreas: coverageMapToCoverageModel,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    return res;
  } catch (error) {
    console.log("error when find coveragearea ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_UPDATE_LOCATIONS", "It was not posible to update your location.");
    throw e;
  }
};

export const generateRandomPhoneNumber = () => {
  const randomDigit = () => Math.floor(Math.random() * 10);
  return `+${randomDigit()}${randomDigit()}-${randomDigit()}${randomDigit()}-${randomDigit()}${randomDigit()}-${randomDigit()}${randomDigit()}`;
};

export const getPlans = async () => {
  try {
    const res = await gateway.get(`/paypal/plans`);
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find plans ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_PLANS", "It was not posible to find company plans.");
    throw e;
  }
};

export const getPlanById = async (paypalPlanId, isFreeTrial = false, isCustom = false) => {
  try {
    const res = await gateway.get(`/paypal/plans/${paypalPlanId}`, {
      params: {
        isFreeTrial,
        isCustom,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find plan ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_PLAN", "It was not posible to find company plan.");
    throw e;
  }
};

export const getPlanByCodeDiscount = async (paypalPlanId) => {
  try {
    const res = await gateway.get(`/paypal/plans-promo/${paypalPlanId}`);
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find plan with promo code discount ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_PLAN", "It was not posible to find company plan.");
    throw e;
  }
};

export const createConnectSubscription = async (subscriptionId, paypalPlanId, subtotal, discount, total, token) => {
  try {
    //Create a subscription in our web but not in paypal side
    //Use this when paypal payment was well
    const res = await gateway.post(
      `/paypal/subscriptions-connect`,
      {
        subscriptionId,
        paypalPlanId,
        subtotal,
        discount,
        total,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find plan with promo code discount ", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_PLAN", "It was not posible to find company plan.");
    throw e;
  }
};

export const getMySubscriptionInfo = async (token) => {
  try {
    const res = await gateway.get(`/paypal/subscriptions-self`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find self subscription.", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_SUBSCRIPTION_SELF", "It was not posible to find your subscription.");
    throw e;
  }
};

export const cancelMySubscriptionInfo = async (token) => {
  try {
    const res = await gateway.delete(`/paypal/subscriptions-self`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find delete your subscription.", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_DELETE_SUBSCRIPTION_SELF", "It was not posible to delete your subscription.");
    throw e;
  }
};

export const getUsersSummary = async (type, page, pageSize, token) => {
  try {
    const params = {
      type,
      page,
      pageSize,
    };

    console.log(params);
    const res = await gateway.get(`/admin/summary`, {
      params,
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find delete your subscription.", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_DELETE_SUBSCRIPTION_SELF", "It was not posible to delete your subscription.");
    throw e;
  }
};

export const getUsersQuickSummary = async (token) => {
  try {
    const res = await gateway.get(`/admin/summary-quick`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find delete your subscription.", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_GET_USER_SUMMARY", "It was not posible to get users summary.");
    throw e;
  }
};

export const deleteUsersByAdmin = async (userUids = [], token) => {
  try {
    const parseUsersUids = encodeURIComponent(userUids);

    const res = await gateway.delete(`/admin/delete-by-admin?uids=${parseUsersUids}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when find delete your subscription.", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_DELETE_SUBSCRIPTION_SELF", "It was not posible to delete your subscription.");
    throw e;
  }
};

export const enterpriseContactInfo = async (name, title, company, email, phone, location, message, countryCode, token) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("title", title);
  formData.append("company", company);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("location", location);
  formData.append("message", message);
  formData.append("countryCode", countryCode);
  try {
    const res = await gateway.post(
      `/paypal/plans/enterprise-contact-us`,
      { name, title, company, email, phone, location, message, countryCode },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    const data = res.data.data;
    return data;
  } catch (error) {
    console.log("error when sending contact info", error);

    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_SEND_CONTACT_INFO", "It was not possible to send contact information.");
    throw e;
  }
};

export const setMetaDescription = () => {
  const language = navigator.language;

  if (language.startsWith("es")) {
    const description = "Potencia tu empresa de limpieza o consigue más trabajo con Connect a Cleaner. Conéctate sin esfuerzo con empresas de primer nivel y profesionales cualificados. ¡Únete ahora!";
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    metaDescriptionTag.setAttribute("content", description);
  }
};

export const getMyConnections = async (query, token) => {
  try {
    const res = await gateway.get(`/connect/self${query}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return res.data.data;
  } catch (error) {
    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_FIND_SERVICES", "Error when tried to find your skills");
    throw e;
  }
};

export const updateMyConnections = async (id, status, token) => {
  try {
    const res = await gateway.patch(
      `/connect/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );
    return res.data.data;
  } catch (error) {
    const e = generateErrorCodeHandlerInBaseOfError(error, "ERR_CANNOT_UPDATE_CONNECTION", "Error when tried to update the notification.");
    throw e;
  }
};
