import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StatePicker } from "./statePicker";
import { updateForm } from "../redux/features/formStateSlice";
import {
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
  updateSignupBusinessState,
} from "../redux/features/signupBusinessStateSlice";
import { MapComponent } from "./coverageAreaMap";
import { SignUpNextButton } from "./signupNextButton";
import { SaveButton } from "./saveButton";
import { createOrUpdateMyLocation, getMyLocation } from "../utils";
import { LoaderComponent } from "./Loader";

export const ConnectEmpCoverageArea = ({
  title = "",
  updateEntityState,
  entityState,
  markersLimit = undefined,
  allowsDeleteMarkers = true,
  isInformative = false, //That means that u only need to see information
  zoom = 5,
  mapContainerStyle = undefined,
}) => {
  const currPage = "/connect";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const token = localStorage.getItem("token");
  const userState = useSelector((state) => state.userState);
  useEffect(() => {
    dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const handleResetParentStates = () => {
    setCancel(false);
    setChanges(false);
  };

  const handleSubmit = async () => {
    try {
      setOpen(true);
      const coverageAreas = await createOrUpdateMyLocation(token, data);
      dispatch(updateEntityState({ coverageAreas: coverageAreas.data.data }));
      dispatch(
        updateEntityState({ coverageArea: coverageAreas?.data?.data[0] })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(false);
      handleResetParentStates();
    }
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
        m: "0px",
        width: "100%",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
          width: "100%",
        }}
        sx={{
          marginTop: { xs: "15%", md: 9 },
          width: { xs: "100%", md: "100%" },
          // marginLeft: { xs: 0, md: 50 },
        }}
      >
        {!userState?.coverageArea && !isInformative ? (
          <Typography
            variant="h5"
            style={{
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              color: "white",
              background: "#233044",
              borderRadius: "10px",
              textAlign: "center",
              width: "100%",
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            {title}
          </Typography>
        ) : (
          <></>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MapComponent
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            updateEntityState={updateEntityState}
            entityState={entityState}
            setChanges={setChanges}
            setLoading={setOpen}
            refresh={cancel}
            refreshCb={handleResetParentStates}
            setData={setData}
            markersLimit={markersLimit}
            allowsDeleteMarkers={allowsDeleteMarkers}
            isInformative={isInformative}
          ></MapComponent>
          {!isInformative ? (
            <Grid item>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <SaveButton
                    disabled={!changes}
                    setCancel={setCancel}
                    onSubmit={() => {
                      handleSubmit();
                    }}
                  ></SaveButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
