import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Plan } from "../Plan";
import { useDispatch, useSelector } from "react-redux";
import { PlanCheckoutLayout } from "./PlanCheckoutLayout";
import { getMySubscriptionInfo, getPlanById, getPlans } from "../../utils";
import { useErrorBoundary } from "react-error-boundary";
import { PlanInfo } from "../PlanInfo";
import { updateUser } from "../../redux/features/userStateSlice";
import { LoaderComponent } from "../Loader";
import "../../assets/styles/plans.css";

export const PlanType = {
  FREE_TRIAL: "free_trial",
  STANDARD: "standard",
  CORPORATION: "corporation",
};

export const PlansLayout = () => {
  const { planSelected } = useSelector((state) => state.planState);
  const { upgradeFreeTrial } = useSelector((state) => state.planState);
  const [plans, setPlans] = useState([]);
  const userState = useSelector((state) => state.userState);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [cancel, setCancel] = useState(false);

  const { showBoundary } = useErrorBoundary();
  const fetchPlans = async () => {
    try {
      const availablePlans = await getPlans();
      const sortedPlans = availablePlans.sort((a, b) => a.order - b.order);
      let plans = [];

      for (let plan of sortedPlans) {
        const { isFreeTrial, isCustom } = plan;
        const planId = isFreeTrial || isCustom ? plan?.id : plan?.paypalPlanId;
        const planDetail = await getPlanById(planId, isFreeTrial, isCustom);

        if (upgradeFreeTrial && isFreeTrial) continue;

        plans.push({
          id: planId,
          isCustom: plan?.isCustom,
          title: planDetail?.product?.name,
          description: planDetail?.product?.description,
          benefits: planDetail?.product?.benefits?.map((benefit) => benefit.name),
          price: {
            amount: !isFreeTrial ? planDetail?.billing_cycles?.find((billing_cycle) => billing_cycle?.tenure_type === "REGULAR")?.pricing_scheme?.fixed_price?.value : 0,
            currency: !isFreeTrial ? planDetail?.billing_cycles?.find((billing_cycle) => billing_cycle?.tenure_type === "REGULAR")?.pricing_scheme?.fixed_price?.currency_code : null,
            frequency: !isFreeTrial ? "monthly" : "",
          },
          hexColor: planDetail?.hexColor,
          isPopular: plan?.isPopular,
        });
      }
      setPlans(plans);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleGetMySubInfo = async () => {
    try {
      setLoading(true);
      const subInfo = await getMySubscriptionInfo(token);
      dispatch(
        updateUser({
          subscription: subInfo?.subscription?.id
            ? {
                id: subInfo?.subscription?.id,
                paypalSubscriptionId: subInfo?.subscription?.paypalId,
                planName: subInfo?.subscription?.plan?.product?.name,
                planId: subInfo?.subscription?.plan?.id,
                planPaypalId: subInfo?.subscription?.plan?.paypalPlanId,
                hexColor: subInfo?.subscription?.plan?.product?.hexColor,
                createdAt: subInfo?.subscription?.createdAt,
              }
            : null,

          benefits: subInfo?.benefits ?? [],
        }),
      );
      return subInfo;
    } catch (error) {
      showBoundary(error);
    }
  };

  useEffect(() => {
    handleGetMySubInfo()
      .then((res) => {
        if (!res?.subscription?.id || upgradeFreeTrial) return fetchPlans();
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    handleGetMySubInfo()
      .then((res) => {
        if (!res?.subscription?.id || upgradeFreeTrial) return fetchPlans();
      })
      .finally(() => setLoading(false));
  }, [cancel, upgradeFreeTrial]);

  return (
    <Box className="plans">
      {loading === false ? (
        planSelected ? (
          <PlanCheckoutLayout />
        ) : userState?.subscription !== null && !upgradeFreeTrial ? (
          <PlanInfo setCancel={setCancel} />
        ) : (
          plans.map((plan, index) => (
            <Box className={plan.isPopular ? "items popular" : "items"} key={`plan-box-${index}`}>
              <Plan
                key={`plan-${index}-${plan.id}`}
                title={plan.title}
                description={plan.description}
                benefits={plan.benefits}
                price={plan.price}
                bannerHexColor={plan.hexColor}
                frequency={plan.price.frequency}
                planId={plan.id}
                isCustom={plan?.isCustom}
                isPopular={plan.isPopular}
              />
            </Box>
          ))
        )
      ) : (
        <></>
      )}

      <LoaderComponent open={loading} type="card" hideBackdrop={true} />
    </Box>
  );
};
