import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { SuperAdminSearch } from "./SuperAdminSearch";

export const SuperAdminBaseSectionCard = ({ cbOnClick = () => {}, title, icon, totalCount, todayCount, monthCount }) => {
  return (
    <Box onClick={() => cbOnClick()}>
      <Grid
        container
        // spacing={3}
        sx={{
          width: {
            xs: "200px",
            sm: "300px",
            md: "400px",
          },
          height: {
            xs: "75px",
            sm: "150px",
            md: "200px",
          },
          backgroundColor: "white",
          padding: 2,
          margin: 2,
          borderRadius: 2,
          boxShadow: 4,
        }}
      >
        <Grid item xs={12}>
          <Typography textAlign={"center"} fontWeight={"bold"}>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box
            sx={{
              width: {
                xs: "30px",
                sm: "50px",
                md: "70px",
              },
              height: {
                xs: "30px",
                sm: "50px",
                md: "70px",
              },
              backgroundColor: "white",
              padding: 2,
            }}
          >
            <img width={"100%"} height={"100%"} src={icon}></img>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" textAlign={"center"} sx={{ color: "#1c6bcb", fontWeight: "bold" }}>
            {totalCount}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: "gray" }} textAlign={"center"}>{`Today - ${todayCount}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: "gray" }} textAlign={"center"}>{`This Month - ${monthCount}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
