import { colors } from "@mui/material";

export const colorConfigs = {
  sidebar: {
    bg: "#233044",
    bgSecundary: "#efedecff",
    color: "#eeee",
    hoverBg: "#1e293a",
    activeBg: "#1e253a",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};
