import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateSignupEmployeeState } from "../redux/features/signupEmployeeStateSlice";

export default function CheckExperienceResidential({
  title,
  data,
  updateComponentState,
  checkedState,
  setChanges = () => {},
}) {
  const dispatch = useDispatch();

  // Inicializa el estado con los elementos ya seleccionados
  const [residentialServices, setResidencialExp] = useState(checkedState || []);

  useEffect(() => {
    // Esta lógica asegura que el estado se actualice solo cuando cambie checkedState
    setResidencialExp(checkedState);
  }, [checkedState]);

  const handleCheckboxChange = (e, item) => {
    setChanges(true);
    if (e.target.checked) {
      setResidencialExp((prev) => [...prev, item]);
    } else {
      setResidencialExp((prev) => prev.filter((itemC) => itemC.id !== item.id));
    }
  };

  useEffect(() => {
    console.log(residentialServices);
    dispatch(updateComponentState({ residentialServices }));
  }, [residentialServices]);

  // Función para verificar si un item está seleccionado
  const isChecked = (item) => {
    return (
      Array.isArray(residentialServices) &&
      residentialServices.some((service) => service.id === item.id)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "white",
        width: "100%",
        borderRadius: "10px",
        height: "auto",
        padding: "10px",
      }}
    >
      <div>
        <p
          style={{
            fontFamily: "Tahoma, sans-serif",
            color: "#233044",
            fontWeight: "bold",
            fontSize: "1.25rem",
            textAlign: "center",
            marginTop: "-2px",
          }}
        >
          {title ?? "Residential Cleaning Services"}
        </p>

        <FormControl
          component="fieldset"
          style={{ height: "400px", overflow: "auto", width: "100%" }}
        >
          <FormGroup aria-label="position">
            {data.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked(item)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                }
                label={item.workServices.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
}
