import { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Tooltip, Button, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../../redux/features/userStateSlice";
import { assets } from "../../assets";
import { sizeConfigs } from "../../config/sizeConfig";
import { colorConfigs } from "../../config/colorConfigs";
import { resetConnectBusinessState } from "../../redux/features/connectBusinessStateSlice";
import { ConnectBusinessExit } from "../ConnectBusinessExit";
import { appStates, setAppState } from "../../redux/features/appStateSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { Notification } from "./Notification";
import { disconnectWithServer } from "../../sockets";
import { resetMarkerCount } from "../../redux/features/coverageSlice";
import { setPlanSelected } from "../../redux/features/planStateSlice";
import { IRoles } from "../../utils";
export const appBarHeight = 64;

function ResponsiveAppBar({ children }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openConnectBusinessExit, setOpenConnectBusinessExit] = useState(false);
  const userState = useSelector((state) => state.userState);
  const appState = useSelector((state) => state.appState);
  const { type: roleType } = userState.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(0);
  const token = localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const [continueAction, setContinueAction] = useState(null);

  useEffect(() => {
    if (appState.currState === appStates.FINDACLEANER) {
      setActiveButton(null);
    }
    if (appState.currState === appStates.INITIALCOVERAGE) {
      setActiveButton(pages.findIndex((value) => value.name.individual === "Working Area"));
      dispatch(setAppState({ currState: appStates.HOME }));
    }
  }, [appState.currState]);

  if (!token || location.pathname === "/signin") {
    // Si no hay token, no renderizar el AppBar
    return null;
  }

  const handleResetNeededState = () => {
    resetConnectBusinessState();
    dispatch(resetMarkerCount());
    dispatch(
      setPlanSelected({
        upgradeFreeTrial: false,
      }),
    );
  };

  const handleSignOff = () => {
    localStorage.removeItem("token");
    dispatch(resetUser());
    disconnectWithServer();
    navigate("/signin");
  };

  const handleCoverageAreaRoute = (index) => {
    setActiveButton(index); // Establecer el botón activo
    navigate("/coverageareas");
    handleResetNeededState();
  };
  const handleServicesRoute = (index) => {
    setActiveButton(index);
    navigate("/services");
    handleResetNeededState();
  };
  const handleSettingAccountRoute = (index) => {
    setActiveButton(index);
    navigate("/settingsaccount");
  };
  const handleRequirementsRoute = (index) => {
    setActiveButton(index);
    navigate("/requirements");
    handleResetNeededState();
  };
  const handleAvalabilityRoute = (index) => {
    setActiveButton(index);
    navigate("/avalability");
    handleResetNeededState();
  };

  const handleHome = (index) => {
    setActiveButton(index);
    dispatch(
      setAppState({
        currState: appStates.HOME,
      }),
    );
    navigate("/");
    handleResetNeededState();
  };

  const handleExistDialog = (cb) => {
    setOpenConnectBusinessExit(true);
    setContinueAction(() => cb);
    handleResetNeededState();
  };

  const handlePlan = (index) => {
    setActiveButton(index);
    navigate("/plan-center");
    dispatch(
      setAppState({
        currState: appStates.PLANS,
      }),
    );
    handleResetNeededState();
  };

  const handleMyConnections = (index) => {
    setActiveButton(index);
    navigate("/my-connections");
    dispatch(
      setAppState({
        currState: appStates.MYCONNECTIONS,
      }),
    );
    handleResetNeededState();
  };

  let pages = [
    {
      name: { business: "Home", individual: "Home" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleHome(index);
          });
        } else {
          handleHome(index);
        }
      },
      authorization: [IRoles.Business, IRoles.Employee, IRoles.SuperAdmin],
    },
    {
      name: { business: "My Connections", individual: "My Connections" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleMyConnections(index);
          });
        } else {
          handleMyConnections(index);
        }
      },
      authorization: [IRoles.Employee, IRoles.Business],
    },
    {
      name: { business: "Services", individual: "Skills" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleServicesRoute(index);
          });
        } else {
          handleServicesRoute(index);
        }
      },
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { business: "Hours of Operation", individual: "Availability" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleAvalabilityRoute(index);
          });
        } else {
          handleAvalabilityRoute(index);
        }
      },
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { business: "Coverage Areas", individual: "Working Area" },
      callback: (index) => {
        console.log("current state: " + appStates.currState);
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleCoverageAreaRoute(index);
          });
        } else {
          handleCoverageAreaRoute(index);
        }
      },
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { individual: "Others", business: "Requirements" },
      callback: handleRequirementsRoute,
      authorization: [IRoles.Employee],
    },
    {
      name: { business: "Suscriptions" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handlePlan(index);
          });
        } else {
          handlePlan(index);
        }
      },
      authorization: [IRoles.Business],
    },
  ];

  const settings = [
    {
      name: "Account",
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleSettingAccountRoute(index);
          });
        } else {
          handleSettingAccountRoute(index);
        }
      },
    },
    {
      name: "Logout",
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleSignOff(index);
          });
        } else {
          handleSignOff(index);
        }
      },
    },
  ];

  const logoTitle = "Connect a Cleaner";
  const imagenIcon = assets.images.connectacleanericon;

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const activeButtonStyle = {
    my: 2,
    color: "white",
    display: "block",
    background: "linear-gradient(to right, #0066ff, #0099ff)",
  };

  return (
    <>
      {roleType === "employee" || roleType === "business" || roleType === "superadmin" ? (
        <>
          <AppBar
            position="fixed"
            top="0"
            sx={{
              width: "100%",
              height: `${appBarHeight}px`,
              ml: sizeConfigs.sidebar.width,
              boxShadow: "unset",
              color: colorConfigs.topbar.color,
              backgroundColor: colorConfigs.topbar.bg,
            }}
          >
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Avatar src={imagenIcon} sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}></Avatar>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  // href="/"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".05rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {logoTitle}
                </Typography>

                <Box sx={{ flexGrow: 1.5, display: { xs: "flex", md: "none" } }}>
                  <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: "block", md: "none" } }}
                  >
                    {pages
                      ?.filter((page) => page?.authorization?.includes(roleType))
                      ?.map((page, index) => (
                        <MenuItem
                          key={`${roleType}-${index}-xs}`}
                          onClick={() => {
                            page.callback(index);
                            handleCloseNavMenu();
                          }}
                          sx={{
                            color: activeButton === index ? "white" : "black",
                            display: "block",
                            background: activeButton === index ? "linear-gradient(to right, #04030f, #302ebb)" : "inherit",
                          }}
                        >
                          <Typography textAlign="center">{roleType === "employee" ? page.name.individual : page.name.business}</Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                </Box>
                <Avatar
                  src={imagenIcon}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    mr: { xs: -0.7, sm: 1 },
                    width: { xs: "35px" },
                    height: "auto",
                  }}
                ></Avatar>
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                    fontSize: {
                      sm: "1rem",
                      xs: ".8rem",
                    },
                    letterSpacing: {
                      sm: ".1rem",
                      xs: "0rem",
                    },
                  }}
                >
                  {logoTitle}
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  {/* {pages.map((page, index) => (
                    <Button
                      key={`${roleType}-${index}-md`}
                      onClick={() => {
                        page.callback(index);
                        handleCloseNavMenu();
                        // setActiveButton(index); // Establecer el botón activo
                      }}
                      sx={{
                        my: 2,
                        color: activeButton === index ? "white" : "black",
                        display: "block",
                        mr: 2,
                        background:
                          activeButton === index
                            ? "linear-gradient(to right, #04030f, #302ebb)"
                            : "inherit",
                      }}
                    >
                      {roleType === "employee"
                        ? page.name.individual
                        : page.name.business}
                    </Button>
                  ))} */}
                </Box>
                <Notification></Notification>
                <Typography
                  sx={{
                    marginRight: "1em",
                    display: { xs: "none", md: "flex" },
                  }}
                  display={{}}
                >{`${userState?.firstName?.split(" ")[0]} ${userState?.lastName?.slice(0, 1)}.`}</Typography>
                {userState?.firstName.length > 0 ? (
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="Remy Sharp" src={userState.avatar ?? "/static/images/avatar/2.jpg"} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      keepMounted
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting.name}
                          onClick={() => {
                            setting.callback();
                            setActiveButton(null);
                            handleCloseUserMenu();
                          }}
                        >
                          <Typography textAlign="center">{setting.name}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : (
                  <></>
                )}
              </Toolbar>
            </Container>
          </AppBar>
          <ConnectBusinessExit open={openConnectBusinessExit} setOpen={setOpenConnectBusinessExit} continueAction={continueAction}></ConnectBusinessExit>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ResponsiveAppBar;
