import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: "",
  email: "",
  birthDate: "",
  country: "",
  firstName: "",
  lastName: "",
  roleName: "",
  roleType: "",
};

const userSelectedStateSlice = createSlice({
  initialState,
  name: "userSelectedStateSlice",
  reducers: {
    updateUserSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetUserSelected: (state, action) => {
      return {
        uid: "",
        email: "",
        firstName: "",
        lastName: "",
        roleName: "",
        roleType: "",
      };
    },
  },
});

export const { updateUserSelected, resetUserSelected } =
  userSelectedStateSlice.actions;

export default userSelectedStateSlice.reducer;
