import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
  padding: 0,
  margin: 0,
};

// Definir las bibliotecas fuera del componente
const libraries = ["places"];

export const Map = ({ onLocationSelect }) => {
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 20.919545,
    lng: -89.618201,
  });
  const [address, setAddress] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setCurrentPosition({ lat: newLat, lng: newLng });
  };

  useEffect(() => {
    if (map && currentPosition) {
      map.panTo(currentPosition);
    }
  }, [map, currentPosition]);

  return (
    <LoadScript googleMapsApiKey="" libraries={libraries}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentPosition}
        zoom={15}
        onLoad={(map) => setMap(map)}
      >
        <Marker
          position={currentPosition}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    </LoadScript>
  );
};
