import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import countriesData from "../data/countriesList.json";
import "../assets/styles/plans.css";
import { Modal, Backdrop, Fade, IconButton, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { enterpriseContactInfo } from "../utils";
import { useErrorBoundary } from "react-error-boundary";
import { LoaderComponent } from "./Loader";
import { resetContactInfo, updateContactInfo } from "../redux/features/contacInfoStateSlice";
import CloseIcon from '@mui/icons-material/Close';
import { assets } from "../assets";
import { LoadScript, LoadScriptNext } from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
const classes = {
  style: {
    bgcolor: "background.paper",
    p: 4,
  },
  flag: {
    width: "70px",
    marginTop: "8px",
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  },
};

const countries = countriesData;

export const BusinessContact = ({ bannerHexColor, setContactUs }) => {
  const [isCodesOpen, setIsCodesOpen] = useState(false);

  const [open, setOpen] = useState(true);

  const token = localStorage.getItem("token");
  const { showBoundary } = useErrorBoundary();
  const [loader, setLoading] = React.useState(false);
  const [openSuccessSendContaUsInfo, setOpenSuccessSendContaUsInfo] = useState(false); // The new state that rendered the new modal that would show to user.

  const [address, setAddress] = useState("");

  const isSubmitted = false;

  const dispatch = useDispatch();
  const contactInfoState = useSelector((state) => state.contactInfoState);

  const handleClose = () => {
    setContactUs(false);
  };


  const handleCloseModal = () => {
    setContactUs(false);
    dispatch(resetContactInfo());
  }

  const handleCodeChange = (event) => {
    const countryCode = event?.target?.value;
    dispatch(updateContactInfo({ ["countryCode"]: countryCode}))
  };

  const handleCodesOpen = () => {
    setIsCodesOpen(true);
  };

  const handleCodesClose = () => {
    setIsCodesOpen(false);
  };

  const handleSendInformation = async (firstName, lastName, title, company, email, phone, location, message, countryCode, token) => {
    try {
      setOpen(false);
      setLoading(true);
      const res = await enterpriseContactInfo(firstName + " " + lastName, title, company, email, phone, location, message, countryCode, token);
    } catch (error) {
      showBoundary(error);
    } finally {
      setLoading(false);  
      setOpenSuccessSendContaUsInfo(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setTimeout(() => {
      console.log(
        `email: ${contactInfoState.email}, firstName: ${contactInfoState.firstName}, lastName: ${contactInfoState.lastName}, title: ${contactInfoState.title}, companyName: ${contactInfoState.companyName}, phone: ${contactInfoState.phone}, location: ${contactInfoState.location}, message: ${contactInfoState.message}, countryCode: ${contactInfoState.countryCode}`,
      );
      handleSendInformation(contactInfoState.firstName, contactInfoState.lastName, contactInfoState.title, contactInfoState.companyName, contactInfoState.email,  contactInfoState.phone, contactInfoState.location, contactInfoState.message, contactInfoState.countryCode, token);
      dispatch(resetContactInfo())
    }, 1000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateContactInfo({ [name]: value}))
  }

  return [
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box display="inline" justifyContent="center" alignItems="center" className="contact">
         
          <IconButton style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }} onClick={(e) => {
            e.stopPropagation();
            handleCloseModal();
            }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="#0f188d">
            CONTACT US
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <form onSubmit={handleSubmit}>
              <TextField fullWidth margin="dense" name="firstName" label="First Name"
                onChange={(e) => handleInputChange(e)}  
                size="small" required variant="outlined"
                value={contactInfoState.firstName}
              />
              <TextField fullWidth margin="dense" name="lastName" label="Last Name"
                onChange={(e) => handleInputChange(e)} 
                size="small"
                value={contactInfoState.lastName}
              />
              <TextField fullWidth margin="dense" name="title" label="Title" 
                onChange={(e) => handleInputChange(e)}
                size="small" 
                value={contactInfoState.title}
              />
              <TextField fullWidth margin="dense" name="email" label="Business Email" 
                onChange={(e) => handleInputChange(e)}
                size="small" type="email" required
                value={contactInfoState.email}
              />
              <TextField fullWidth margin="dense" name="companyName" label="Company Name" 
                onChange={(e) => handleInputChange(e)}
                size="small"
                value={contactInfoState.companyName} 
              />
  
              <Select
                size="small"
                value={contactInfoState.countryCode}
                required
                onChange={handleCodeChange}
                onOpen={handleCodesOpen}
                onClose={handleCodesClose}
                className="my-select"
                name="countryCode"
              >
                {countries.map(function (data) {
                  return (
                    <MenuItem key={data?.alpha3} value={data?.alpha3 + "-" + data?.numberPrefix}>
                      <ListItemIcon>
                        <img src={data?.file_url} alt="Countries" width="22" />
                      </ListItemIcon>
                      {isCodesOpen && <ListItemText primary={data?.name + " +" + data?.numberPrefix} />}
                    </MenuItem>
                  );
                })}
              </Select>
              <TextField size="small" name="phone" label="Phone Number" required type="number" variant="outlined" margin="dense" className="phone" 
                value={contactInfoState.phone}
                onChange={(e) => handleInputChange(e)}
              />  
              <LoadScriptNext
                googleMapsApiKey="AIzaSyAVA2xUJNYnk3pWEbP60u9Iz--eyc0iL9w"
                libraries={["places"]}
              >
                <Box sx={{ width: "100%" }}>
                  <PlacesAutocomplete
                    value={address !== "" ? address : contactInfoState.location}
                    onChange={(e) => {
                      setAddress(e);
                      dispatch(updateContactInfo({ "location": e}))
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                    }) => (
                      <div>
                        <TextField
                          required
                          {...getInputProps({
                            label: "Address",
                            className: "location-search-input",
                          })}
                          size="small"
                          style={{ width: "100%" }}
                          margin="dense" name="location" label="Business Location"
                        />
                        <div className="location-results">
                          {suggestions.slice(0,5).map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: "#a8a8a8", cursor: "pointer" }
                              : { backgroundColor: "#ffffff", cursor: "pointer" };
                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Box>
              </LoadScriptNext>

              <TextField
                fullWidth
                multiline
                maxRows={5}
                margin="dense"
                name="message"
                label="Please tell us how can we help you"
                onChange={(e) => handleInputChange(e)}
                size="medium" className="message"
                value={contactInfoState.message}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0f188d",
                  marginTop: 2,
                }}
                type="submit"
              >
                SUBMIT
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
      {<Modal
        open={openSuccessSendContaUsInfo}
        closeAfterTransition
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={ classes.modalStyle } className="successModal">
          <Box>
            <div>
              <Typography variant="h5" color="initial"  sx={{ fontWeight: "500" }}>THANK YOU!</Typography>
              <Typography variant="body2" sx={{ marginTop: "10px", marginBottom:"35px", fontWeight: "bold"}}>
                We've received your message. An agent from our team will contact you soon.
              </Typography>
              <Button variant="contained" onClick={(e) => { e.stopPropagation() ;handleCloseModal();}} >OK</Button>
            </div>
          </Box>
          <img src={assets.images.sendContactInfo} style={{ filter: `blur(5px)`}}></img> 
        </Box>
      </Modal>
      }
      <LoaderComponent open={loader} type="card" hideBackdrop={false}></LoaderComponent>
    </>,
  ];
};
