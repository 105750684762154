import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import {
  Typography,
  Box,
  Button,
  Grid
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { gateway } from "../gateway";
import { updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";
import { Loader } from "./common/Loader";
import { blobUrlToFile } from "./ImageUploader";

import { useErrorBoundary } from "react-error-boundary";
import { mapToCoverageModel } from "../utils";
import { LoaderComponent } from "./Loader";

export const ConnectEmployeeWelcome = ({
  username,
  scope = "employee",
  resetEntityState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();
  const signupEmployeeState = useSelector((state) => state.signupEmployeeState);
  const { firstName: firstNameEmployee } = signupEmployeeState;
  const signupBusinessState = useSelector((state) => state.signupBusinessState);
  const { firstName: firstNameBusiness, companyName } = signupBusinessState;
  const [wasCreated, setWasCreated] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    dispatch(resetEntityState());
    navigate("/signin");
  };

  const handleCreateBusiness = async () => {
    try {
      setOpen(true);

      const file = await blobUrlToFile(signupBusinessState.imageUrl);

      const formData = new FormData();
      Object.keys(signupBusinessState).forEach((key) => {
        const value = signupBusinessState[key];
        if (typeof value === "object" && value !== null) {
          // Convertir objetos a JSON
          if (key === "coverageAreas") {
            formData.append(key, JSON.stringify(mapToCoverageModel(value)));
          } else {
            formData.append(key, JSON.stringify(value));
          }
        } else {
          // Añadir valores normales
          formData.append(
            key,
            key === "email" ? value?.toLocaleLowerCase() : value
          );
        }
      });
      console.log("the file receved from blob url is: ", file);
      formData.append("imageFile", file);

      const response = await gateway.post("/users/business", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data.data;
      const { user, messageToUser } = data;
      console.log(user);
      dispatch(updateSignupEmployeeState({ messageToUser }));
      setWasCreated(true);
      setOpen(false);
    } catch (error) {
      const { name } = error;
      setOpen(false);
      if (name === "AxiosError") {
        let responseError = error?.response?.data;
        return showBoundary(responseError);
      }
      console.error(error);
      showBoundary(error);
    }
  };

  const handleCreateEmployee = async () => {
    try {
      // throw new Error("a test error");
      setOpen(true);

      const file = await blobUrlToFile(signupEmployeeState.imageUrl);

      const formData = new FormData();
      Object.keys(signupEmployeeState).forEach((key) => {
        const value = signupEmployeeState[key];
        if (typeof value === "object" && value !== null) {
          // Convertir objetos a JSON

          if (key === "coverageAreas") {
            formData.append(key, JSON.stringify(mapToCoverageModel(value)));
          } else {
            formData.append(key, JSON.stringify(value));
          }
        } else {
          // Añadir valores normales
          if (key === "email") {
            formData.append(key, value?.toLocaleLowerCase());
          } else {
            formData.append(key, value);
          }
        }
      });
      console.log("the file receved from blob url is: ", file);
      formData.append("imageFile", file);

      const response = await gateway.post("/users/individual", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data.data;
      const { user, messageToUser } = data;
      console.log(user);
      dispatch(updateSignupEmployeeState({ messageToUser }));
      setWasCreated(true);
      setOpen(false);
    } catch (error) {
      const { name } = error;
      setOpen(false);
      if (name === "AxiosError") {
        let responseError = error?.response?.data;
        return showBoundary(responseError);
      }
      console.error(error);
      showBoundary(error);
    }
  };

  useEffect(() => {
    try {
      if (scope === "employee") {
        handleCreateEmployee();
      } else if (scope === "business") {
        handleCreateBusiness();
      }
    } catch (error) {
      showBoundary(error);
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {wasCreated ? (
          <div key="welcomeDiv">
            {" "}
            <Confetti />
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    backgroundColor: "#233044",
                    color: "white",
                    textAlign: "center",
                    padding: "10px",
                    borderRadius: "10px",
                    wordWrap: "break-word",
                    maxWidth: "100%",
                  }}
                >
                  {scope === "employee" ? (
                    <>
                      {`Hi ${
                        scope === "employee"
                          ? firstNameEmployee
                          : firstNameBusiness
                      }, congratulations. You’re now part of Connect a Cleaner!`}
                      <br />
                      {"Please check your email to activate your account."}
                    </>
                  ) : (
                    <>
                      {`Hi ${companyName}, congratulations.`}
                      <br />
                      {"Please check your email to activate your account."}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  sx={{
                    borderRadius: "10px",
                    height: "40px",
                    width: "100%", // Utiliza porcentajes en lugar de valores fijos
                    backgroundColor: "#233044",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#224f91",
                      color: "#ffffff",
                    },
                  }}
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
                >
                  Return Home
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <></>
        )}
      </Box>
      <LoaderComponent open={open} type="spinner"></LoaderComponent>
    </div>
  );
};
