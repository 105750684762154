import Axios from "axios";
import { useErrorBoundary } from "react-error-boundary";

export class ResponseError extends Error {
  constructor(name, code, message) {
    super(message);
    this.name = name;
    this.code = code;
  }
}
const tokenLocal = localStorage.getItem("token");
const axiosInstance = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL ?? "http://localhost:3001"}/${process.env.REACT_APP_API_VERSION}`,
  headers: {
    Authorization: ``, // Configura el encabezado "Authorization"
  },
  timeout: 30000, // Tiempo de espera en milisegundos (ejemplo: 10000 ms = 10 segundos)
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      throw new ResponseError("ERR_CONNECTION_REFUSED", 503, "Service is unavailabe, try later");
    }
    if(tokenLocal && error?.response?.data?.message === "Token is not valid or expired."){
      localStorage.removeItem("token");
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  },
);

export const gateway = axiosInstance;
