import { createSlice } from "@reduxjs/toolkit";

export const appStates = {
  HOME: "home",
  SIGNIN: "signin",
  SIGNUP: "signup",
  MYCONNECTIONS: "myConnections",
  INITIALCOVERAGE: "initialCoverage",
  PLANS: "plans",
  PLAYGROUND: "playground",
  FIND: "find"
};

const initialState = {
  prevPathState: "",
  currPathState: "",
  currState: appStates.HOME,
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setAppState } = appStateSlice.actions;

export default appStateSlice.reducer;
