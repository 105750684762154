import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "../redux/features/formStateSlice";

import { MapPredictive } from "./mapPredictive";
import { nextViewConnectBusinessState, previousViewConnectBusinessState, updateConnectBusinessState } from "../redux/features/connectBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { MapPredictiveAlgorithm } from "./mapPredictiveAlgorithm";
import MyLocation from "@mui/icons-material/MyLocation";

export const ConnectBusinessAddress = ({ updateComponentState, componentState }) => {
  const currPage = "/signupEmployee";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const connectBusinessState = useSelector((state) => state.connectBusinessState);
  const { currentView } = connectBusinessState;
  const [isFormValid, setIsFormValid] = useState(false);

  const [openLocationError, setOpenLocationError] = useState(false);
  const [locationErrorMessage, setLocationErrorMessage] = useState("");

  const handleLocationSelect = (city, state, zipCode, country, address) => {
    console.log(address, state, city, zipCode, country);
    dispatch(updateConnectBusinessState({ address, state, city, zipCode, country }));
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateConnectBusinessState({ [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);
    const formData = new FormData(event.currentTarget);

    const userData = {
      phone: formData.get("phone"),
      age: formData.get("age"),
      // address: formData.get("address"),
    };
    dispatch(updateConnectBusinessState(userData));
    dispatch(nextViewConnectBusinessState(userData));
    setIsLoading(false);
    setOpen(false);
    setIsFormValid(true);
  };

  const handleLocationError = (message) => {
    setLocationErrorMessage(message);
    setOpenLocationError(true);
  };

  const handleCloseErrorMessage = () => {
    setLocationErrorMessage("");
    setOpenLocationError(false);
  }

  const useMyLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.longitude && position.coords.latitude) {
          dispatch(
            updateComponentState({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }),
          );
          dispatch(nextViewConnectBusinessState());
        } else {
          handleLocationError("We could not obtain your location.");
        }
      });
    } else {
      handleLocationError("Geolocation not available.");
    }
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
        sx={{
          marginTop: { xs: -10, md: -10 },
          width: { xs: "100%", md: 600 },
          height: { xs: 400, md: 300 },
        }}
      >
        <Grid container spacing={2}>
          <Typography
            variant="body"
            style={{
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              // color: "white",
              // background: "rgba(35, 48, 68, 1)",
              borderRadius: "10px",
              textAlign: "center",
              width: "100%",
              padding: "10px",
              marginLeft: "15px",
              marginTop: "20px",
            }}
          >
            "Find your cleaner! Share the location, and connect with the nearest expert for an optimal match "
          </Typography>
          <Grid item xs={12}>
            <MapPredictiveAlgorithm onLocationSelect={handleLocationSelect} updateComponentState={updateComponentState} componentState={componentState} isMapPickerHidden={false} />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={connectBusinessState.country}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="State"
              name="state"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={connectBusinessState.state}
            />
          </Grid> */}
          {/* 
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="City"
              name="city"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={connectBusinessState.city}
            />
          </Grid> */}

          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              label="Zip Code"
              name="zipCode"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              value={connectBusinessState.zipCode}
            />
          </Grid> */}
        </Grid>
        <Box sx={{ mt: "20px" }}>
          <SignUpNextButton nextViewEntityState={nextViewConnectBusinessState} previousViewEntityState={previousViewConnectBusinessState}></SignUpNextButton>
          <Button onClick={useMyLocation} startIcon={<MyLocation></MyLocation>} sx={{ mt: ".5em" }}>
            Use my location
          </Button>
        </Box>
      </Box>
      <Snackbar open={openLocationError} autoHideDuration={3000} onClose={handleCloseErrorMessage} message={locationErrorMessage} sx={{ color: "white" }}></Snackbar>
    </div>
  );
};
