import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  lat: 0,
  lng: 0,
  city: "",
  state: "",
  zipcode: "",
  date: "",
  startTime: "",
  endTime: "",
  services: [],
  requirements: [],
  currentView: 0,
  totalViews: 3,
};

export const connectACleanerForJobStateSlice = createSlice({
  name: "connectACleanerForJobStateSlice",
  initialState,
  reducers: {
    updateState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }, // state.totalViews - 1 is equal to 6 because we start counting in 0
    nextViewState: (state, action) => {
      return {
        ...state,
        currentView:
          state.currentView >= state.totalViews - 1 ? 0 : state.currentView + 1,
      };
    },
    previousViewState: (state, action) => {
      return {
        ...state,
        currentView: state.currentView <= 0 ? 0 : state.currentView - 1,
      };
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const { updateState, resetState, nextViewState, previousViewState } =
  connectACleanerForJobStateSlice.actions;
export default connectACleanerForJobStateSlice.reducer;
