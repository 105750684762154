import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updateForm } from "../redux/features/formStateSlice";

export function BasicDatePicker() {
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();

  const handleDateChange = (newValue) => {
    console.log(newValue.toISOString());
    dispatch(updateForm({ birthDate: newValue.toISOString() }));
    setValue(newValue.toISOString());
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Birth Date"
        value={value}
        disableFuture
        sx={{
          width: "100%",
          mt: 1,
          mb: 1,
        }}
        onChange={(newValue) => handleDateChange(newValue)}
      >
        {(params) => (
          <TextField
            {...params}
            variant="standard" // Personaliza según tus necesidades
            margin="normal" // Personaliza según tus necesidades
          />
        )}
      </DatePicker>
    </LocalizationProvider>
  );
}
