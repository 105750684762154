import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Divider,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import HouseIcon from "@mui/icons-material/House";
import { useErrorBoundary } from "react-error-boundary";
import { LoadingCard } from "./common/LoadingCard";
import { getMyAvailability, getMyServices, processSchedule } from "../utils";
import { assets } from "../assets";
import { AvailabilityTable } from "./IndividualAvailabilityTable";

export const IndividualAvailability = ({ title, type }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const [myAvailability, setMyAvailability] = useState([]);

  useEffect(() => {
    setLoading(true);
    getMyAvailability(token)
      .then((res) => {
        const processedScheduled = processSchedule(res);
        console.log("the availability processed: ", processedScheduled);
        setMyAvailability(processedScheduled);
      })
      .catch((err) => showBoundary(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, [token]); // added token dependency

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
        margin: "0",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ marginTop: "1em", m: "0", mb: "1em" }}
      >
        <Avatar   sx={{ marginTop: '1em' }}
        src={assets.images.availavilityIcon}></Avatar>
        <Typography variant="subtitle1" marginLeft={2} sx={{ marginTop: '1em' }}>
          {title}
        </Typography>
      </Box>
      <Divider sx={{ width: "80%" }} />

      {!loading ? (
        <AvailabilityTable schedule={myAvailability} />
      ) : (
        <LoadingCard />
      )}
    </Box>
  );
};
