import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: "",
};

const superAdminSearchStateSlice = createSlice({
  name: "superAdminSearch",
  initialState,
  reducers: {
    setSuperAdminSearchState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSuperAdminSearchState } = superAdminSearchStateSlice.actions;
export default superAdminSearchStateSlice.reducer;
