import { Box, Toolbar } from "@mui/material";
import React from "react";
import Topbar from "../common/Topbar";
import { sizeConfigs } from "../../config/sizeConfig";
import { colorConfigs } from "../../config/colorConfigs";
import { assets } from "../../assets";

export default function SigninupLayout({ children }) {
  const img = assets.images.connectacleanerbanner;
  return (
    <div>
      <Box
        component="div"
        sx={{ display: "flex", margin: 0, padding: 0 }}
        // height="100vh"
        width="100wh"
        // minHeight="100wh"
        // maxHeight="100vh"
        position="0"
        //overflow="hidden"
        key="signinuplayout"
      >
        <Box
          component="div"
          key="dgfd"
          sx={{
            width: "30%",
            flexShrink: 0,
            margin: 0,
            padding: "0",
          }}
          display={{
            xs: "none",
            md: "flex",
          }}
        >
          <img
            alt="Cleaning"
            src={img}
            height="100%"
            width="100%"
            style={{
              objectFit: "cover",
              filter: "blur(5px)",
              padding: 0,
              margin: 0,
            }}
          />
        </Box>

        <Box
          component="main"
          key="componentChildSection"
          sx={{
            flexGrow: 1,
            p: 1,
            width: `calc(100% -${sizeConfigs.sidebar.width})`,
            minHeight: "100vh",
            backgroundColor: colorConfigs.mainBg,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Box>
    </div>
  );
}
