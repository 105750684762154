import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admins: 0,
  employees: 0,
  clients: 0,
};

const businessProfileStateSlice = createSlice({
  initialState,
  name: "businessProfileStateSlice",
  reducers: {
    updateBusinessProfile: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateBusinessProfile } = businessProfileStateSlice.actions;
export default businessProfileStateSlice.reducer;
