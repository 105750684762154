import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BackPage from "./common/BackPage";
import { useDispatch } from "react-redux";
import { resetForm, updateForm } from "../redux/features/formStateSlice";

export const EmailConfirmation = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 1s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        <Box sx={{ alignSelf: "self-start", mb: "20px" }}>
          <BackPage onClick={() => dispatch(resetForm())} />
        </Box>

        <Card sx={{ backgroundColor: "#2196f3", color: "#fff" }}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Confirmación de Correo Electrónico
            </Typography>
            <Typography variant="body1" paragraph>
              Gracias por registrarte. Hemos enviado un correo electrónico a tu
              dirección de correo electrónico con instrucciones para confirmar
              tu cuenta. Por favor, verifica tu bandeja de entrada y sigue los
              pasos para completar la confirmación.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};
