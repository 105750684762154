import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StatePicker } from "./statePicker";
import { updateForm } from "../redux/features/formStateSlice";
import { nextViewSignupBusinessState, previousViewSignupBusinessState, updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";
import { MapComponent } from "./coverageAreaMap";
import { SignUpNextButton } from "./signupNextButton";
import { SaveButton } from "./saveButton";
import { EBenefitTypes, createOrUpdateMyLocation, getMyLocation } from "../utils";
import { LoaderComponent } from "./Loader";
import { BaseDialog } from "./common/BaseDialog";
import { appStates, setAppState } from "../redux/features/appStateSlice";
import { resetMarkerCount } from "../redux/features/coverageSlice";
import { ArrowForward, Close } from "@mui/icons-material";

const styles = {
  titleSx: {
    fontWeight: "bold",
  },
  actionSx: {
    justifyContent: "center",
  },
  btnCloseSx: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
  btnAcceptSx: {
    backgroundColor: "#3c7cde",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
};

export const ConnectBusinessCoverageArea = ({ updateEntityState, entityState }) => {
  const userState = useSelector((state) => state.userState);
  const currPage = "/connect";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const token = localStorage.getItem("token");
  const [openInsufficientBenefits, setOpenInsufficientBenefits] = React.useState(false);

  const markerState = useSelector((state) => state.markerState);

  const benefitType = EBenefitTypes.AREASCOVERAGES;

  const usersBenefits =
    userState?.benefits?.find((benefit) => {
      if (benefit?.type === benefitType) return true;
      return false;
    }) ?? null;

  useEffect(() => {
    console.log(usersBenefits);
  }, [userState]);

  useEffect(() => {
    dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const handleResetParentStates = () => {
    setCancel(false);
    setChanges(false);
  };

  const handleOpenInsufficientBenefitsDialog = () => {
    setOpenInsufficientBenefits(true);
  };

  const handleCbActionFactory = () => {
    if (usersBenefits?.UserBenefits?.usageCount < usersBenefits?.config?.limitedUsage || markerState.markerCount <= usersBenefits?.config?.limitedUsage) {
      return handleSubmit;
    } else {
      return handleOpenInsufficientBenefitsDialog;
    }
  };

  const handleSubmit = async () => {
    try {
      setOpen(true);
      await createOrUpdateMyLocation(token, data, usersBenefits?.UserBenefits?.id);
      handleResetParentStates();
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  const actions = [
    {
      label: "Close",
      onClick: () => {
        setOpenInsufficientBenefits(false);
      },
      icon: <Close></Close>,
      style: styles.btnCloseSx,
    },
    {
      label: "Go to plans",
      onClick: () => {
        navigate("/plan-center");
        dispatch(
          setAppState({
            currState: appStates.PLANS,
          }),
        );
        dispatch(resetMarkerCount());
      },
      icon: <ArrowForward></ArrowForward>,
      style: styles.btnAcceptSx,
    },
  ];

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: "15%", md: 9 },
          width: { xs: "100%", md: 700 },
          // marginLeft: { xs: 0, md: 50 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* TODO: Coverage area */}
          <MapComponent
            updateEntityState={updateEntityState}
            entityState={entityState}
            setChanges={setChanges}
            setLoading={setOpen}
            refresh={cancel}
            refreshCb={handleResetParentStates}
            setData={setData}
          ></MapComponent>
          <Grid item>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <SaveButton
                  disabled={!changes}
                  setCancel={setCancel}
                  onSubmit={() => {
                    const cb = handleCbActionFactory();
                    cb();
                  }}
                  refresh={true}
                ></SaveButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <BaseDialog
        openState={openInsufficientBenefits}
        setOpenState={setOpenInsufficientBenefits}
        title="Benefits Limit Reached!"
        titleSx={styles.titleSx}
        content="You don't have the required benefits or you've exceeded the usage limit to perform this action. Please review your plan for details or contact support for assistance."
        actionSx={styles.actionSx}
        actions={actions}
      ></BaseDialog>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
