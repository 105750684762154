import React, { useEffect, useState, useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { gateway } from "../gateway";
import { useDispatch, useSelector } from "react-redux";
import { createConnectSubscription } from "../utils";
import { useErrorBoundary } from "react-error-boundary";
import { updateUser } from "../redux/features/userStateSlice";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

export const PlanPayment = ({ setSubscriptionState = () => {} }) => {
  const planState = useSelector((state) => state?.planState);
  const planSelected = planState?.planSelected ?? null;

  const [currentPlanId, setCurrentPlanId] = useState(planSelected?.id);
  const { showBoundary } = useErrorBoundary();
  // const createSubscription = useCallback(, [currentPlanId]);
  const initialOptions = {
    "client-id": "AcW_ZKtb7U2mNntJjdg6MnLOXXYCLIifHBgSqvNPTYQBxcQzt3CzHqSLIzip0B3xAB9zZLvs9amPjQhE",
    "enable-funding": "card",
    "disable-funding": "paylater",
    "data-sdk-integration-source": "integrationbuilder_sc",
    vault: "true",
    intent: "subscription",
  };
  const tokenLocal = localStorage.getItem("token");

  const [message, setMessage] = useState("");

  useEffect(() => {
    setCurrentPlanId(planState.planSelected.id);
  }, [planState]);

  const handleCreateConnectSubscription = useCallback(
    async (currentSubscriptionId) => {
      try {
        console.log(planState);
        const connectSubs = await createConnectSubscription(
          currentSubscriptionId, //this is provided by paypalweb
          currentPlanId,
          planState?.planSelected?.price?.amount,
          planState?.planSelected?.discount ?? 0,
          Number(planState?.planSelected?.price?.amount) - Number(planState?.planSelected?.discount ?? 0),
          tokenLocal,
        );
        return connectSubs;
      } catch (error) {
        showBoundary(error);
      }
    },
    [planState],
  );

  return (
    <div className="PlanPaymentPaypal">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          key={currentPlanId}
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          //Here we request that our back make the request to paypal web
          //Our backend is just a gateway for handle the request. If this is well
          //On aprove will handle a tell our backend that create the subscription schema for this user
          createSubscription={async () => {
            try {
              const res = await gateway.post(
                "/paypal/subscriptions",
                {
                  plan_id: currentPlanId,
                },
                {
                  headers: {
                    Authorization: `${tokenLocal}`,
                  },
                },
              );
              const data = res?.data?.data;
              if (data?.id) {
                // setMessage(`Successful subscription...`);
                return data.id;
              } else {
                console.error({ callback: "createSubscription", serverResponse: data }, JSON.stringify(data, null, 2));
                const errorDetail = data?.details?.[0];

                setSubscriptionState({
                  state: "error",
                  data: {
                    message: `Could not initiate PayPal Subscription...${errorDetail?.issue ? `\n\nIssue: ${errorDetail.issue}` : ""}${
                      errorDetail?.description ? `\nDescription: ${errorDetail.description}` : ""
                    }${data?.message ? `\nMessage: ${data.message}` : ""}${data?.debug_id ? `\nDebug ID: ${data.debug_id}` : ""}`,
                  },
                });
              }
            } catch (error) {
              console.error(error);
              setSubscriptionState({
                state: "error",
                data: {
                  message: `Could not initiate PayPal Subscription...${error}`,
                },
              });
            }
          }}
          onApprove={async (data, actions) => {
            /*
              No need to activate manually since SUBSCRIBE_NOW is being used.
              Learn how to handle other user actions from our docs:
              https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
            */

            console.log(data);
            if (data.orderID) {
              // If paypal web response sucessfully so we need to update our platfor to create the connection.
              const subsinfo = await handleCreateConnectSubscription(data?.subscriptionID);

              setSubscriptionState({
                state: "success",
                data: {
                  message: `You have successfully subscribed to the plan. Your subscription id is: ${data?.subscriptionID}`,
                  subscriptionId: data?.subscriptionID,
                  orderId: data?.orderID,
                  subscription: subsinfo?.subscription?.id
                    ? {
                        id: subsinfo?.subscription?.id,
                        paypalSubscriptionId: subsinfo?.subscription?.paypalId,
                        planName: subsinfo?.subscription?.plan?.product?.name,
                        planId: subsinfo?.subscription?.plan?.id,
                        planPaypalId: subsinfo?.subscription?.plan?.paypalPlanId,
                        hexColor: subsinfo?.subscription?.plan?.product?.hexColor,
                        createdAt: subsinfo?.subscription?.createdAt,
                      }
                    : null,

                  benefits: subsinfo?.benefits ?? [],
                },
              });
            } else {
              setSubscriptionState({
                state: "error",
                data: {
                  message: `Failed to activate the subscription: ${data.subscriptionID}`,
                  subscriptionID: data?.subscriptionID,
                  orderId: data?.orderID,
                },
              });
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
};
