import * as React from "react";
import List from "@mui/material/List";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useState } from "react";
import { gateway } from "../gateway";
import { updateBusinessProfile } from "../redux/features/businessProfileStateSlice";
import { UserRecord } from "./UserRecord";
import { socket } from "../sockets";

export const EmployeesListTeam = () => {
  const [employeesList, setEmployeesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const tokenLocal = localStorage.getItem("token");

  useState(async () => {
    try {
      const response = await gateway.get("/users/employees", {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });

      if (response.status < 400) {
        const employees = response?.data?.data?.users;
        dispatch(updateBusinessProfile({ employees: employees.length }));
        setEmployeesList(employees);
      }

      //Socket in order to
      socket.on("deletedUserFromCompany", ({ userUid }) => {
        // Actualiza la lista de empleados eliminando el empleado con el ID correspondiente
        console.log("Listening deletedUserFromCompany event from Server-Side");
        setEmployeesList((prevEmployees) =>
          prevEmployees.filter((employee) => employee.uid !== userUid)
        );
      });

      socket.on("newEmployeeToCompany", (user) => {
        // Actualiza la lista de empleados eliminando el empleado con el ID correspondiente
        console.log("Listening newUserToCompany event from Server-Side");
        console.log(user);
        const data = [...employeesList];
        setEmployeesList((prevEmployees) => {
          console.log("prevEmployees: ", prevEmployees);
          console.log("newUser", user);

          return [...prevEmployees, user];
        });
        console.log("setEmployeesList to : ", data);
      });
    } catch (error) {
      console.log("Error when tried to find my team");
    }
  }, []);

  return (
    <Box
      sx={{
        // bgcolor: "background.paper",
        width: "100%",
        // height: "50%",
        // marginTop: "16px",
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        borderRadius: "16px",
        boxShadow: "unset",
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap", // Esto permite que los elementos se envuelvan
          maxWidth: "auto",
          padding: "16px",
          width: "100%",
        }}
      >
        {employeesList.map((employee, index) => (
          <UserRecord
            user={employee}
            index={index}
            roleScope="employees"
          ></UserRecord>
        ))}
      </List>
    </Box>
  );
};
