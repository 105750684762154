import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "../../redux/features/appStateSlice";
import { useNavigate } from "react-router-dom";
export default function BackPage({ currPage, onClick }) {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appState);
  const tempPrevPath = appState.prevPathState;
  const navigate = useNavigate();
  useEffect(() => {}, [appState.prevPathState]);
  return (
    <IconButton
      onClick={
        typeof onClick === "function"
          ? onClick
          : () => {
              dispatch(
                setAppState({
                  prevPathState: currPage,
                  currPathState: tempPrevPath,
                }),
              );
              navigate(tempPrevPath);
            }
      }
    >
      <ChevronLeftOutlinedIcon></ChevronLeftOutlinedIcon>
    </IconButton>
  );
}
