import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Button, Typography } from "@mui/material";
import { EmployeeBasicCard } from "./employeeBasicCard";
import { useSelector, useDispatch } from "react-redux";
import { resetConnectBusinessState } from "../redux/features/connectBusinessStateSlice";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { QuickBadge } from "./QuickBadge";
import { appStates, setAppState } from "../redux/features/appStateSlice";

export const ConnectEmployeeTres = () => {
  // ... lógica del componente ...
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectBusinessState = useSelector(
    (state) => state.connectBusinessState
  );
  const {
    serviceType,
    residentialServices,
    commercialServices,
    bestChoices,
    requirements,
  } = connectBusinessState;

  const [employees, setEmployees] = useState([]);

  const handleFilterBestChoices = () => {
    const services =
      serviceType === "commercial" ? commercialServices : residentialServices;
    const serviceIds = services.map((service) => service.id);
    const requirementIds = requirements.map((requirement) => requirement.id);

    const filteredBestChoices = bestChoices
      .filter((bestChoice) => {
        const hasServices = serviceIds.length > 0;
        const hasRequirements = requirementIds.length > 0;

        const serviceMatch = hasServices
          ? bestChoice.workTypeServices.some((workTypeService) =>
              serviceIds.includes(workTypeService.id)
            )
          : true; // Si no hay servicios seleccionados, no filtramos por ellos

        const requirementsMatch = hasRequirements
          ? requirementIds.every((requirementId) =>
              bestChoice.requirements.some(
                (requirement) => requirement.id === requirementId
              )
            )
          : true; // Si no hay requisitos seleccionados, no filtramos por ellos

        return serviceMatch && requirementsMatch;
      })
      .sort(
        (a, b) =>
          a.distances?.driving?.duration - b.distances?.driving?.duration
      );

    console.log("Filtered Best Choices: ", filteredBestChoices);
    setEmployees(filteredBestChoices);
  };

  useEffect(() => {
    handleFilterBestChoices();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % employees.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + employees.length) % employees.length
    );
  };

  useEffect(() => {}, [employees]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "center",
        marginTop: { xs: 9, sm: 8, md: 8 },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          color: "White",
          background: "#233044",
          borderRadius: "10px",
          textAlign: "center",
          padding: "10px",
          boxShadow: 3,
        }}
      >
        Cleaners near you
      </Typography>
      <Grid
        container
        spacing={isSmallScreen ? 2 : 4}
        direction={isSmallScreen ? "column" : "row"}
        justifyContent="center"
        sx={{ width: "100%", marginTop: 2 }}
      >
        {employees.map((employee, index) => (
          <Grid
            item
            key={index}
            sx={{
              transform: index === currentIndex ? "scale(1)" : "none",
              transition: "transform 0.5s ease-in-out",
              ":hover": { transform: "scale(1.1)" },
            }}
          >
            <EmployeeBasicCard
              children={index === 0 ? <QuickBadge></QuickBadge> : <></>}
              {...employee}
              distance={employee.distances}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          width: "100%",
          margin: "auto",
        }}
      >
        <Button
          onClick={() => {
            dispatch(resetConnectBusinessState());
            dispatch(
              setAppState({
                currState: appStates.HOME,
              })
            );
            navigate("/");
          }}
          sx={{
            backgroundColor: "#233044",
            borderRadius: "10px",
            ":hover": {
              backgroundColor: "#1565c0",
            },
            color: "white",
            padding: "6px 12px",
            minWidth: "120px",
          }}
        >
          CANCEL
        </Button>
      </Box>
    </Box>
  );
};
