import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserQuickCreation } from "../../components/UserQuickCreation";
import { EmployeesListTeam } from "../../components/EmployeeList";
export default function EmployeesPageLayout({ nameCompany }) {
  const company = useSelector((state) => state.userState.company);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <>
      <Box
        minHeight="90vh"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // backgroundColor: "blue",
          height: "90vh",
          width: "100%",
          padding: 1,
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 0.5s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", padding: "0", margin: "0" }}
        >
          <UserQuickCreation Scope={"employees"}></UserQuickCreation>
          <EmployeesListTeam
            sx={{ padding: "0", margin: "0" }}
          ></EmployeesListTeam>
        </Box>
      </Box>
    </>
  );
}
