import React, { useEffect, useState } from "react";
import { Box, Grid, CssBaseline } from "@mui/material";
import { IndividualServices } from "../IndividualServices";
import { IndividualAvailability } from "../IndividualAvailability";
import { IndividualLocationMap } from "../IndividualLocationMap";
import { HelpFloatingButton } from "../helpFloatingButton";
import { IndividualContactInfo } from "../individualContactInfo";
import { IndividualConnections } from "../individualConnections";

export const IndividualDashboardLayout = () => {
  return (
    <>
      <CssBaseline></CssBaseline>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          mt: "5em",
          // background: "pink",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start", // Cambiado de 'center' a 'stretch'
            // backgroundColor: "blue",
            width: { md: "70%", xs: "90%" },
            height: "80%",
          }}
        >
           <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "red",
              padding: "0",
              margin: "0",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualContactInfo></IndividualContactInfo>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "red",
              padding: "0",
              margin: "0",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualConnections></IndividualConnections>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "red",
              padding: "0",
              margin: "0",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualServices
              title={"Residential Skills"}
              type={"residential"}
            ></IndividualServices>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%",
              height: "100%",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualServices
              title={"Commercial Skills"}
              type={"commercial"}
            ></IndividualServices>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualAvailability
              title={"Availability"}
            ></IndividualAvailability>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            xs={12}
            md={6}
          >
            <IndividualLocationMap title={"Coverage Area"}>
              {" "}
            </IndividualLocationMap>
          </Grid>
         
         
        </Grid>
        <HelpFloatingButton bgColor="#1d4999"></HelpFloatingButton>
      </Box>
    </>
  );
};
