import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience, {
  CheckExperienceCommercial,
} from "./checkboxExperienceCommercial";
import CheckAvailability from "./checkboxAvailability";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { TimeSelectorCommercial } from "./checkBoxAvailabilityCommercial";
import { gateway } from "../gateway";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { Schedule } from "./schedule";
import { updateUser } from "../redux/features/userStateSlice";
import { SaveButton } from "./saveButton";
import { useSelector } from "react-redux";
import { ScheduleSignup } from "./scheduleSignupBusiness";

export const HoursOfOperationSigup = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateSignupEmployeeState,
  checkedState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currPage = "/signupEmployee";
  const commertialType = "commercial"; //The type that will be put on the
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [open, setOpen] = useState(false);
  const tokenLocal = localStorage.getItem("token");
  const [availability, setMyAvailability] = useState();
  const userState = useSelector((state) => state.userState);

  // alert("this is the best one");
  // alert(JSON.stringify(checkedState));

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: 0, md: 0},
          width: { xs: 350, md: 600 },
          ml: { xs: 0, md: -12},

        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            color: "White",
            background: "#233044",
            borderRadius: "3px",
            textAlign: "center",
            width: "100%", // Full width
            padding: "10px",
            marginBottom: "1%",
          }}
        >
          Hours of Operation
        </Typography>
        <ScheduleSignup
          updateComponentState={updateComponentState}
          setChanges={setChanges}
          checkedState={checkedState}
        ></ScheduleSignup>
        <SignUpNextButton
          // type="submit"
          sx={{
            marginTop: "20px",
          }}
          nextViewEntityState={nextViewComponentState}
          previousViewEntityState={previousViewEntityState}
        ></SignUpNextButton>
      </Box>
    </div>
  );
};
