import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useDispatch,useSelector } from 'react-redux';
import { updateSignupBusinessState } from '../redux/features/signupBusinessStateSlice';
import { assets } from '../assets';

const images = [
  {
    url: `${assets.images.corporation}`,
    title: 'Corporation',
    width: '33%',
  },
  {
    url: `${assets.images.sole}`,
    title: 'LLC - Sole Member',
    width: '33%',
  },
  {
    url: `${assets.images.partner}`,
    title: 'LLC Partnership',
    width: '33%',
  },
];

const ImageButton = styled(ButtonBase)(({ theme,selected,title}) => ({
  position: 'relative',
  height: 400,
  margin: 1, // Agrega un margen del 2% a todos los lados
  opacity: selected === title ? 1 : 0.4, // Cambia la opacidad según si está seleccionado

  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 150,
    marginRight:'-4px',
    marginBottom: '20px', // Agrega un margen inferior en pantallas pequeñas
  },
  '&:hover, &.Mui-focusVisible': {
    // zIndex: 1,
    '& .MuiImageBackdrop-root': {
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 50%',
  borderRadius: '4px', // Opcional: si deseas bordes redondeados
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  // marginRight:"5px",
  borderRadius:"5px",
  backgroundColor: theme.palette.common.black,
  opacity: 0.25,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));



export default function ButtonBaseServices({setSelectedParent}) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const signupBusinessState = useSelector((state) => {return state.signupBusinessState});


  useEffect(() => {
    if (signupBusinessState.classifyYourSelf != null) {
      setSelected(signupBusinessState.classifyYourSelf);
    }
  }, [signupBusinessState.classifyYourSelf]);

  const handleOnClick = (option) => () => {
    setSelectedParent(true);
    if (selected === option) {
      setSelected(null); // Deselecciona si se vuelve a clickear la misma opción
      
    } else {
      setSelected(option); // Selecciona la nueva opción
      dispatch(updateSignupBusinessState({ classifyYourSelf: option }));
    }
  };
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: { xs: 290, md: 650 } }}>
      {images.map((image) => (
        <ImageButton
        focusRipple
        key={image.title}
        selected={selected}
        title={image.title}
        style={{ width: image.width }}
        onClick={handleOnClick(image.title)}
    
        > 
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
      ))}
    </Box>
  );
}
