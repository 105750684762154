import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceType: "",
  address: "",
  state: "",
  country: "",
  city: "",
  zipCode: "",
  expandMiles: false,
  residentialServices: [],
  commercialServices: [],
  requirements: [],
  bestChoices: [],
  currentView: 0,
  totalViews: 8,
};

export const connectBusinessStateSlice = createSlice({
  name: "connectBusinesstateSlice",
  initialState,
  reducers: {
    updateConnectBusinessState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }, // state.totalViews - 1 is equal to 6 because we start counting in 0
    nextViewConnectBusinessState: (state, action) => {
      return {
        ...state,
        currentView:
          state.currentView >= state.totalViews - 1 ? 0 : state.currentView + 1,
      };
    },
    previousViewConnectBusinessState: (state, action) => {
      return {
        ...state,
        currentView: state.currentView <= 0 ? 0 : state.currentView - 1,
      };
    },
    resetConnectBusinessState: () => {
      return initialState;
    },
  },
});

export const {
  updateConnectBusinessState,
  resetConnectBusinessState,
  nextViewConnectBusinessState,
  previousViewConnectBusinessState,
} = connectBusinessStateSlice.actions;
export default connectBusinessStateSlice.reducer;
