import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nextViewConnectBusinessState,
  previousViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import BackPage from "./common/BackPage";
import { MapPredictive } from "./mapPredictive";
import { SignUpNextButton } from "./signupNextButton";
import { appStates, setAppState } from "../redux/features/appStateSlice";

export const ConnectIndividualWelcomeMessage = ({
  username,
  scope = "employee",
  resetEntityState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signupEmployeeState = useSelector((state) => state.signupEmployeeState);
  const signupBusinessState = useSelector((state) => state.signupBusinessState);
  const { companyName: companyName } = signupBusinessState;
  const userState = useSelector((state) => state.userState);
  const name = userState.firstName + " " + userState.lastName;
  const [wasCreated, setWasCreated] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      setAppState({
        currState: appStates.INITIALCOVERAGE,
      })
    );
    dispatch(nextViewConnectBusinessState());
    navigate("/coverageareas"); // Redireccionar a la ruta /coveragearea
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "600px",
        }}
        sx={{
          marginTop: { xs: -8, md: 20 },
          width: { xs: "100%", md: 900 },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600, // 500 es el peso de la fuente Poppins que hemos importado, para bold puedes usar 700 si está disponible
              // backgroundColor: "#233044",
              color: "#2d2fb8",
              textAlign: "center",
              padding: "10px",
              borderRadius: "10px",
              wordWrap: "break-word",
              maxWidth: "100%",
              marginTop: { xs: 7, md: 3 },
            }}
          >
            {`Welcome to Connect a Cleaner ${name}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              backgroundColor: "#2d2fb8",
              color: "white",
              fontWeight: 600, // 500 es el peso de la fuente Poppins que hemos importado, para bold puedes usar 700 si está disponible
              textAlign: "center",
              padding: "20px",
              borderRadius: "5px",
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            To start please set your working area
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginBottom: 0 }}>
            {" "}
            {/* Margen aplicado entre la imagen y el botón */}
            <img
              src={require("../assets/images/markArea.png")}
              alt="Mark Area"
              style={{ maxWidth: "100%", height: "100px", marginRight: "15px" }}
            />
          </Box>
          <Button
            sx={{
              "&:hover": {
                transform: "scale(1.1)", // Aumenta el tamaño del botón en un 10%
                transition: "transform 0.3s ease-in-out", // Hace la transición suave
              },
            }}
            onClick={handleSubmit}
          >
            <Typography
              variant="body"
              sx={{
                backgroundColor: "#269f42",
                color: "white",
                fontWeight: 600, // 500 es el peso de la fuente Poppins que hemos importado, para bold puedes usar 700 si está disponible
                textAlign: "center",
                padding: "5px",
                borderRadius: "50px",
                width: "150px",
              }}
            >
              CLICK HERE
            </Typography>
          </Button>
        </Grid>
      </Box>
    </div>
  );
};
