import React, { useState } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nextViewConnectBusinessState } from "../redux/features/connectBusinessStateSlice";
import { appStates, setAppState } from "../redux/features/appStateSlice";
import { gateway } from "../gateway";
import { updateUser } from "../redux/features/userStateSlice";
import { HelpFloatingButton } from "./helpFloatingButton";

export const ConnectBusinessWelcomeMessage = () => {
  const dispatch = useDispatch();
  const signupBusinessState = useSelector((state) => state.signupBusinessState);
  const { companyName: companyName } = signupBusinessState;
  const [wasCreated, setWasCreated] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");

  const userState = useSelector((state) => state.userState);
  const name = userState.company.businessName;

  const handleFindACleaner = () => {
    dispatch(
      setAppState({
        currState: appStates.FINDACLEANER,
      }),
    );
    dispatch(nextViewConnectBusinessState());
    // get the current user benefits
    gateway
      .get("/paypal/subscriptions-self", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        const data = res.data.data;
        dispatch(
          updateUser({
            subscription: data?.subscription?.id
              ? {
                  id: data?.subscription?.id,
                  paypalSubscriptionId: data?.subscription?.paypalId,
                  planName: data?.subscription?.plan?.product?.name,
                  planId: data?.subscription?.plan?.id,
                  planPaypalId: data?.subscription?.plan?.paypalPlanId,
                  hexColor: data?.subscription?.plan?.product?.hexColor,
                  createdAt: data?.subscription?.createdAt,
                }
              : null,

            benefits: data?.benefits ?? [],
          }),
        );
      });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleFindACleaner}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "600px",
        }}
        sx={{
          marginTop: { xs: 18, md: 10 },
          width: { xs: "100%", md: 600 },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 700, // 500 es el peso de la fuente Poppins que hemos importado, para bold puedes usar 700 si está disponible
              // backgroundColor: "#233044",
              // color: "blue",
              textAlign: "center",
              padding: "10px",
              borderRadius: "10px",
              wordWrap: "break-word",
              maxWidth: "100%",
              marginTop: { xs: 7, md: 10 },
            }}
          >
            {`Welcome ${name ?? "Company"}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              // backgroundColor: "#233044",
              // color: "white",
              textAlign: "center",
              padding: "20px",
              borderRadius: "10px",
              wordWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            The simple the better!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs
            sx={{
              mr: 1,
              height: "30px",
              display: "flex",
              // marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              sx={{
                "&:hover": {
                  transform: "scale(1.1)", // Aumenta el tamaño del botón en un 10%
                  transition: "transform 0.3s ease-in-out", // Hace la transición suave
                },
              }}
            >
              <Typography
                variant="body1"
                onClick={handleFindACleaner}
                sx={{
                  backgroundColor: "#1d4999",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "50px",
                  width: "auto", // Cambiado de "100%" a 'auto' para que el botón se expanda según el contenido
                  margin: "0 auto", // Centra el botón si su ancho es menor que el del contenedor
                  display: "block", // Asegura que el botón ocupe el ancho completo disponible
                  textTransform: "none", // Asegura que el texto no esté en mayúsculas
                  "&:hover": {
                    transform: "scale(1.1)", // Aumenta el tamaño del botón en un 10%
                    transition: "transform 0.3s ease-in-out", // Hace la transición suave
                  },
                }}
              >
                Find a cleaner
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <HelpFloatingButton></HelpFloatingButton>
    </div>
  );
};
