import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Face6Icon from "@mui/icons-material/Face6";
import { Container } from "@mui/material";
import { UserQuickMenu } from "./UserQuickMenu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserSelected } from "../redux/features/userSelectedStateSlice";

export const UserRecord = ({ user, index = 0, roleScope }) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { uid, email, roles, firstName, lastName, birthDate, country } = user;
  const listItemStyles = {
    padding: "1px", // Ajusta el relleno interno
    margin: "2px", // Ajusta el margen externo
    bgcolor: "background.paper",
    display: "flex",
  };

  // const handleOnClick = () => {
  //   console.log(user);
  //   dispatch(
  //     updateUserSelected({
  //       uid,
  //       email,
  //       roleName: roles[0]?.name ?? null,
  //       roleType: roles[0]?.type ?? null,
  //       firstName,
  //       lastName,
  //       birthDate,
  //       country,
  //     })
  //   );
  //   navigate(`/businessprofile/employees/${user?.uid}`);
  // };

  // useState(() => {
  //   console.log(user);
  // }, []);

  const circleStyles = {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "10px",
    alignSelf: "center",
    marginLeft: "10px",
  };

  const isActive = user.isEmailConfirmed;
  const circleBackgroundColor = isActive ? "#00C9A7" : "#B0A8B9";

  return (
    <div key={index}>
      <ListItem sx={listItemStyles}>
        <ListItemAvatar>
          <Avatar>
            <Face6Icon></Face6Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${user.firstName} ${user.lastName}`}
          secondary={`${user.email ?? " "} - ${user.country ?? " "} ${
            user.state ?? ""
          } `}
        />
        <Container
          sx={{
            justifySelf: "flex-end",
            alignSelf: "center",
            width: "auto",
            display: "flex",
          }}
        >
          {" "}
          {isActive ? (
            <ListItemText sx={{ alignSelf: "center" }} secondary={"active"} />
          ) : (
            <ListItemText sx={{ alignSelf: "center" }} secondary={"inactive"} />
          )}
          <div
            style={{
              ...circleStyles,
              backgroundColor: circleBackgroundColor,
            }}
          ></div>
          {/* <IconButton sx={{ alignSelf: "center", justifySelf: "center" }}>
            <MoreVertOutlined></MoreVertOutlined>
          </IconButton> */}
          <UserQuickMenu
            user={user}
            userUid={user.uid}
            roleScope={roleScope}
            companyId={user.UsersCompany?.companyId}
          ></UserQuickMenu>
        </Container>
      </ListItem>
    </div>
  );
};
