import { AccountCircle, AccountCircleOutlined, CellTower, Window } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import PinDrop from "@mui/icons-material/PinDrop";
import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LoadingCard } from "./common/LoadingCard";

export const IndividualContactInfo = () => {
  const userState = useSelector((state) => state.userState);
  const [loading, setLoading] = useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  useEffect(() => {
    const delay = async () => {
      setLoading(true);
      await timeout(500);
      setLoading(false);
    };

    delay();
  }, []);
  return (
    <Box
      sx={{
        mr: "2em",
        backgroundColor: "background.paper",
        width: "100%",
        height: "330px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
        margin: "0",
        position: "relative",
        pb: "2em",
      }}
    >
      <Box display="flex" alignItems="center" sx={{ marginTop: "1em", m: "0", mb: "1em" }}>
        <Avatar sx={{ marginTop: "1em", bgcolor: "#ffff" }}>
          <AccountCircle sx={{ color: "#1d4999", fontSize: 40 }}></AccountCircle>
        </Avatar>
        <Typography variant="subtitle1" marginLeft={2} sx={{ marginTop: "1em" }}>
          My Information
        </Typography>
      </Box>
      <Divider sx={{ width: "90%" }} />
      {!loading ? (
        <Box sx={{ backgroundColor: "#ffff", p: 1.5, width: "100%", height: "80%" }}>
          <Stack alignItems="center" mb={1}>
            <Avatar alt="Avatar" src={userState.avatar} sx={{ width: "80px", height: "80px" }} />
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" pt={1}>
            <Phone color="primary" />
            <Typography>{userState.company.businessPhone}</Typography>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" pt={1.5}>
            <Email color="primary" />
            <Typography variant="body1" component="p" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal" }}>
              {userState.email}
            </Typography>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" pt={1.5}>
            <PinDrop color="primary" />
            <Typography>
              {userState.city}, {userState.state}
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ height: "100%"}}>
          <LoadingCard></LoadingCard>
        </Box>
      )}
    </Box>
  );
};
