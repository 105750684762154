import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Button,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export const AvailabilityTable = ({ schedule }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell align="center">AM</TableCell>
            <TableCell align="center">Midday</TableCell>
            <TableCell align="center">PM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule
            .slice(0, open ? schedule.length : 2)
            .map((daySchedule, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {Object.keys(daySchedule)[0]}
                </TableCell>
                <TableCell align="center">
                  {daySchedule[Object.keys(daySchedule)[0]].AM ? (
                    <CircleIcon color="primary" />
                  ) : (
                    <CircleIcon color="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {daySchedule[Object.keys(daySchedule)[0]].Midday ? (
                    <CircleIcon color="primary" />
                  ) : (
                    <CircleIcon color="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {daySchedule[Object.keys(daySchedule)[0]].PM ? (
                    <CircleIcon color="primary" />
                  ) : (
                    <CircleIcon color="disabled" />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Button onClick={toggleOpen}>{open ? "Show less" : "Show more"}</Button>
    </>
  );
};
