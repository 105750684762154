import React, { useEffect, useState } from "react";
import {
  Paper,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
  TextField,
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "blue",
        },
      },
    },
  },
});

const TimeSelector = ({
  day,
  isOpen,
  onChange,
  fromTime,
  toTime,
  isSmallScreen,
}) => (
  <Grid
    container
    spacing={2}
    alignItems="center"
    justifyContent="center"
    direction="row" // Establecer siempre la dirección a "row" para mantenerlos en la misma fila
    sx={{ marginBottom: 2 }}
  >
    <Grid item xs={12} sm={4}>
      <FormControlLabel
        control={
          <Switch
            checked={isOpen}
            onChange={(e) => onChange(day, "isOpen", e)}
            name={`${day}Open`}
          />
        }
        label={day}
        sx={{ width: "100%" , ml: isSmallScreen ? -1 : "10px"}}
      />
    </Grid>
    <Grid item xs={6} sm={4}>
      <TextField
        label="From"
        type="time"
        disabled={!isOpen}
        defaultValue={fromTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: "100%" }}
        onChange={(e) => onChange(day, "from", e)}
      />
    </Grid>
    <Grid item xs={6} sm={4}>
      <TextField
        label="To"
        type="time"
        disabled={!isOpen}
        defaultValue={toTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: "100%" }}
        onChange={(e) => onChange(day, "to", e)}
      />
    </Grid>
  </Grid>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const Schedule = ({
  updateComponentState,
  checkedState,
  setChanges = () => {},
}) => {
  const [hours, setHours] = useState(defaultHours);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (
      checkedState &&
      typeof checkedState === "object" &&
      Object.keys(checkedState).length > 0
    ) {
      const initialHours = {};
      for (const [day, values] of Object.entries(checkedState)) {
        initialHours[capitalizeFirstLetter(day)] = {
          isOpen: values.isOpen,
          from: values.from,
          to: values.to,
        };
      }
      setHours(initialHours);
      console.log(initialHours);
    }
  }, [checkedState]);

  const handleSwitchTimeChange = (day, type, event) => {
    setChanges(true);
    setHours({
      ...hours,
      [day]: {
        ...hours[day],
        [type]: type === "isOpen" ? event.target.checked : event.target.value,
      },
    });
  };

  useEffect(() => {
    dispatch(updateComponentState({ availability: hours }));
  }, [hours]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", maxWidth: 600, m: "auto" }}
      >
        <Paper sx={{ padding: 2, my: 2, width: "100%" }}>
          {Object.entries(hours).map(([day, { isOpen, from, to }]) => (
            <TimeSelector
              key={day}
              day={day}
              isOpen={isOpen}
              onChange={handleSwitchTimeChange}
              fromTime={from}
              toTime={to}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

const defaultHours = {
  "Sunday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Monday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Tuesday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Wednesday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Thursday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Friday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  },
  "Saturday": {
    "isOpen": false,
    "from": "09:00",
    "to": "17:00"
  }
};
