import { Container, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gateway } from "../gateway";
import {
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
  updateSignupBusinessState,
  resetSignupBusinessState,
  // otras importaciones...
} from "../redux/features/signupBusinessStateSlice";
import { ConnectBusinessWelcomeMessage } from "./connectBusinessWelcomeMessage";
import { ConnectBusinessTypeOfJob } from "./connectBusinessTypeOfJob";
import { ConnectBusinessAddress } from "./connectBusinessAddress";
import { ConnectBusinessExpandMilesMessage } from "./connectBusinessExpandMilesMessage";
import { ConnectBusinessExpandNoEmployees } from "./connectBusinessExpandNoEmployees";
import { ConnectBusinessExpandYesEmployees } from "./connectBusinessExpandYesEmployees";
import { SignUpNextButton } from "./signupNextButton";
import {
  connectBusinessStateSlice,
  nextViewConnectBusinessState,
  previousViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import { ConnectBusinessResidential } from "./connectBusinessResidential";
import { ConnectBusinessCommercial } from "./connectBusinessCommercial";
import { ConnectBusinessRequirements } from "./connectBusinessRequirements";
import {
  ConnectEmployeeTres,
  connectEmployeeTres,
} from "./connectEmployeeTres";
import { ConnectBusinessSearch } from "./connectBusinessSearch";
import { ConnectIndividualWelcomeMessage } from "./connectIndividualWelcomeMessage";

export const ConnectBusinessAlgorithm = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const connectBusinessState = useSelector(
    (state) => state.connectBusinessState
  );
  const { serviceType } = connectBusinessState;

  const currPage = "/signupBusiness";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { residentialServices: checkedResidentialService } =
    connectBusinessState;
  const { commercialServices: checkedCommercialServices } =
    connectBusinessState;
  const { requirements: checkedRequirements } = connectBusinessState;

  const [isVisible, setIsVisible] = useState(false);

  const [isUserCreated, setIsUserCreated] = useState(false);
  const dispatch = useDispatch();

  const { currentView } = connectBusinessState;

  useEffect(() => {
    // Simula un retraso de 1 segundo antes de mostrar el contenido
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 200);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Mostrar el Loader
    setOpen(true); // Abrir el diálogo
    dispatch(updateSignupBusinessState());
    dispatch(nextViewSignupBusinessState());
    setIsLoading(false);
    setOpen(false);
  };

  const renderServiceTypeComponent = () => {
    switch (serviceType) {
      case "residential":
        return (
          <ConnectBusinessResidential
            checkedState={checkedResidentialService}
            nextViewComponentState={nextViewConnectBusinessState}
            previousViewEntityState={previousViewConnectBusinessState}
            updateComponentState={updateConnectBusinessState}
          />
        );
      case "commercial":
        return (
          <ConnectBusinessCommercial
            checkedState={checkedCommercialServices}
            nextViewComponentState={nextViewConnectBusinessState}
            previousViewEntityState={previousViewConnectBusinessState}
            updateComponentState={updateConnectBusinessState}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh", // Altura mínima para que ocupe toda la pantalla
      }}
    >
      <CssBaseline />
      {currentView === 0 && <ConnectBusinessWelcomeMessage />}
      {currentView === 1 && <ConnectBusinessTypeOfJob />}
      {currentView === 2 && (
        <ConnectBusinessAddress
          updateComponentState={updateConnectBusinessState}
        />
      )}
      {currentView === 3 && <ConnectBusinessSearch />}
      {/* {currentView === 4 && (
        <ConnectBusinessExpandNoEmployees />
      )} */}
      {currentView === 4 && <ConnectBusinessExpandYesEmployees />}
      {currentView === 5 && renderServiceTypeComponent()}
      {currentView === 6 && (
        <ConnectBusinessRequirements
          checkedState={checkedRequirements}
          nextViewComponentState={nextViewConnectBusinessState}
          previousViewEntityState={previousViewConnectBusinessState}
          updateComponentState={updateConnectBusinessState}
        />
      )}
      {currentView === 7 && (
        <ConnectEmployeeTres
          checkedState={checkedRequirements}
          nextViewComponentState={nextViewConnectBusinessState}
          previousViewEntityState={previousViewConnectBusinessState}
          updateComponentState={updateConnectBusinessState}
        />
      )}
    </Container>
  );
};
