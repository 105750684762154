export const termsAndConditionsInfo = {
  title: "CONNECT A CLEANER TERMS AND CONDITIONS",
  content: `
  <h3>1. Introduction</h3>
  <p>
    Welcome to Connect a Cleaner LLC! These terms and conditions govern your use of our
    web application and the services provided therein. By accessing or using our web app,
    you agree to comply with these terms and conditions.
  </p>
  `,
};
