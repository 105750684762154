import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const statePickerStateSlice = createSlice({
  name: "statePickerStateSlice",
  initialState,
  reducers: {
    updateStatePicker: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateStatePicker } = statePickerStateSlice.actions;
export default statePickerStateSlice.reducer;
