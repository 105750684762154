import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Divider, DrawerHeader, List, IconButton, Toolbar, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Icon } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Menu from "@mui/icons-material/Menu";
import Search from "@mui/icons-material/Search";
import { appBarHeight } from "./AppBar";
import { colorConfigs } from "../../config/colorConfigs";
import ListIcon from "@mui/icons-material/List";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Home from "@mui/icons-material/Home";
import Skills from "@mui/icons-material/CleaningServices";
import WorkingArea from "@mui/icons-material/ShareLocation";
import Others from "@mui/icons-material/PlaylistAdd";
import Availability from "@mui/icons-material/PendingActions";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { appStates, setAppState } from "../../redux/features/appStateSlice";
import { resetConnectBusinessState } from "../../redux/features/connectBusinessStateSlice";
import { ConnectBusinessExit } from "../ConnectBusinessExit";
import { IRoles } from "../../utils";
import { resetMarkerCount } from "../../redux/features/coverageSlice";
import { setPlanSelected } from "../../redux/features/planStateSlice";
import CellTower from "@mui/icons-material/CellTower";
export const drawerWidth = {
  min: 100,
  max: 258,
};

export const SidebarConnect = () => {
  const [open, setOpen] = useState(false);
  const [openConnectBusinessExit, setOpenConnectBusinessExit] = useState(false);

  const userState = useSelector((state) => state.userState);
  const appState = useSelector((state) => state.appState);
  const [activeButton, setActiveButton] = React.useState(0);
  const [continueAction, setContinueAction] = React.useState(null);

  const handleResetNeededState = () => {
    resetConnectBusinessState();
    dispatch(resetMarkerCount());
    dispatch(
      setPlanSelected({
        upgradeFreeTrial: false,
      }),
    );
  };

  const { type: roleType } = userState.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //TODO: set app the reat appState
  const handleCoverageAreaRoute = (index) => {
    setActiveButton(index); // Establecer el botón activo
    navigate("/coverageareas");
    handleResetNeededState();
  };
  const handleServicesRoute = (index) => {
    setActiveButton(index);
    navigate("/services");
    handleResetNeededState();
  };

  const handleRequirementsRoute = (index) => {
    setActiveButton(index);
    navigate("/requirements");
    handleResetNeededState();
  };
  const handleAvalabilityRoute = (index) => {
    setActiveButton(index);
    navigate("/avalability");
    handleResetNeededState();
  };

  const handleHome = (index) => {
    setActiveButton(index);
    navigate("/");
    dispatch(
      setAppState({
        currState: appStates.HOME,
      }),
    );
    handleResetNeededState();
  };

  const handlePlan = (index) => {
    setActiveButton(index);
    // dispatch(resetConnectBusinessState());
    navigate("/plan-center");
    dispatch(
      setAppState({
        currState: appStates.PLANS,
      }),
    );
    handleResetNeededState();
  };

  const handleExistDialog = (cb) => {
    setOpenConnectBusinessExit(true);
    setContinueAction(() => cb);
    handleResetNeededState();
  };

  const handlePlayGround = (index) => {
    setActiveButton(index);
    navigate("/playground");
    dispatch(
      setAppState({
        currState: appStates.PLAYGROUND,
      }),
    );
    handleResetNeededState();
  };

  const  handleMyConnections = (index) => {
    setActiveButton(index);
    navigate("/my-connections");
    dispatch(
      setAppState({
        currState: appStates.MYCONNECTIONS,
      }),
    );
    handleResetNeededState();
  }

  let pages = [
    {
      name: { business: "Home", individual: "Home" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleHome(index);
            //To Reset the curr algorithm view on find a cleaner!
            dispatch(resetConnectBusinessState());
          });
        } else {
          handleHome(index);
        }
      },
      icon: <Home></Home>,
      authorization: [IRoles.Business, IRoles.Employee, IRoles.SuperAdmin],
    },
    {
      name: { business: "My Connections", individual: "My Connections" },
      callback: (index) => {
        if(appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleMyConnections(index);
            dispatch(resetConnectBusinessState());
          });
        } else {
          handleMyConnections(index);
        }
      },
      icon: <CellTower></CellTower>,
      authorization: [IRoles.Employee, IRoles.Business]
    },
    {
      name: { business: "Services", individual: "Skills" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleServicesRoute(index);
            //To Reset the curr algorithm view on find a cleaner!
            dispatch(resetConnectBusinessState());
          });
        } else {
          handleServicesRoute(index);
        }
      },
      icon: <Skills></Skills>,
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { business: "Hours of Operation", individual: "Availability" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleAvalabilityRoute(index);
            //To Reset the curr algorithm view on find a cleaner!
            dispatch(resetConnectBusinessState());
          });
        } else {
          handleAvalabilityRoute(index);
        }
      },
      icon: <Availability></Availability>,
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { business: "Coverage Areas", individual: "Working Area" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handleCoverageAreaRoute(index);

            //To Reset the curr algorithm view on find a cleaner!
            dispatch(resetConnectBusinessState());
          });
        } else {
          handleCoverageAreaRoute(index);
        }
      },
      icon: <WorkingArea></WorkingArea>,
      authorization: [IRoles.Business, IRoles.Employee],
    },
    {
      name: { business: "Suscriptions" },
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee") {
          handleExistDialog(() => {
            handlePlan(index);
            //To Reset the curr algorithm view on find a cleaner!
            dispatch(resetConnectBusinessState());
          });
        } else {
          handlePlan(index);
        }
      },
      icon: <CreditCardIcon></CreditCardIcon>,
      authorization: [IRoles.Business],
    },

    {
      name: { individual: "Others", business: "Requirements" },
      callback: handleRequirementsRoute,
      icon: <Others></Others>,
      authorization: [IRoles.Employee],
    },
    /*
    {
      name: { business: "Playground", individual: "Playground"},
      callback: (index) => {
        if (appState.currState === appStates.FINDACLEANER && roleType !== "employee"){
          handleExistDialog(() => {
            handlePlayGround(index);
            dispatch(resetConnectBusinessState());
          });
        } else {
          handlePlayGround(index);
        }
      },
      icon: <Others></Others>,
      authorization: [IRoles.Business, IRoles.Employee],
    }
    */
  ];

  // if (roleType === "employee") {
  //   pages.push({
  //     name: { individual: "Others", business: "Requirements" },
  //     callback: handleRequirementsRoute,
  //     icon: <Others></Others>,
  //   });
  // }

  return (
    <>
      <Drawer
        sx={{
          width: open ? drawerWidth.max : drawerWidth.min,
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          flexShrink: 0,
          position: "relative",
          "& .MuiDrawer-paper": {
            width: open ? drawerWidth.max : drawerWidth.min,
            transition: (theme) =>
              theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            boxSizing: "border-box",
            backgroundColor: colorConfigs.sidebar.bgSecundary,
            marginTop: `${appBarHeight}px`,
            top: 0,
            height: `calc(100% - ${appBarHeight})`,
          },
          display: {
            xs: "none",
            md: "flex",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <IconButton
            onClick={() => setOpen(!open)}
            sx={{
              position: "absolute",
              right: 10,
              transform: !open ? "translateX(-60%)" : "translateX(10)",
              transition: (theme) =>
                theme.transitions.create("transform", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
          >
            {!open ? (
              <ListIcon></ListIcon>
            ) : (
              <ArrowBackIosIcon
                // sx={{ color: "white" }}
                fontSize="small"
              ></ArrowBackIosIcon>
            )}
          </IconButton>
        </Toolbar>

        <Divider></Divider>
        <List>
          {pages
            ?.filter((page) => page?.authorization?.includes(roleType))
            ?.map((page, index) => (
              <ListItem key={`${roleType}-${index}-md-sidebar`}>
                <ListItemButton
                  onClick={() => {
                    page.callback(index);
                  }}
                >
                  <ListItemIcon sx={{ color: "black" }}>{page.icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      margin: 0,
                      padding: 0,
                      opacity: open ? 1 : 0,
                      color: "black",
                      transition: (theme) =>
                        theme.transitions.create("opacity", {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        }),
                      // Añadir overflow y whiteSpace para manejar el texto cuando está oculto
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {roleType === "employee" ? page.name.individual : page.name.business}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Drawer>
      <ConnectBusinessExit open={openConnectBusinessExit} setOpen={setOpenConnectBusinessExit} continueAction={continueAction}></ConnectBusinessExit>
    </>
  );
};
