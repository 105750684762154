import React, { useEffect, useState } from "react";
import { Box, Divider, Typography, Button, Checkbox, Link, Collapse, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { PlanNameFromType, setPlanSelected } from "../redux/features/planStateSlice";
import { PlanPayment } from "./PlanPayment";
import { termsAndConditionsInfo } from "../assets/termsConditions";
import { PlanPaymentFree } from "./PlanPaymentFree";
import CloseIcon from "@mui/icons-material/Close";
import { BaseDialog } from "./common/BaseDialog";

const styles = {
  titleSx: {
    textAlign: "center",
  },
};

export const PlanCheckout = ({ setSubscriptionState }) => {
  const { planSelected } = useSelector((state) => state.planState);
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const termsDialog = {
    marginLeft: isMobile ? "-10px" : "0px",
    width: isMobile ? "115%" : "100%",
  };

  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();

  const [tcHtmlDocument, setTcDocument] = useState("");
  const tcSource = "https://connectacleaner.github.io/termsAndContionsConnectACleaner/";

  useEffect(() => {
    fetch(tcSource)
      .then((response) => response.text())
      .then((data) => setTcDocument(data))
      .catch((error) => console.error(error));
  }, []);

  const TermsAndConditions = () => {
    return (
      <>
        <IconButton style={{ position: "absolute", top: "2px", right: "5px", zIndex: 1 }} onClick={() => setOpenTermsAndConditions(false)}>
          <CloseIcon />
        </IconButton>
        <Divider></Divider>
        <Box dangerouslySetInnerHTML={{ __html: tcHtmlDocument }}></Box>
      </>
    );
  };
  return (
    <>
      <Typography variant="h4" color="initial" sx={{ fontWeight: "bold" }}>
        Checkout
      </Typography>
      <Divider sx={{ mb: "1em", mt: "1em" }}></Divider>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" color="initial" sx={{ fontWeight: "bold" }}>
          {PlanNameFromType[planSelected?.type]}
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            dispatch(setPlanSelected({ planSelected: undefined }));
          }}
        >
          Edit
        </Button>
      </Box>

      <Typography variant="subtitle2" color="gray">
        West River Cleaning Services, Inc
      </Typography>
      <Typography variant="subtitle2" color="gray">
        Gloria Mathew{" "}
      </Typography>
      <Typography variant="subtitle2" color="gray">
        123 West River Ave, Los Angeles, CA, 90040{" "}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1.5 }}>
        <Checkbox
          checked={check}
          onChange={() => {
            setCheck((prev) => !prev);
          }}
        ></Checkbox>
        <Typography variant="subtitle2" color="gray">
          I have read and agree to the Connect a Cleaner
          <Link
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenTermsAndConditions(true);
            }}
          >
            {` Terms & Conditions.`}
          </Link>
        </Typography>
      </Box>
      <Divider sx={{ mb: "1em", mt: "1em" }}></Divider>
      <Typography variant="h5" color={!check ? "gray" : "initial"} sx={{ fontWeight: "bold" }}>
        {"Payment method"}
      </Typography>
      <br></br>
      <Collapse in={check}>
        {planSelected?.price?.amount === 0 ? <PlanPaymentFree setSubscriptionState={setSubscriptionState}></PlanPaymentFree> : <PlanPayment setSubscriptionState={setSubscriptionState}></PlanPayment>}
      </Collapse>

      <BaseDialog
        openState={openTermsAndConditions}
        setOpenState={() => {
          setOpenTermsAndConditions(false);
        }}
        fullWidth={true}
        maxWidth="md"
        titleSx={styles.titleSx}
        title={termsAndConditionsInfo.title}
        contentSx={termsDialog}
        content={<TermsAndConditions></TermsAndConditions>}
      ></BaseDialog>
    </>
  );
};
