import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { gateway } from "../gateway";
import CheckExperience from "./checkboxExperience";
import { CheckExperienceCommercial } from "./checkboxExperienceCommercial";
import { updateSignupBusinessState } from "../redux/features/signupBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectEmployeeBothServices = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState,
  checkedState,
}) => {
  const currPage = "/signupBusiness";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const residencialType = "residencial"; //The type that will be put on the
  const [residencialServices, setResidencialServices] = useState([]);

  const commercialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);

  const getWorkServiceByType = async (type, setStateService) => {
    try {
      const response = await gateway.get(`/workservices?type=${type}`);
      const data = response.data.data.items;
      setStateService(data);
      console.log("the data: ", data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getWorkServiceByType(residencialType, setResidencialServices);
    getWorkServiceByType(commercialType, setCommercialServices);
  }, []);
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: "20%", md: 3 },
          marginLeft: { xs: "0", md: -19 },
          width: { xs: "100%", md: 800 },
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              backgroundColor: "#233044",
              color: "white",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            Services offered by the company
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CheckExperience
              checkedState={checkedState.residential}
              updateComponentState={updateComponentState}
              data={residencialServices}
            />{" "}
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckExperienceCommercial
              checkedState={checkedState.commercial}
              updateComponentState={updateComponentState}
              data={commercialServices}
            />
          </Grid>
          {/* Columna de Disponibilidad */}
          {/* <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "10px",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              Availability
            </Typography>

            <TimeSelector
              updateComponentState={updateComponentState}
            ></TimeSelector>
          </Grid> */}
        </Grid>
        <SignUpNextButton
          // type="submit"
          sx={{
            marginTop: "20px",
          }}
          nextViewEntityState={nextViewComponentState}
          previousViewEntityState={previousViewEntityState}
        ></SignUpNextButton>
      </Box>
      {/* </Box> */}
    </div>
  );
};
