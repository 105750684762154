import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  makeStyles,
} from "@mui/material";
import React from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useState } from "react";
import { StatePicker } from "./statePicker";
import { CountryPicker } from "./countryPicker";
import { DatePicker } from "@mui/x-date-pickers";
import { BasicDatePicker } from "./datePicker";
import { Loader } from "./common/Loader";
import { useSelector } from "react-redux";
import { gateway } from "../gateway";
import jwtDecode from "jwt-decode";
import { LoaderComponent } from "./Loader";

export const UserQuickCreation = ({ Scope }) => {
  const [open, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const birthDate = useSelector((state) => state.formState.birthDate);
  const country = useSelector((state) => state.countryState["userCountry"]);
  const state = useSelector((state) => state.statePickerState["userState"]);
  const localToken = localStorage.getItem("token");
  const { uid } = jwtDecode(localToken);
  const handleCreate = async (event) => {
    try {
      event.preventDefault();
      const formElement = event.target; // Get the form element
      const formData = new FormData(formElement);

      setIsLoading(true);
      const userData = {
        email: formData.get("email"),
        password: uid,
        firstName: formData.get("firstName"),
        lastName: formData.get("lastName"),
        birthDate: birthDate,
        country: country,
        state: state,
      };
      console.log(userData);
      const response = await gateway.post(
        `/users/${Scope}`,
        {
          ...userData,
        },
        {
          headers: {
            Authorization: `${localToken}`,
          },
        }
      );

      console.log("Response is : ", response.data);
      if (response.status === 201) {
        console.log("User was created");
      } else if (response.status === 200) {
        console.log("User was invited");
      }
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const response = error?.response?.data;

      if (!response?.data?.isAvailable) {
        //Email is alrready in use, chose other
        console.log(response?.message);
      }
    } finally {
      setIsLoading(false); // Cerrar el diálogo
    }
  };

  return (
    <>
      <IconButton
        sx={{
          width: {
            md: "40px",
          },
          height: {
            md: "40px",
          },
          padding: "0",
          margin: "0",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PersonAddIcon> </PersonAddIcon>
      </IconButton>{" "}
      <Dialog component="form" onSubmit={handleCreate} open={open}>
        <DialogTitle>{`Create an ${Scope}.`}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "orange" }}>
            This action will either create a new user for this company or invite
            an existing account to join. Please note that the user will remain
            "inactive" until they accept or confirm the invitation and provide
            their credentials. Additionally, be aware that this action will
            grant specific permissions to that user within this scope:
          </DialogContentText>

          <Box sx={{ mt: 1 }}>
            <Grid container margin="normal">
              <Grid item xs sx={{ mr: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoFocus
                />
              </Grid>
              <Grid item xs sx={{ ml: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  // autoComplete="email"
                  autoFocus
                />
              </Grid>
            </Grid>

            <BasicDatePicker></BasicDatePicker>
            <CountryPicker name="userCountry" />
            <StatePicker name="userState" />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Invite</Button>
        </DialogActions>
      </Dialog>
      
      <LoaderComponent open={isLoading} type="spinner"></LoaderComponent>
    </>
  );
};
