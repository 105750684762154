import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  age: "",
  address: "",
  country: "",
  state: "",
  phone: "",
  zipCode: "",
  residentialServices: [],
  availability: null,
  commercialServices: [],
  transport: { id: null, name: null },
  individualType: "",
  classifyYourSelf: "",
  imageUrl: null,
  imageFile: null,
  formFile: null,
  currentView: 0,
  totalViews: 8,
};

export const signupEmployeeStateSlice = createSlice({
  name: "signupEmployeeStateSlice",
  initialState,
  reducers: {
    updateSignupEmployeeState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    }, // state.totalViews - 1 is equal to 6 because we start counting in 0
    nextViewSignupEmployeeState: (state, action) => {
      console.log("excuting next", state);
      return {
        ...state,
        currentView:
          state.currentView >= state.totalViews - 1 ? 0 : state.currentView + 1,
      };
    },
    previousViewSignupEmployeeState: (state, action) => {
      return {
        ...state,
        currentView: state.currentView <= 0 ? 0 : state.currentView - 1,
      };
    },
    resetSignupEmployeeState: () => {
      return initialState;
    },
  },
});

export const {
  updateSignupEmployeeState,
  resetSignupEmployeeState,
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
} = signupEmployeeStateSlice.actions;
export default signupEmployeeStateSlice.reducer;
