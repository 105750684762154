import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { gateway } from "../gateway";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetUser } from "../redux/features/userStateSlice";
import { BaseDialog } from "./common/BaseDialog";

const styles = {
  titleSx: {
    textAlign: "center",
    color: "blue",
  },
  actionSx: {
    justifyContent: "center",
  },
  btnDialogSx: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
};
export const AccountDeactivation = ({ onDeactivate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeactivationDialog, setOpenDeactivationDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const tokenLocal = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignOff = () => {
    if (tokenLocal) {
      localStorage.removeItem("token");
      dispatch(resetUser());
      navigate("/signin");
    }
  };
  const token = localStorage.getItem("token");

  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDeactivationDialog = () => {
    setOpenDeactivationDialog(true);
    handleCloseMenu();
  };

  const handleCloseDeactivationDialog = () => {
    setOpenDeactivationDialog(false);
  };

  const handleDeactivateAccount = () => {
    setOpenDeactivationDialog(false);
    setOpenConfirmationDialog(true);
  };

  const handleDeactivationEmail = async () => {
    try {
      const res = gateway
        .get("users/send-delete/self", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data?.status === 200) {
            handleSignOff();
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseConfirmationDialog = async () => {
    setOpenConfirmationDialog(false);
  };

  const DeactivateAccount = () => {
    return (
      <>
        <Typography sx={{ textAlign: "center", fontSize: "0.875rem", color: "black" }}>
          Before proceeding, we would like to remind you that if you deactivate your account, it will require manual reactivation by our support team.
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "20px",
            color: "black",
          }}
        >
          Are you sure you want to continue?
        </Typography>
      </>
    );
  };

  const ConfirmDeactivation = () => {
    return (
      <>
        <Typography sx={{ textAlign: "center", color: "black" }}>In order to proceed with deactivation, we've sent you an email for identity verification.</Typography>
        <Typography sx={{ textAlign: "center", color: "black", marginTop: "1.5em" }}>Please verify and follow the instructions sent.</Typography>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          marginTop: "-4%",
        }}
      >
        <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? "long-menu" : undefined} aria-expanded={openMenu ? "true" : undefined} aria-haspopup="true" onClick={handleClickMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: 48 * 2,
              width: "20ch",
            },
          }}
        >
          <MenuItem onClick={handleOpenDeactivationDialog}>Deactivate account</MenuItem>
        </Menu>
      </Box>

      <BaseDialog
        openState={openDeactivationDialog}
        setOpenState={handleCloseDeactivationDialog}
        titleSx={styles.titleSx}
        title="ACCOUNT DEACTIVATION"
        content={<DeactivateAccount></DeactivateAccount>}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "CANCEL",
            onClick: () => {
              handleCloseDeactivationDialog();
            },
            style: styles.btnDialogSx,
          },
          {
            label: "YES",
            onClick: () => {
              handleDeactivateAccount();
            },
            style: styles.btnDialogSx,
          },
        ]}
      ></BaseDialog>

      <BaseDialog
        openState={openConfirmationDialog}
        setOpenState={handleCloseConfirmationDialog}
        titleSx={styles.titleSx}
        title="We are sorry to see you go!"
        content={<ConfirmDeactivation></ConfirmDeactivation>}
        actionSx={styles.actionSx}
        actions={[
          {
            label: "OK",
            autoFocus: true,
            onClick: () => {
              handleCloseConfirmationDialog();
              handleDeactivationEmail();
            },
            style: styles.btnDialogSx,
          },
        ]}
      ></BaseDialog>
    </>
  );
};
