import React from "react";
import { Box } from "@mui/material";
import { assets } from "../assets";

export const QuickBadge = () => {
  return (
    <>
      <style>
        {`
          @keyframes heartbeat {
            0% { transform: scale(1); }
            50% { transform: scale(1.03); }
            100% { transform: scale(1); }
          }
        `}
      </style>
      <Box
        sx={{
          position: "absolute",
          top: "-12px",
          padding: 0,
          margin: 0,
          right: "-20px",
          width: "30%",
          height: "30%",
          "& img": {
            width: "100%",
            height: "100%",
            animation: "heartbeat 1.5s infinite",
          },
          filter: "drop-shadow(1px 1px 8px blue)",
        }}
      >
        <img src={assets.images.connectacleanerbadge} alt="Badge" />
      </Box>
    </>
  );
};
