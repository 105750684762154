import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useSelector } from "react-redux";
import { DataTodo } from "../../components/todoEmployees";
import { FindMyDevice } from "../../components/findMyDevice";

//This code show info for unique user

export default function EmployeePageLayout() {
  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector((state) => state.userSelectedState);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <>
      <Box
        minHeight="90vh"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // backgroundColor: "blue",
          height: "90vh",
          width: "100%",
          padding: 1,
          opacity: isVisible ? 1 : 0, // Establece la opacidad basada en isVisible
          transition: "opacity 0.5s ease-in-out", // Agrega una transición de opacidad
        }}
      >
        <Grid id="Grid-2-Row-grapper" container sx={{ height: "100%" }}>
          <Grid
            id="UserAvatarName"
            container
            item
            xs={12}
            spacing={1}
            sx={{
              // backgroundColor: "red",
              height: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item md={3} sm={6} xs={12}>
              {/* Contenido de la primera columna */}
              {/* <Paper>Image</Paper> */}

              <Avatar
                // md = {
                //   width: "200px",
                //   height: "200px",
                // }
                sx={{
                  width: {
                    xs: 80,
                    sm: 150,
                    md: 200,
                  },
                  height: {
                    xs: 80,
                    sm: 150,
                    md: 200,
                  },
                  margin: "auto", // Esto centrará horizontalmente
                  marginTop: "auto", // Esto centrará verticalmente
                  marginBottom: "auto", // Esto centrará verticalmente
                }}
              >
                <PermIdentityIcon
                  sx={{ width: "70%", height: "70%" }}
                ></PermIdentityIcon>
              </Avatar>
            </Grid>
            <Grid item md={3} sm={2} xs={12}>
              <Container
                sx={{
                  margin: "auto",
                  marginTop: "auto",
                  marginBottom: "auto",
                  textAlign: "center",
                }}
              >
                <Typography variant="h4">
                  {user?.firstName + " " + user?.lastName ?? ""}
                </Typography>
                <Typography variant="h5">{user?.email ?? ""}</Typography>
                <Typography variant="h6">{user?.country ?? ""}</Typography>
              </Container>
            </Grid>
            <Grid item md={6} sm={4} xs={12}></Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={1}
            sx={{
              // backgroundColor: "pink",
              height: "70%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              id="TODOS Container"
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                height: "100%",
                display: "flex",
                width: "auto",
                alignItems: "center",
                // backgroundColor: "blue",
              }}
            >
              {/* Contenido de la primera columna */}

              <DataTodo></DataTodo>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                alignSelf: "center",
                justifySelf: "center",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FindMyDevice></FindMyDevice>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
