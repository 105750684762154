import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience, {
  CheckExperienceCommercial,
} from "./checkboxExperienceCommercial";
import CheckAvailability from "./checkboxAvailability";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { TimeSelectorCommercial } from "./checkBoxAvailabilityCommercial";
import { gateway } from "../gateway";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { SignUpNextButton } from "./signupNextButton";
import { Schedule } from "./schedule";
import { updateUser } from "../redux/features/userStateSlice";
import { SaveButton } from "./saveButton";
import { useSelector } from "react-redux";

export const HoursOfOperation = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateSignupEmployeeState,
  checkedState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currPage = "/signupEmployee";
  const commertialType = "commercial"; //The type that will be put on the
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [open, setOpen] = useState(false);
  const tokenLocal = localStorage.getItem("token");
  const [availability, setMyAvailability] = useState();
  const userState = useSelector((state) => state.userState);

  const getMyAvailability = async () => {
    try {
      const response = await gateway.get(`/availability/self`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;

      setMyAvailability(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveMyAvailability = async () => {
    try {
      const response = await gateway.put(
        `/availability/self`,
        {
          availability: userState.availability,
        },
        {
          headers: {
            Authorization: `${tokenLocal}`,
          },
        }
      );
      const data = response.data.data;
      setMyAvailability(data);
      setChanges(false);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (cancel) {
      handleAvailabilityInfo(); // Esta función ya obtiene los servicios y actualiza los estados.
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  useEffect(() => {
    handleAvailabilityInfo();
  }, []);

  const handleAvailabilityInfo = async () => {
    try {
      setOpen(true);
      await getMyAvailability();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const handleAvailabilityUpdate = async () => {
    try {
      setOpen(true);
      await saveMyAvailability();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: "20%", md: 9 },
          width: { xs: 400, md: 600 },
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            color: "White",
            background: "#233044",
            borderRadius: "3px",
            textAlign: "center",
            width: "100%", // Full width
            padding: "10px",
            marginBottom: "1%",
          }}
        >
          Hours of Operation
        </Typography>
        <Schedule
          updateComponentState={updateComponentState}
          setChanges={setChanges}
          checkedState={availability}
        ></Schedule>
        <SaveButton
          disabled={!changes}
          setCancel={setCancel}
          onSubmit={handleAvailabilityUpdate}
        ></SaveButton>
      </Box>
    </div>
  );
};
