import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { setPlanSelected } from "../redux/features/planStateSlice";
import { assets } from "../assets";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../redux/features/userStateSlice";
import { useSelector } from "react-redux";

export const PlanCheckoutSuccess = ({ subscriptionState }) => {
  const { planSelected } = useSelector((state) => state.planState);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Typography variant="h4" color="initial" sx={{ fontWeight: "bold" }}>
        { planSelected.name === "Free" ? 'Your Suscription Was Successful!' : 'Your Payment Was Successful!' }
      </Typography>
      <Box sx={{ width: "20%", height: "30%", mt: 2 }}>
        <img width="100%" height="100%" src={assets.images.sucessSubs}></img>
      </Box>

      <Alert severity="success" icon={false}>
        <Typography variant="h5" color="initial" sx={{ fontWeight: "bold" }}>
          { planSelected.name === "Free" ? "We sent your free plan details to the email provided." : "We sent a payment receipt to the email provided." }
          
        </Typography>
      </Alert>
      <Button
        variant="contained"
        sx={{ mt: 2, backgroundColor: "#0068e6" }}
        onClick={() => {
          dispatch(updateUser({ subscription: subscriptionState?.data?.subscription, benefits: subscriptionState?.data?.benefits }));

          dispatch(
            setPlanSelected({
              planSelected: undefined,
            }),
          );
          navigate("/");
        }}
      >
        Go Home
      </Button>
    </Box>
  );
};
