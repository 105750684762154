import CellTower from "@mui/icons-material/CellTower";
import { Avatar, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingCard } from "./common/LoadingCard";
import { getMyConnections } from "../utils";

export const IndividualConnections = () => {
  const token = localStorage.getItem("token");
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      getMyConnections(`?page=1&pagesize=3&order=date&ordertype=DESC`, token)
      .then((res) => {
        setRequests(res?.items);
      })
      .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const headCells = [
    {
      id: "date",
      label: "Date",
      disablePadding: true,
      numeric: false,
    },
    {
      id: "company",
      label: "Company",
      disablePadding: false,
      numeric: false,
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    {
      id: "status",
      label: "Status",
      disablePadding: false,
      numeric: false,
    },
  ];

  const styles = {
    accepted: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#009933",
      width: "80px",
    },
    declined: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#e63900",
      width: "80px",
    },
    pending: {
      padding: 0.5,
      borderRadius: "5px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "gray",
      width: "80px",
    },
  };
  return (
    <Box
      sx={{
        mr: "2em",
        backgroundColor: "background.paper",
        width: "100%",
        height: "330px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "3px",
        margin: "0",
        position: "relative",
        pb: "2em",
      }}
    >
      <Box display="flex" alignItems="center" sx={{ marginTop: "1em", m: "0", mb: "1em" }}>
        <Avatar sx={{ marginTop: "1em", bgcolor: "#ffff" }}>
          <CellTower sx={{ color: "#1d4999", fontSize: 40 }}></CellTower>
        </Avatar>
        <Typography variant="subtitle1" marginLeft={2} sx={{ marginTop: "1em" }}>
          My Connections
        </Typography>
      </Box>
      <Divider sx={{ width: "90%" }} />
      {!loading ? (
        <Box sx={{ backgroundColor: "#ffff", p: 1.5, width: "95%", height: "80%" }}>
          {requests.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests?.map((request, index) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row" sx={{ padding: 1.85, minWidth: 70 }}>
                          {request?.createdAt}
                        </TableCell>
                        <TableCell sx={{ minWidth: 80, maxWidth: 110, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{request?.company?.businessName}</TableCell>
                        <TableCell sx={{ maxWidth: 60 }}>
                          <Typography sx={styles[request?.status]}>{request?.status}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ display: "flex", alignContent: "center", alignItems: "center", height: "100%" }}>
              <Typography sx={{ textAlign: "center", p: 3 }}>There are currently no connection requests.</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: "80%" }}>
          <LoadingCard />
        </Box>
      )}
    </Box>
  );
};
