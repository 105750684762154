import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import formStateSlice from "./features/formStateSlice";
import countrySlice from "./features/countryStateSlice";
import statePickerStateSlice from "./features/stateStateSlice";
import userStateSlice from "./features/userStateSlice";
import businessProfileStateSlice from "./features/businessProfileStateSlice";
import signupEmployeeStateSlice from "./features/signupEmployeeStateSlice";
import signupBusinessStateSlice from "./features/signupBusinessStateSlice";
import userSelectedStateSlice from "./features/userSelectedStateSlice";
import connectACleanerForJobStateSlice from "./features/connectACleanerForJobStateSlice";
import connectBusinessStateSlice from "./features/connectBusinessStateSlice";
import notificationSlice from "./features/notificationSlice";
import planStateSlice from "./features/planStateSlice";
import markerStateSlice from "./features/coverageSlice";
import superAdminSearchStateSlice from "./features/superAdminSearchStateSlice";
import contacInfoStateSlice from "./features/contacInfoStateSlice";

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    formState: formStateSlice,
    countryState: countrySlice,
    statePickerState: statePickerStateSlice,
    userState: userStateSlice,
    businessProfileState: businessProfileStateSlice,
    signupEmployeeState: signupEmployeeStateSlice,
    signupBusinessState: signupBusinessStateSlice,
    userSelectedState: userSelectedStateSlice,
    connectACleanerForJobState: connectACleanerForJobStateSlice,
    connectBusinessState: connectBusinessStateSlice,
    notificationsState: notificationSlice,
    planState: planStateSlice,
    markerState: markerStateSlice,
    superAdminSearchState: superAdminSearchStateSlice,
    contactInfoState: contacInfoStateSlice
  },
  devTools: "production",
});
