import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StatePicker } from "./statePicker";
import { updateForm } from "../redux/features/formStateSlice";
import {
  nextViewSignupBusinessState,
  previousViewSignupBusinessState,
  updateSignupBusinessState,
} from "../redux/features/signupBusinessStateSlice";
import { MapComponent } from "./coverageAreaMap";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectEmployeeTwelve = ({ updateEntityState, entityState }) => {
  const currPage = "/connect";
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const markersLimit = 1;

  useEffect(() => {
    dispatch(updateForm({ state: "businessInformation" }));
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);

    // Aquí iría la lógica para manejar la presentación del formulario

    dispatch(nextViewSignupBusinessState());
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: "15%", md: 5 },
          width: { xs: "100%", md: 700 },
          marginLeft: { xs: 0, md: -20 },
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            color: "white",
            background: "#233044",
            borderRadius: "10px",
            textAlign: "center",
            width: "100%",
            marginBottom: "10px",
            padding: "10px",
          }}
        >
          Coverage Area
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* TODO: Coverage area */}
          <MapComponent
            updateEntityState={updateEntityState}
            entityState={entityState}
            isSignupProcess={true}
            refresh={refresh}
            markersLimit={markersLimit}
          ></MapComponent>
          <Grid item>
            <SignUpNextButton
              // type="submit"
              sx={{
                marginTop: "10px",
              }}
              nextViewEntityState={nextViewSignupBusinessState}
              previousViewEntityState={previousViewSignupBusinessState}
            ></SignUpNextButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
