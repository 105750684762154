import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import BackPage from "./common/BackPage";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";

import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { useDispatch } from "react-redux";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const NextButton = ({
  onSubmit = () => {},
  type,
  disabled = false,
  nextViewEntityState,
  previousViewEntityState,
  sx,
  buttonText,
}) => {
  const dispatch = useDispatch();

  return (
    <div key={"SignUpNextButton"}>
      <Grid
        container
        spacing={2}
        sx={typeof sx === "object" ? { ...sx } : undefined}
      >
        <Grid item xs={4}>
          <IconButton
            onClick={
              typeof previousViewEntityState === "function"
                ? () => {
                    dispatch(previousViewEntityState());
                  }
                : () => {}
            }
          >
            <ChevronLeftOutlinedIcon></ChevronLeftOutlinedIcon>
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <Button
            type={type}
            disabled={disabled} // Deshabilitar el botón si el formulario no es válido
            sx = {   disabled  === true  ?  { opacity: 0.5}: {opacity: 1}     }
            onClick={
              typeof nextViewEntityState === "function"
                ? async () => {
                    try {
                      await onSubmit();
                      // await sleep(100);
                      if (type === "submit") {
                        if (disabled) {
                          dispatch(nextViewEntityState());
                        }
                      } else {
                        dispatch(nextViewEntityState());
                      }
                    } catch (error) {
                      console.error(
                        "Error when traying to execute: onSubmit(); nextViewEntityState()"
                      );
                      console.error(error);
                    }
                  }
                : () => {}
            }
            // type={type}
            style={{
              marginTop: "5%",
              borderRadius: "10px",
              width: "100%", // Ancho del botón
              backgroundColor: "#233044",
              color: "white",
            }}
            variant="contained"
            disableElevation
          >
           {buttonText} {/* Usamos la prop aquí para el texto del botón */}

          </Button>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  );
};
