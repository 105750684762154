import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nextViewConnectBusinessState,
  updateConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import IconButtonFileUpload, { blobUrlToFile } from "./ImageUploader";
import { updateUser } from "../redux/features/userStateSlice";
import { gateway } from "../gateway";
import { LoaderComponent } from "./Loader";
import { MapPredictive } from "./mapPredictive";
import { SaveButton } from "./saveButton";
import { AccountDeactivation } from "./informationBtnBolean";

export const ConnectEmployeeAccount = ({
  updateComponentState = () => {},
  componentState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userState);
  const tokenLocal = localStorage.getItem("token");

  const [myUserInfo, setMyUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [ageError, setAgeError] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleLocationSelect = (city, state, zipCode, country, address) => {
    // Actualiza los estados
    setCity(city);
    setState(state);
    setZipCode(zipCode);
    setCountry(country);
    // Más lógica si es necesario
  };

  const isSomeEmpty = (listToCheck = []) => {
    const data = listToCheck.some((item) => item === "" || item === null);
    console.log(data);
    return data;
  };

  const getMyUserInfo = async () => {
    try {
      const response = await gateway.get(`/users/self`, {
        headers: {
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;
      setMyUserInfo(data);
      dispatch(updateComponentState(data));
    } catch (error) {
      console.error(error);
    }
  };

  const saveMyUserInfo = async () => {
    try {
      setOpen(true);
      const file = await blobUrlToFile(userState.imageUrl);
      const formData = new FormData();

      formData.append("age", age);
      formData.append("phone", phone);
      formData.append("address", userState.address ?? "");
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("zipCode", zipCode);

      if (userState.imageUrl) {
        formData.append("imageFile", file);
      }

      const response = await gateway.put("/users/self", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenLocal}`,
        },
      });
      const data = response.data.data;
      dispatch(updateComponentState(data));
      setMyUserInfo(data);
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(false);
      setChanges(false);
      setCancel(false);
      setIsEmpty(false);
    }
  };

  const handleUpdate = async () => {
    await saveMyUserInfo();
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    if (myUserInfo) {
      setPhone(myUserInfo.phone);
      setAge(myUserInfo.age);
      setCountry(myUserInfo.country);
      setState(myUserInfo.state);
      setCity(myUserInfo.city);
      setZipCode(myUserInfo.zipCode);
    }
  }, [myUserInfo]);

  useEffect(() => {
    setIsEmpty(isSomeEmpty([state, city, zipCode, phone, country]));
  }, [state, city, zipCode, phone, country]);

  useEffect(() => {
    if (cancel) {
      getMyUserInfo(); // Esta función ya obtiene los servicios y actualiza los estados.
      dispatch(updateComponentState({ imageUrl: null }));
      setCancel(false);
      setChanges(false);
    }
  }, [cancel]);

  const handleUserInfo = async () => {
    try {
      setOpen(true);
      await getMyUserInfo();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setChanges(true);
    const { name, value } = e.target;
    switch (name) {
      case "phone":
        setPhone(value);
        break;
      case "age":
        setAge(value);

        if (!Number.isInteger(Number(value)) || value < 0 || value > 150) {
          setAgeError("Please enter a valid age");
        } else {
          setAgeError("");
        }

        break;
      case "country":
        setCountry(value);
        break;
      case "state":
        setState(value);
        break;
      case "city":
        setCity(value);
        break;
      case "zipCode":
        setZipCode(value);
        break;
      default:
        break;
    }
    dispatch(updateConnectBusinessState({ [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOpen(true);

    const userData = {
      phone: phone,
      age: age,
      country: country,
      state: state,
      city: city,
      zipCode: zipCode,
    };

    dispatch(updateConnectBusinessState(userData));
    dispatch(nextViewConnectBusinessState(userData));

    setIsLoading(false);
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "70%",
        }}
        sx={{
          marginTop: { xs: 8, md: 9 },
          width: { xs: "100%", md: 600 },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontFamily: "Tahoma, sans-serif",
                fontWeight: "bold",
                color: "White",
                background: "#233044",
                padding: "10px",
                width: "100%",
                marginBottom: "10px",
                borderRadius: "10px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Update your information
            </Typography>
            <Avatar
              sx={{ width: "90px", height: "90px" }}
              src={userState.imageUrl ?? userState.avatar}
            />
            <IconButtonFileUpload
              setChanges={setChanges}
              updateComponentState={updateComponentState}
            />

            {/* Age TextField */}
            <TextField
              margin="normal"
              id="age"
              type="text"
              label="Age (Optional)"
              InputLabelProps={{ shrink: age ? true : false }}
              name="age"
              size="small"
              variant="outlined"
              onChange={handleInputChange}
              error={!!ageError}
              helperText={ageError}
              value={age}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginBottom: "3%",
              }}
            />

            {/* Phone TextField */}
            <TextField
              margin="normal"
              id="phone"
              type="phone"
              label="Phone Number"
              name="phone"
              size="small"
              InputLabelProps={{ shrink: phone ? true : false }}
              variant="outlined"
              onChange={handleInputChange}
              value={phone}
              sx={{
                fontSize: "12px",
                width: "100%",
                marginTop: "-1%",
              }}
              autoFocus
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
                mt: "10px",
              }}
            >
              <MapPredictive
                setChanges={setChanges}
                onLocationSelect={handleLocationSelect}
                updateComponentState={updateComponentState}
                componentState={componentState}
              ></MapPredictive>
            </Box>

            <TextField
              margin="normal"
              id="city"
              label="City"
              name="city"
              size="small"
              InputLabelProps={{ shrink: city ? true : false }}
              variant="outlined"
              onChange={handleInputChange}
              value={city}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
            />

            {/* State TextField */}
            <TextField
              margin="normal"
              id="state"
              label="State"
              name="state"
              size="small"
              InputLabelProps={{ shrink: state ? true : false }}
              variant="outlined"
              onChange={handleInputChange}
              value={state}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
            />

            {/* Zip Code TextField */}
            <TextField
              margin="normal"
              id="zipCode"
              label="Zip Code"
              name="zipCode"
              size="small"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={handleInputChange}
              value={zipCode}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
            />

            <TextField
              margin="normal"
              id="country"
              label="Country"
              name="country"
              size="small"
              InputLabelProps={{ shrink: country ? true : false }}
              variant="outlined"
              onChange={handleInputChange}
              value={country}
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <SaveButton
                disabled={!changes || !!ageError || isEmpty}
                setCancel={setCancel}
                onSubmit={handleUpdate}
              ></SaveButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <AccountDeactivation></AccountDeactivation>
        </Grid>
      </Box>
      <LoaderComponent open={open}></LoaderComponent>
    </div>
  );
};
