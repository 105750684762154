import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { colorConfigs } from "../../config/colorConfigs";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";

export default function SidebarItemCollapse({ item }) {
  const [open, setOpen] = useState(false);
  const userRole = useSelector((state) => state.userState?.role?.type);

  return item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        component={Link}
        to={item.path}
        sx={{
          width: "100%",
          "&: hover": { backgroundColor: colorConfigs.sidebar.hoverBg },
          paddingY: "12px",
          paddingX: "30px",
        }}
      >
        <ListItemIcon sx={{ color: colorConfigs.sidebar.color }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Typography>{item.sidebarProps.displayText}</Typography>}
        ></ListItemText>
        {open ? (
          <ExpandLessOutlinedIcon></ExpandLessOutlinedIcon>
        ) : (
          <ExpandMoreOutlinedIcon></ExpandMoreOutlinedIcon>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              //TODO Expected: Maybe put policy here
              route.child ? (
                <SidebarItemCollapse
                  item={route}
                  key={index}
                ></SidebarItemCollapse>
              ) : route.policy?.includes(userRole) ? (
                <SidebarItem item={route} key={index}>
                  {" "}
                </SidebarItem>
              ) : null
            ) : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
}
