import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CheckExperience, {
  CheckExperienceCommercial,
} from "./checkboxExperienceCommercial";
import CheckAvailability from "./checkboxAvailability";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TimeSelector } from "./checkBoxAvailabilityParent";
import { TimeSelectorCommercial } from "./checkBoxAvailabilityCommercial";
import { gateway } from "../gateway";
import {
  nextViewSignupEmployeeState,
  previousViewSignupEmployeeState,
  updateSignupEmployeeState,
} from "../redux/features/signupEmployeeStateSlice";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectEmployeeSix = ({
  nextViewComponentState,
  previousViewEntityState,
  updateComponentState = updateSignupEmployeeState,
  checkedState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currPage = "/signupEmployee";
  const commertialType = "commercial"; //The type that will be put on the
  const [commercialServices, setCommercialServices] = useState([]);

  const getWorkServiceByType = async (type) => {
    try {
      const response = await gateway.get(
        `/workservices?type=${commertialType}`
      );
      const data = response.data.data.items;
      setCommercialServices(data);
      console.log("the data: ", data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWorkServiceByType(commertialType);
  }, []);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto",
      }}
    >
      <Box
        style={{
          borderRadius: "10px",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          alignItems: "center",
          transition: "opacity 1s ease-in-out",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "auto",
        }}
        sx={{
          marginTop: { xs: "20%", md: 10 },
          width: { xs: "100%", md: 600 },
          marginLeft: { md: -15 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "#233044",
                color: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              Availability
            </Typography>
            <TimeSelector
              checkedState={checkedState}
              updateComponentState={updateComponentState}
            ></TimeSelector>
          </Grid>
        </Grid>

        <SignUpNextButton
          // type="submit"
          sx={{
            marginTop: "20px",
          }}
          nextViewEntityState={nextViewComponentState}
          previousViewEntityState={previousViewEntityState}
        ></SignUpNextButton>
      </Box>
    </div>
  );
};
