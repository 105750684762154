import { MoreVertOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Fragment, useState } from "react";

export const TodoHeaderQuickMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Add Request</MenuItem>
        <MenuItem onClick={handleClose}>Delete Request/s</MenuItem>
        {/* Más opciones si es necesario */}
      </Menu>
    </Fragment>
  );
};
