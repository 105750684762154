import React, { useEffect, useState } from "react";
import { Box, Slider, TextField, Typography, Grid, Dialog, DialogActions, Button, IconButton, Menu, MenuItem, LinearProgress, Tooltip, Icon } from "@mui/material";
import { GoogleMap, LoadScriptNext, Marker, Circle } from "@react-google-maps/api";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMyLocation, milesToKm } from "../utils";
import { addMarkerCount, deleteMarkerCount } from "../redux/features/coverageSlice";
import { appStates, setAppState } from "../redux/features/appStateSlice";
import { BaseDialog } from "./common/BaseDialog";
import { useNavigate } from "react-router-dom";
import Lock from "@mui/icons-material/Lock";
import EditOff from "@mui/icons-material/EditOff";
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Home, LocationOn } from "@mui/icons-material";

//import "../assets/styles/coverageAreas.css";
// const containerStyle = {
//   width: "400px",
//   height: "300px",
//   borderRadius: "5px",
// };

const center = { lat: 39.748116, lng: -104.770598 };

const styles = {
  titleSx: {
    fontWeight: "bold",
  },
  actionSx: {
    justifyContent: "center",
  },
  btnCloseSx: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
  btnAcceptSx: {
    backgroundColor: "#3c7cde",
    color: "white",
    "&:hover": { backgroundColor: "#233044" },
    margin: "0 8px",
  },
};

let markerCount = 0;

const customIcon = (color, holeColor) => ({
  url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="${color}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><circle cx="12" cy="9.1" r="2.7" fill="${holeColor}" /></svg>`,
});

export const MapComponent = ({
  updateEntityState,
  entityState,
  isSignupProcess = false,
  setChanges = () => {},
  refresh = () => {},
  refreshCb = () => {},
  setData = () => {},
  setLoading = () => {},
  markersLimit = undefined,
  allowsDeleteMarkers = true,
  isInformative = false,
  zoom = 5,
  mapContainerStyle = undefined,
}) => {
  const [markers, setMarkers] = useState([]);
  const [markersBackup, setMarkersBackup] = useState([]);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  console.log("marker limit: " +  markersLimit)
  const [mapCenter, setMapCenter] = useState(center);

  const [address, setAddress] = useState("");
  const token = localStorage.getItem("token");

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [openNotAvailablePlan, setOpenNotAvailablePlan] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const containerStyle = {
    width: isXs ? "300px" : isMd ? "650px" : "300px",
    height: isXs ? "300px" : isMd ? "400px" : "300px",
    borderRadius: "5px",
  };

  const onMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    addMarker(lat, lng);
  };

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingStoredIndex, setEditingStoredIndex] = useState(-1);

  // Estado para el menú
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleSetMarkers = (newMarkers) => {
    setData(newMarkers);
    if (typeof updateEntityState === "function") {
      dispatch(updateEntityState({ coverageAreas: newMarkers }));
    }

    setChanges(true);
    setMarkers(newMarkers);
  };

  const updateMarkersFromLocations = (locations = []) => {
    locations.forEach(() => dispatch(addMarkerCount()));
    const newMarkers = locations.map((loc, index) => ({
      lat: Number(loc.lat),
      lng: Number(loc.lng),
      address: loc.address,
      city: loc.city,
      state: loc.state,
      country: loc.country,
      radius: milesToKm(Number(loc.radius)) ?? 16093.4, // Convertir millas a metros
      id: loc.id,
      isAvailable: loc.isAvailable,
      name: loc.name ? loc.name : `Location: ${++index}`,
      index: index,
    }));
    markerCount = locations.length;
    handleSetMarkers(newMarkers);
    setMarkers(newMarkers);
    setMarkersBackup(newMarkers);
    setIsFirstLoading(false);
  };

  // useEffect(() => {
  //   getMyLocation(token).then((res) => {
  //     // alert(JSON.stringify(res));
  //     updateMarkersFromLocations(res); // Función para actualizar los marcadores
  //     // console.log(res);
  //   });
  // }, []);

  useEffect(() => {
    if (!isSignupProcess) {
      setLoading(true);
      setEditIndexes([]);
      getMyLocation(token)
        .then((res) => {
          // alert(JSON.stringify(res));

          updateMarkersFromLocations(res);
          // console.log(res);
          setLoading(false);
        })
        .then((res) => refreshCb());
    } else {
      const { coverageAreas } = entityState;
      updateMarkersFromLocations(coverageAreas);
    }
  }, [refresh]);

  useEffect(() => {
    if (markers.length > 0) {
      const lastMarker = editingIndex !== -1 ? editingIndex : markers[markers.length - 1];
      setMapCenter({ lat: lastMarker.lat, lng: lastMarker.lng });
    }
  }, [markers]);
  // useEffect(() => {}, [markers]);

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    const markerIndex = markers.findIndex((item) => item?.index === index);
    setEditingStoredIndex(index);
    setEditingIndex(markerIndex);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Función para abrir el diálogo de edición
  const handleEditOpen = (index) => {
    setEditingIndex(index);
    setEditDialogOpen(true);
  };

  // Función para cerrar el diálogo de edición
  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  // Función para guardar los cambios de edición
  const handleEditSave = () => {
    // Actualiza el marcador editado en el estado de marcadores
    // ... Implementación ...
    handleEditClose();
  };

  // Función para eliminar un marcador
  const handleDelete = () => {
    handleSetMarkers(markers.filter((_, idx) => idx !== editingIndex));
    handleMenuClose();
    dispatch(deleteMarkerCount());
  };

  // Componente de diálogo de edición
  const EditDialog = () => (
    <Dialog open={editDialogOpen} onClose={handleEditClose}>
      <DialogActions>
        <Button onClick={handleEditClose}>Cancel</Button>
        <Button onClick={handleEditSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );

  const getMarkerDetailsFromCoords = async (lat, lng, existingRadius, index) => {
    setLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    try {
      const geocodeResponse = await geocoder.geocode({
        location: { lat, lng },
      });
      const address = geocodeResponse.results[0].formatted_address;
      const addressComponents = geocodeResponse.results[0].address_components;

      const cityComponent = addressComponents.find((component) => component.types.includes("locality"));
      const stateComponent = addressComponents.find((component) => component.types.includes("administrative_area_level_1"));
      const countryComponent = addressComponents.find((component) => component.types.includes("country"));

      return {
        address,
        city: cityComponent ? cityComponent.long_name : "",
        state: stateComponent ? stateComponent.long_name : "",
        country: countryComponent ? countryComponent.long_name : "",
        lat,
        lng,
        radius: existingRadius ?? 16093.4,
        isAvailable: true,
        name: index !== undefined ? markers[index]?.name : `Location: ${markerCount + 1}`,
        index: index ? index : markerCount++,
      };
    } catch (error) {
      console.error("Error al obtener los detalles del marcador: ", error);
      return null; // O manejar el error de manera más específica
    } finally {
      setLoading(false);
    }
  };

  const addMarker = async (lat, lng) => {
    if (markersLimit !== undefined && markers.length >= markersLimit) {
      console.log(`Its no posible to add more marker, limit set to ${markersLimit}.`);
      return; // Sale temprano si ya se alcanzó el límite
    }
    setEditIndexes((prevEditIndexes) => [...prevEditIndexes, markerCount]);
    const markerDetails = await getMarkerDetailsFromCoords(lat, lng);
    if (markerDetails) {
      dispatch(addMarkerCount());
      handleSetMarkers([...markers, markerDetails]);
    }
  };

  const handleRadiusChange = (index, newValueInMiles) => {
    const newValueInMeters = newValueInMiles * 1609.34; // Convertir millas a metros
    const updatedMarkers = markers.map((marker, idx) => {
      if (idx === index) {
        return { ...marker, radius: newValueInMeters };
      }
      return marker;
    });
    handleSetMarkers(updatedMarkers);
  };

  const handleSelect = async (value) => {
    setAddress(value);
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);

      if (markersLimit === 1 && markers.length === 1) {
        // Actualiza la posición del marcador existente
        const updatedMarker = await getMarkerDetailsFromCoords(latLng.lat, latLng.lng, markers[0].radius);
        if (updatedMarker) {
          const updatedMarkers = [updatedMarker];
          handleSetMarkers(updatedMarkers);
        }
      } else {
        // Agrega un nuevo marcador si no se ha alcanzado el límite
        addMarker(latLng.lat, latLng.lng);
      }
    } catch (error) {
      console.error("Error al obtener la dirección: ", error);
    }
  };

  const onMarkerDragEnd = async (markerIndex, event) => {
    const updatedLat = event.latLng.lat();
    const updatedLng = event.latLng.lng();
    setEditingIndex(markerIndex);
    // Actualiza inmediatamente la posición del marcador
    const tempMarkers = markers.map((marker, idx) => {
      if (idx === markerIndex) {
        return { ...marker, lat: updatedLat, lng: updatedLng };
      }
      return marker;
    });
    handleSetMarkers(tempMarkers);

    // Luego carga y actualiza los detalles adicionales
    const existingRadius = markers[markerIndex].radius;
    const updatedMarkerDetails = await getMarkerDetailsFromCoords(updatedLat, updatedLng, existingRadius, markerIndex);

    if (updatedMarkerDetails) {
      const updatedMarkers = markers.map((marker, idx) => {
        if (idx === markerIndex) {
          return { ...marker, ...updatedMarkerDetails };
        }
        return marker;
      });
      handleSetMarkers(updatedMarkers);
    }
  };

  function handleOpenNotAvailablePlan(available) {
    setOpenNotAvailablePlan(available);
  }

  const actions = [
    {
      label: "Close",
      onClick: () => {
        setOpenNotAvailablePlan(false);
      },
      style: styles.btnCloseSx,
      icon: <Close></Close>,
    },
    {
      label: "Go to plans",
      onClick: () => {
        navigate("/plan-center");
        dispatch(
          setAppState({
            currState: appStates.PLANS,
          }),
        );
      },
      icon: <ArrowForward></ArrowForward>,
      style: styles.btnAcceptSx,
    },
  ];

  const handleNameChange = (index, event) => {
    const updatedMarkers = markers.map((marker, idx) => {
      if (idx === index) {
        return { ...marker, name: event.target.value };
      }
      return marker;
    });
    handleSetMarkers(updatedMarkers);
  };

  const [editIndexes, setEditIndexes] = useState([]);

  const handleEditItem = () => {
    setEditIndexes((prev) => {
      if (prev.includes(editingStoredIndex)) {
        const marker = markers.find((item) => item.index === editingStoredIndex);
        if (marker?.id) {
          let restoredMarkers = [...markers];
          restoredMarkers[editingIndex] = markersBackup[editingStoredIndex];
          setMarkers(restoredMarkers);
        }
        return prev.filter((i) => i !== editingStoredIndex);
      }
      return [...prev, editingStoredIndex];
    });
    setChanges(true);
    handleMenuClose();
  };

  useEffect(() => {
    if (editIndexes.length === 0) setChanges(false);
  }, [editIndexes]);

  return (
    <LoadScriptNext googleMapsApiKey="AIzaSyAVA2xUJNYnk3pWEbP60u9Iz--eyc0iL9w" libraries={["places"]}>
      {!isInformative ? (
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField
                {...getInputProps({
                  label: "Address",
                  className: "location-search-input",
                })}
                sx={{
                  width: { xs: 300, md: 650 },
                }}
                margin="normal"
              />
              <div>
                {loading && <div>Cargando...</div>}
                {suggestions.map((suggestion) => {
                  const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        <></>
      )}
      <GoogleMap mapContainerStyle={mapContainerStyle || containerStyle} center={mapCenter} zoom={zoom} onClick={onMapClick}>
        {markers.map((marker, index) => (
          <React.Fragment key={marker.id || `marker-${marker.lat}-${marker.lng}`}>
            <Marker
              position={{ lat: marker.lat, lng: marker.lng }}
              draggable={marker.isAvailable}
              onDragEnd={(event) => onMarkerDragEnd(index, event)}
              icon={marker.isAvailable ? customIcon("rgba(234,67,53,255)", "rgba(180,20,18,255)") : customIcon("gray", "rgb(84,84,84)")}
            ></Marker>
            <Circle
              center={{ lat: marker.lat, lng: marker.lng }}
              radius={marker.radius}
              options={{
                strokeColor: marker.isAvailable ? "#FF0000" : "#808080",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: marker.isAvailable ? "#FF0000" : "#808080",
                fillOpacity: 0.35,
              }}
            />
          </React.Fragment>
        ))}
      </GoogleMap>

      {!isInformative ? (
        <div>
          <Box
            sx={{
              height: "auto", // Altura fija del contenedor
              overflowY: "scroll", // Habilita el desplazamiento vertical
              marginTop: "10px",
              width: { xs: "100%", md: "650px" },
            }}
          >
            {markers.map((marker, index) => (
              <Tooltip title={!marker.isAvailable ? "Upgrade your plan to unlock this Coverage Area" : ""} placement="bottom">
                <Box
                  key={marker.id || `marker-${marker.lat}-${marker.lng}`}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "10px",
                    padding: { xs: "5px", sm: "10px" }, // Menos padding en xs
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    margin: "10px 0",
                    border: !editIndexes.includes(marker.index) || markersLimit === 1 ? "none" : "solid 1px #1976d2",
                  }}
                  style={{ opacity: !marker.isAvailable ? 0.4 : 1 }}
                  onClick={() => handleOpenNotAvailablePlan(!marker.isAvailable)}
                >
                  <Grid container spacing={2} sx={{ justifyContent: "center", width: "100%" }}>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        type="text"
                        value={markersLimit === 1 ? "My Location" : marker.name }
                        disabled={!editIndexes.includes(marker.index || markersLimit === 1)}
                        onChange={(e) => handleNameChange(index, e)}
                        variant="standard"
                        sx={{
                          height: "2.5em",
                          width: "10em",
                          backgroundColor: "#A3D3FA",
                          borderRadius: "10px",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            display: "flex",
                            height: "100%",
                            fontWeight: !editIndexes.includes(marker.index) ? "normal" : "bold",
                          },
                        }}
                        inputProps={{
                          sx: { textAlign: "center" },
                        }}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body1" component="span" sx={{ fontWeight: "bold" }}>
                        City:
                      </Typography>{" "}
                      <Typography variant="body1" component="span">
                        {marker.city}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body1" component="span" sx={{ fontWeight: "bold" }}>
                        State:
                      </Typography>{" "}
                      <Typography variant="body1" component="span">
                        {marker.state}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body2" component="span" sx={{ fontWeight: "bold" }}>
                        Miles of coverage
                      </Typography>
                      {!isInformative && marker.isAvailable ? (
                        <Slider
                          disabled={!editIndexes.includes(marker.index) && markersLimit !== 1}
                          sx={{
                            width: "80%",
                            "&.MuiSlider-root.Mui-disabled": {
                              color: "#1976d2",
                            },
                          }}
                          value={marker.radius / 1609.34} // Convertir metros a millas para el valor del Slider
                          onChange={(e, value) => handleRadiusChange(index, value)}
                          min={10} // 10 millas
                          max={50} // 50 millas
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          getAriaValueText={(value) => `${value} millas`}
                        />
                      ) : (
                        <LinearProgress
                          variant="determinate"
                          value={((marker.radius / 1609.34 - 10) / (50 - 10)) * 100} // Normaliza el valor para LinearProgress
                          sx={{ width: "80%" }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {allowsDeleteMarkers ? (
                        <>
                          {marker.isAvailable ? (
                            <>
                              <IconButton onClick={(e) => handleMenuClick(e, marker.index)}>{!editIndexes.includes(marker.index) ? <EditIcon /> : <EditOff />}</IconButton>
                              <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                                {markers[editingIndex]?.id ? (
                                  <MenuItem onClick={handleEditItem}>
                                    {!editIndexes.includes(editingStoredIndex) ? (
                                      <>
                                        <Edit /> Edit
                                      </>
                                    ) : (
                                      <>
                                        <EditOff /> Cancel Edit
                                      </>
                                    )}
                                  </MenuItem>
                                ) : undefined}
                                <MenuItem onClick={handleDelete}>
                                  <DeleteIcon />
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          ) : (
                            <IconButton>
                              <Lock />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <EditDialog />
                </Box>
              </Tooltip>
            ))}
          </Box>
        </div>
      ) : (
        <></>
      )}

      <BaseDialog
        openState={openNotAvailablePlan}
        setOpenState={setOpenNotAvailablePlan}
        title="Coverage Area Locked!"
        titleSx={styles.titleSx}
        content="This coverage area is locked because you have exceeded your benefits limit. To unlock it, please upgrade your account."
        actionSx={styles.actionSx}
        actions={actions}
      ></BaseDialog>
    </LoadScriptNext>
  );
};
