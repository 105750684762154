import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Button, Typography } from "@mui/material";
import { appStates, setAppState } from "../redux/features/appStateSlice";
import { useDispatch } from "react-redux";
import { BaseDialog } from "./common/BaseDialog";

const styles = {
  titleSx: { textAlign: "center", color: "blue" },
  contentSx: { textAlign: "center", fontSize: "0.875rem", color: "black" },
  actionsSx: { justifyContent: "center" },
  btnCancelSx: { backgroundColor: "grey", color: "white", "&:hover": { backgroundColor: "#233044" }, margin: "0 8px" },
  btnContinueSx: { backgroundColor: "grey", color: "white", "&:hover": { backgroundColor: "#233044" }, margin: "0 8px" },
};
export const ConnectBusinessExit = ({ open = false, setOpen = () => {}, continueAction = () => {} }) => {
  const dispatch = useDispatch();
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const actions = [
    {
      label: "CANCEL",
      onClick: () => {
        handleCloseDialog();
      },
      style: styles.btnCancelSx,
    },
    {
      label: "CONTINUE",
      onClick: () => {
        dispatch(setAppState({ currState: appStates.HOME }));
        continueAction();
        handleCloseDialog();
      },
      style: styles.btnContinueSx,
    },
  ];
  useState(() => {}, open);
  

  const ExitMessage = () => {
    return (
      <Box>
        <Typography sx={{ textAlign: "center", fontSize: "0.875rem", color: "black" }}>
          We noticed that you haven’t completed your connection process, if you leave now you’ll lose all your progress
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "20px",
            color: "black",
          }}
        >
          Do you want to continue?{" "}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {
        <BaseDialog
          openState={open}
          setOpenState={setOpen}
          title="IMPORTANT NOTICE"
          titleSx={styles.titleSx}
          content={<ExitMessage></ExitMessage>}
          actionSx={styles.actionsSx}
          actions={actions}
        ></BaseDialog>
      }
    </>
  );
};
