import React from "react";
import { Card, Typography, Box, alpha, darken } from "@mui/material";

export const PlanBanner = ({
  color = "#d22e2e",
  children,
  width = "50%",
  height = "10%",
}) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "8em",
          left: "0",
          width,
          height,
          bgcolor: color,
          zIndex: "1",
          clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pl: "10%",
          color: "white",
        }}
        children={children}
      />
      {/* Shadow clipPath */}
      <Box
        sx={{
          position: "absolute",
          top: "8.25em",
          left: "0.5em",
          zIndex: "0",
          width,
          height,
          bgcolor: alpha(color, 0.3),
          clipPath: "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "calc(8em - 10px)",
          left: "0",
          width: "10px",
          height: "10px",
          bgcolor: darken(color, 0.3),
          clipPath: "polygon(100% 0, 0 100%, 100% 100%)",
          transformOrigin: "0 0",
          zIndex: "badge",
        }}
      />
    </>
  );
};
