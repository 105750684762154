import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Zoom } from "@mui/material";

export const BaseDialog = ({
  openState = false,
  setOpenState = () => {},
  title = "",
  titleSx,
  content,
  contentSx,
  actions,
  actionSx,
  disableScape = false,
  transition = undefined,
  fullWidth = false,
  maxWidth = undefined,
}) => {
  return (
    <Dialog open={openState} onClose={() => setOpenState(false)} disableEscapeKeyDown={disableScape} TransitionComponent={transition} fullWidth={fullWidth} maxWidth={maxWidth}>
      <Box sx={{ padding: "5px" }}>
        <DialogTitle sx={titleSx}>{title}</DialogTitle>
        <DialogContent sx={contentSx}>{content}</DialogContent>
        <DialogActions sx={actionSx}>
          {actions &&
            actions.map((action) => {
              return (
                <Button onClick={action.onClick} sx={action.style} startIcon={action.icon} autoFocus={action.autoFocus}>
                  {action.label}
                </Button>
              );
            })}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
