import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import React from "react";
import { sizeConfigs } from "../../config/sizeConfig";
import { assets } from "../../assets";
import { colorConfigs } from "../../config/colorConfigs";
import { appRoutes } from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const userRole = useSelector((state) => state.userState?.role?.type);
  console.log(userRole);
  return (
    <Drawer
      variant="permanent"
      // maxWidth="xs"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
        },
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "20px", marginTop: "10px" }}>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <Avatar
              src={assets.images.logo}
              sx={{ width: "25%", height: "25%" }}
            ></Avatar>
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              route.policy?.includes(userRole) ? (
                <SidebarItemCollapse
                  item={route}
                  key={index}
                ></SidebarItemCollapse>
              ) : null
            ) : route.policy?.includes(userRole) ? (
              <SidebarItem item={route} key={index}>
                {" "}
              </SidebarItem>
            ) : null
          ) : null
        )}
      </List>
    </Drawer>
  );
}
