import { Button } from "@mui/material";
import { BusinessContact } from "./businessContact"
import { useState } from "react";

export const Playground = () => {
    const [contactUs, setContactUs] = useState(false);

    return <div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#005eff",
            marginTop: "auto",
          }}
          //TODO: Plan Selected
          onClick={() => {
            setContactUs(true);
          }}
        >
          CHOOSE PLAN
        </Button>
        {
        contactUs ? <BusinessContact bannerHexColor={"#005eff"} setContactUs={setContactUs}></BusinessContact> : <></>
      }
    </div>
}