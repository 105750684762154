import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Slider,
  Typography,
  Zoom,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "../redux/features/formStateSlice";
import BasicDatePicker from "./calendar";
import TimePickerValue from "./timeRange";
import { MapPredictive } from "./mapPredictive";
import {
  nextViewConnectBusinessState,
  previousViewConnectBusinessState,
  resetConnectBusinessState,
} from "../redux/features/connectBusinessStateSlice";
import { SignUpNextButton } from "./signupNextButton";

export const ConnectBusinessExpandNoEmployees = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleBackHomeClick = () => {
      dispatch( resetConnectBusinessState ())
      navigate('/'); // Esto redirige al usuario a la ruta de inicio
    };
  
  
  return (
<Box
      sx={{
        bgcolor: 'rgba(35, 48, 68, 1)',
        borderRadius: 2,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        minHeight: '200px', // Ajustar según sea necesario
        width: '100%',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        WE ARE SORRY
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We couldn't find any cleaner in your area
      </Typography>
      <Button
        variant="contained"
        // color="secondary"
        sx={{ mt: 2, borderRadius: 20, px: 3 }} // px es para el padding horizontal
        onClick={handleBackHomeClick}
      >
        BACK TO HOME
      </Button>
    </Box>
  );
};